/* eslint-disable react/destructuring-assignment */
import React from 'react';
import ReactTable from 'react-table';
import { Card, CardBody, Row, Col, Button, FormGroup, Label, Alert } from 'reactstrap';
import Switch from 'react-bootstrap-switch';
import SweetAlert from 'react-bootstrap-sweetalert';
import { SketchPicker } from 'react-color';
import { observer } from 'mobx-react';
import moment from 'moment';
import Select from 'react-select';
import NumberFormat from 'react-number-format';

import PanelHeader from 'components/PanelHeader/PanelHeader';
import ImageUpload from 'components/CustomUpload/ImageUpload';
import { LabelInput, CardTitle } from 'components';
import { colors } from 'theme';
import { EMAIL_REG_EX, NUMBER_REG_EX } from 'utils/regularExpressions';
import { createSalesperson, getSalespersonList, editSalesperson, editSalespersonAvatar } from 'lib/api/salespersonApi';
import { getGoalsList, deleteGoal, createGoal, editGoal } from 'lib/api/goalsApi';
import authService from 'lib/services/authService';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getSubscriptionPreviewInvoice, getBillingInfoPage, getSubscriptionMeneagmentData } from 'lib/api/billingApi';
import { getPriceBuilder, getCashTerms } from 'lib/api/proposalApi';
import config from '../../config';
import store from '../../store';
import { getIsSalesInMetadata } from '../../lib/api/companyApi';
import { checkZeroFormat } from '../Pricebook/helpers';

const styles = {
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  popover: { position: 'absolute', zIndex: '2', bottom: '2rem' },
  colorButton: {
    width: '1rem',
    height: '1rem',
    border: `1px solid ${colors.blue}`,
    cursor: 'pointer',
  },
  label: { color: colors.blue, marginRight: '1vw' },
  row: { display: 'flex', flexDirection: 'row' },
};

const REQUIRED_FIELDS = ['name', 'username', 'email', 'phone'];

const stripePromise = loadStripe(config.stripeKey);

const NumberFormatInput = props => <NumberFormat {...props} />;
class AddEditSalePerson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      errorMessage: '',
      showPasswordModal: false,
      showSuccessModal: false,
      showErrorStripeModal: false,
      paymentResponse: null,
      avatarFile: null,
      avatarUrl: null,
      name: '',
      nameState: '',
      phone: '',
      phoneState: '',
      username: '',
      usernameState: '',
      email: '',
      emailState: '',
      isActive: false,
      subscriptionEmbedded: false,
      color: '#fff',
      isColorPickerVisible: false,
      salespersonId: props.match.params.id,
      isEditMode: !!props.match.params.id,
      showTrainingNotCompleteModal: false,
      subscriptionPreviewInvoice: {
        loading: false,
        upcomingInvoice: null,
        remainingDays: null,
        callback: null,
      },
      previewInvoiceModal: false,
      isRejectActiveSalesmen: false,
      activateNeedInfoLoading: false,
      showRejectModal: false,
      showRejectModalBilling: false,
      passwordConfirmButtonDisabled: false,
      goals: [],
      goalsInputs: {},
      goalsLoading: false,
      goalsError: null,
    };
  }

  async componentDidMount() {
    const { isEditMode, salespersonId } = this.state;
    if (isEditMode) {
      this.fetchSalespersonDetails(salespersonId);
      this.setState({ goalsLoading: true }, async () => {
        const { goals: goalsResponse } = await getGoalsList(salespersonId);
        const goalsArray = [
          ...goalsResponse.map(goal => ({
            ...goal,
            id: goal.year,
            year: { value: goal.year, label: goal.year },
            editing: false,
          })),
        ];
        const goals = this.sortGoals(goalsArray);
        this.setState({
          goals,
          goalsLoading: false,
        });
      });
    }

    const { data: company, fetchCompany, fetchBillingInfo, billingInfo } = store.company;

    if (!billingInfo?.paymentMethod) {
      fetchBillingInfo();
    }

    if (!company?.id?.length) {
      fetchCompany();
    }

    this.setState({ activateNeedInfoLoading: true }, async () => {
      const { priceBuilder } = await getPriceBuilder();
      let isReject = false;
      if (!priceBuilder) {
        isReject = true;
      }
      const { cashTerms } = await getCashTerms();
      if (!cashTerms) {
        isReject = true;
      }
      this.setState({
        isRejectActiveSalesmen: isReject,
        activateNeedInfoLoading: false,
      });
    });

    getSubscriptionMeneagmentData()
      .then(response => {
        if (response?.subscriptionEmbedded) {
          this.setState({ subscriptionEmbedded: !!response?.subscriptionEmbedded });
        }
      })
      .catch(e => this.setState({ subscriptionEmbedded: false }));
  }

  sortGoals = goals => {
    const currentYear = moment().format('YYYY');
    const goalsArraySorted = [...(goals?.sort((a, b) => Number(a.year.value) > Number(b.year.value)) || [])];
    return [
      ...goalsArraySorted.filter(goal => Number(goal.year.value) === Number(currentYear)),
      ...goalsArraySorted.filter(goal => Number(goal.year.value) > Number(currentYear)),
      ...goalsArraySorted
        .filter(goal => Number(goal.year.value) < Number(currentYear))
        .sort((a, b) => Number(a.year.value) < Number(b.year.value)),
    ];
  };

  renderRejectModal = () => (
    <SweetAlert
      warning
      style={{ display: 'block', marginTop: '-15%', fontSize: '0.8571em' }}
      title='To add a new salesperson please insert Pricebook info on the "Pricebook Page"'
      onConfirm={() => this.setState({ showRejectModal: false })}
      closeOnClickOutside={false}
      onCancel={() => this.props.history.push(`/admin/pricebook`)}
      confirmBtnBsStyle="danger"
      cancelBtnBsStyle="info"
      confirmBtnText="Not now"
      cancelBtnText="Ok"
      showCancel
    />
  );

  renderRejectBillingModal = () => (
    <SweetAlert
      warning
      style={{ display: 'block', marginTop: '-15%', fontSize: '0.8571em' }}
      title='To add a new salesperson please insert Billing info on the "Billing Page"'
      closeOnClickOutside={false}
      onConfirm={() => this.setState({ showRejectModalBilling: false })}
      onCancel={() => this.props.history.push(`/admin/billing-Info`)}
      confirmBtnBsStyle="danger"
      cancelBtnBsStyle="info"
      confirmBtnText="Not now"
      cancelBtnText="Ok"
      showCancel
    />
  );

  fetchSalespersonDetails = salespersonId => {
    const { companyId } = authService.getUser();
    return getSalespersonList(companyId).then(({ salesmanList }) => {
      const [editableSalesperson] = salesmanList
        .filter(salesperson => salesperson.id === salespersonId)
        .map(({ avatarUrl, name, phone, userName, id, email, color, isActive }) => ({
          avatarUrl,
          name,
          phone,
          email,
          username: userName,
          id,
          color,
          isActive,
        }));
      this.setState({
        ...editableSalesperson,
      });
    });
  };

  handleColor = colorPicked => {
    this.setState({ color: colorPicked.hex });
  };

  handleColorPicker = () => {
    this.setState({ isColorPickerVisible: true });
  };

  handleClose = () => {
    this.setState({ isColorPickerVisible: false });
  };

  validateFields = () => {
    const { phone, email } = this.state;

    const errors = {};

    REQUIRED_FIELDS.forEach(key => {
      errors[`${key}State`] = this.state[key] ? '' : 'has-danger';
    });

    errors.emailState = EMAIL_REG_EX.test(email) ? '' : 'has-danger';
    errors.phoneState = NUMBER_REG_EX.test(phone) ? '' : 'has-danger';

    this.setState({
      ...errors,
    });

    if (Object.values(errors).some(value => !!value)) {
      return false;
    }

    return true;
  };

  resolveResponse = (password, result) => {
    const { isEditMode } = this.state;

    this.setState({
      isLoading: false,
      errorMessage: '',
    });

    if (!isEditMode) {
      this.setState({
        showPasswordModal: true,
        password,
        paymentResponse: result,
      });
    } else {
      if (!result || !result.status) {
        this.props.history.push('/admin/workforce');
        return;
      }

      if (result.status === 'requires_action' || result.status === 'requires_payment_method') {
        this.props.stripe
          .confirmCardPayment(result?.clientSecret, {
            payment_method: result?.paymentMethod,
          })
          .then(payRes => {
            if (payRes.error) {
              // Start code flow to handle updating the payment details.
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc).
              throw payRes;
            } else if (payRes.paymentIntent.status === 'succeeded') {
              this.setState({ showSuccessModal: true });
            }
          })
          .catch(error => {
            this.setState({ showErrorStripeModal: true });
          });
      } else if (result.status === 'succeeded') {
        this.setState({ showSuccessModal: true });
      }
    }
  };

  billingCheck = () => {
    // const { billingInfo } = store.company;
    // if (!billingInfo?.paymentMethod) {
    //   this.setState({ showRejectModalBilling: true });
    //   return false;
    // }
    // return true;
    return this.state.subscriptionEmbedded;
  };

  onSubmit = event => {
    event.preventDefault();

    const { data: company, billingLoading, billingInfo } = store.company;
    if (!company.id?.length || billingLoading) {
      return;
    }

    if (!this.validateFields()) {
      return;
    }

    const {
      avatarUrl,
      avatarFile,
      name,
      phone,
      username,
      email,
      isActive,
      color,
      isEditMode,
      id,
      activateNeedInfoLoading,
      isRejectActiveSalesmen,
      goals,
      subscriptionEmbedded,
    } = this.state;

    const data = {
      name,
      userName: username,
      email,
      phone,
      isActive,
      color,
      avatarUrl,
    };

    const submitContinue = () => {
      const request = isEditMode ? editSalesperson : createSalesperson;

      if (isEditMode) {
        data.salesmanId = id;
      } else if (goals.length) {
        data.salesmanGoals = goals.map(goal => ({
          year: goal.year.value,
          q1: goal.q1,
          q2: goal.q2,
          q3: goal.q3,
          q4: goal.q4,
        }));
      }

      this.setState({
        isLoading: true,
        errorMessage: '',
      });

      return request(data)
        .then(({ password, salesman, paymentIntent }) => {
          if (avatarFile) {
            editSalespersonAvatar({
              avatarFile,
              salesmanId: salesman ? salesman.id : '',
            })
              .then(() => {
                this.resolveResponse(password, paymentIntent);
              })
              .catch(({ errors, message }) => {
                this.setState({
                  errorMessage: (errors && Object.values(errors).join('; ')) || message,
                  isLoading: false,
                  showErrorStripeModal: true,
                });
              });
          } else {
            this.resolveResponse(password, paymentIntent);
          }
        })
        .catch(({ errors, message }) => {
          this.setState({
            errorMessage: (errors && Object.values(errors).join('; ')) || message,
            isLoading: false,
            showErrorStripeModal: true,
          });
        });
    };

    if (isActive) {
      if (!company.isTrainingComplete) {
        this.setState({ showTrainingNotCompleteModal: true });
        return;
      }

      if (!subscriptionEmbedded) {
        this.setState({ showRejectModalBilling: true });
        return;
      }
      if (activateNeedInfoLoading) {
        return;
      }
      if (isActive && isRejectActiveSalesmen) {
        this.setState({ showRejectModal: true });
        return;
      }

      if (isEditMode) {
        getIsSalesInMetadata(id)
          .then(({ isSalesmanInMetadata }) => {
            if (!isSalesmanInMetadata) {
              this.preparePreviewInvoiceModal(submitContinue);
              return;
            }
            submitContinue();
          })
          .catch(() => {
            this.preparePreviewInvoiceModal(submitContinue);
          });
        return;
      }
      this.preparePreviewInvoiceModal(submitContinue);
      return;
    }

    submitContinue();
  };

  renderPreviewInvoiceModal = () => (
    <SweetAlert
      warning
      cancelBtnStyle={{
        fontSize: '1em',
        backgroundColor: `${colors.blue}!important`,
      }}
      style={{ display: 'block', marginTop: '-15%', fontSize: '0.714em' }}
      title={
        this.state.subscriptionPreviewInvoice.loading
          ? 'Loading...'
          : `You've activated a New User. There’s ${this.state.subscriptionPreviewInvoice.remainingDays} days of billing left in this month's billing cycle. Do you agree to have your card charged for ${this.state.subscriptionPreviewInvoice.upcomingInvoice}?`
      }
      onConfirm={() => this.setState({ previewInvoiceModal: false })}
      confirmBtnBsStyle="danger"
      cancelBtnBsStyle="info"
      confirmBtnText="No"
      onCancel={() => {
        this.setState({ previewInvoiceModal: false }, () => {
          this.state.subscriptionPreviewInvoice.callback && this.state.subscriptionPreviewInvoice.callback();
        });
      }}
      closeOnClickOutside={false}
      cancelBtnText="Yes"
      confirmBtnStyle={{
        fontSize: '1em',
        backgroundColor: colors.red,
        color: 'white',
      }}
      showCancel
    />
  );

  preparePreviewInvoiceModal = callback => {
    this.setState(
      {
        subscriptionPreviewInvoice: { loading: true, callback },
        previewInvoiceModal: true,
      },
      async () => {
        try {
          const result = await getSubscriptionPreviewInvoice();
          this.setState(oldState => ({
            subscriptionPreviewInvoice: {
              ...oldState.subscriptionPreviewInvoice,
              loading: false,
              ...result,
            },
          }));
        } catch (e) {
          this.setState({
            subscriptionPreviewInvoice: { loading: false },
            previewInvoiceModal: false,
          });
        }
      }
    );
  };

  renderModal = (onConfirm, onCancel, confirmBtnText, cancelBtnText, info, showCancel = true) => {
    if (info)
      return (
        <SweetAlert
          warning
          confirmBtnStyle={{ fontSize: '1em' }}
          style={{ display: 'block', marginTop: '-15%', fontSize: '0.714em' }}
          title={info}
          onConfirm={onConfirm}
          confirmBtnBsStyle="info"
          confirmBtnText={confirmBtnText}
          onCancel={onCancel}
          cancelBtnText={cancelBtnText}
          cancelBtnStyle={{
            fontSize: '1em',
            backgroundColor: colors.red,
            color: 'white',
          }}
          showCancel={showCancel}
        />
      );

    return (
      <SweetAlert
        warning
        style={{
          display: 'block',
          marginTop: '-265px',
          maxWidth: '600px',
          width: 'calc(100% - 30px)',
        }}
        title=""
        onConfirm={() => {
          this.setState({ passwordConfirmButtonDisabled: true }, () => {
            if (!this.state.paymentResponse) {
              this.props.history.push('/admin/workforce');
              return;
            }
            if (
              this.state.paymentResponse.status === 'requires_action' ||
              this.state.paymentResponse.status === 'requires_payment_method'
            ) {
              this.props.stripe
                .confirmCardPayment(this.state.paymentResponse?.clientSecret, {
                  payment_method: this.state.paymentResponse?.paymentMethod,
                })
                .then(result => {
                  if (result.error) {
                    // Start code flow to handle updating the payment details.
                    // Display error message in your UI.
                    // The card was declined (i.e. insufficient funds, card has expired, etc).
                    throw result;
                  } else if (result.paymentIntent.status === 'succeeded') {
                    this.setState({ paymentResponse: null }, () => {
                      this.props.history.push('/admin/workforce');
                    });
                    return;
                  }
                  this.props.history.push('/admin/workforce');
                })
                .catch(error => {
                  this.setState({
                    paymentResponse: null,
                    showErrorStripeModal: true,
                    showPasswordModal: false,
                  });
                });
            } else if (this.state.paymentResponse.status === 'succeeded') {
              this.setState({ paymentResponse: null }, () => {
                this.props.history.push('/admin/workforce');
              });
            }
          });
        }}
        confirmBtnBsStyle="info"
        confirmBtnText="Ok"
        disabled={this.state.passwordConfirmButtonDisabled}
      >
        <p>Please copy and save the password for the new Salesperson mobile account.</p>
        <p>When closing this message the password is no longer available!</p>
        <p>PASSWORD: {this.state.password}</p>
      </SweetAlert>
    );
  };

  renderSuccessModal = () => (
    <SweetAlert
      warning
      confirmBtnStyle={{ fontSize: '1em' }}
      style={{ display: 'block', marginTop: '-15%', fontSize: '0.714em' }}
      title="Payment has been successfully processed"
      onConfirm={() => {
        this.setState({ showSuccessModal: false });
        this.props.history.push('/admin/workforce');
      }}
      confirmBtnBsStyle="info"
      confirmBtnText="Ok"
    />
  );

  renderErrorStripeModal = () => (
    <SweetAlert
      warning
      confirmBtnStyle={{ fontSize: '1em' }}
      style={{ display: 'block', marginTop: '-15%', fontSize: '0.714em' }}
      title="Something went wrong with your payment. Please check your billing information"
      onConfirm={() => this.props.history.push('/admin/workforce')}
      confirmBtnBsStyle="info"
      confirmBtnText="Ok"
    />
  );

  renderTrainingNotCompleteModal = () => (
    <SweetAlert
      warning
      cancelBtnStyle={{ fontSize: '1em' }}
      confirmBtnStyle={{ fontSize: '1em' }}
      style={{ display: 'block', marginTop: '-15%', fontSize: '0.714em' }}
      title="In order to activate a Salesperson account you must go through an onboarding training. Do you want to sign-up for a training?"
      onCancel={() => {
        window.open('https://calendly.com/marblplatform');
        this.setState({ showTrainingNotCompleteModal: false });
      }}
      onConfirm={() => this.setState({ showTrainingNotCompleteModal: false })}
      closeOnClickOutside={false}
      confirmBtnBsStyle="danger"
      confirmBtnText="No"
      cancelBtnBsStyle="info"
      cancelBtnText="Yes"
      showCancel
    />
  );

  // eslint-disable-next-line react/sort-comp
  typeEmail(e) {
    const emailState = EMAIL_REG_EX.test(e.target.value) ? 'has-success' : 'has-danger';

    this.setState({ email: e.target.value, emailState });
  }

  typeNumber(e) {
    const numberRex = NUMBER_REG_EX;
    let { phone, phoneState } = this.state;
    phone = e.target.value;
    if (numberRex.test(phone)) {
      phoneState = 'has-success';
    } else {
      phoneState = 'has-danger';
    }
    this.setState({ phone, phoneState });
  }

  typePassword(e) {
    const { webportal } = this.state;
    webportal.password = e.target.value;
    if (webportal.password === webportal.confirmPassword) {
      webportal.passwordState = 'has-success';
    } else {
      webportal.passwordState = 'has-danger';
    }
    this.setState({ webportal });
  }

  typeConfirmPassword(e) {
    const { webportal } = this.state;
    webportal.confirmPassword = e.target.value;
    if (webportal.password === webportal.confirmPassword) {
      webportal.passwordState = 'has-success';
    } else {
      webportal.passwordState = 'has-danger';
    }
    this.setState({ webportal });
  }

  typeInput(e, field, required) {
    const newValues = {};
    newValues[field] = e.target.value;
    if (newValues[field]) {
      newValues[`${field}State`] = 'has-success';
    } else {
      newValues[`${field}State`] = required ? 'has-danger' : '';
    }
    this.setState({ ...newValues });
  }

  getErrorMessage = e =>
    e.errors && typeof e.errors === 'object' && Object.keys(e.errors)?.length
      ? Object.keys(e.errors).map(key => `${key}: ${e.errors[key]}\n`)
      : e.message;

  typeImage = name => value => {
    this.setState({ [`${name}File`]: value, [`${name}Url`]: '' });
  };

  handleSaveGoal = id => {
    const { goals, goalsError, goalsInputs: data, isEditMode } = this.state;
    if (
      !data.q1?.toString()?.length ||
      !data.q2?.toString()?.length ||
      !data.q3?.toString()?.length ||
      !data.q4?.toString()?.length
    ) {
      this.setState({ goalsError: 'Please fill all "Q" fields' });
      return;
    }

    const targetGoalYear = data.year?.value;
    const currentYear = moment().format('YYYY');
    if (
      targetGoalYear &&
      targetGoalYear.toString() !== currentYear &&
      (!goals.find(goal => goal.id?.toString() === currentYear) ||
        (id.toString() !== targetGoalYear && id.toString() === currentYear))
    ) {
      this.setState({ goalsError: 'You need the current year goal' });
      return;
    }

    if (goalsError?.length) {
      this.setState({ goalsError: null });
    }

    const total = checkZeroFormat(
      (Number(this.state.goalsInputs.q1) || 0) +
      (Number(this.state.goalsInputs.q2) || 0) +
      (Number(this.state.goalsInputs.q3) || 0) +
      (Number(this.state.goalsInputs.q4) || 0)
    );
    if (id === 'addNewGoal' || Number(id) !== Number(data.year.value)) {
      if (!isEditMode) {
        const goalsSorted = this.sortGoals([
          { ...data, id: data.year.value, editing: false, total },
          ...goals.filter(el => el.id !== 'addNewGoal' && Number(el.id) !== Number(id)),
        ]);
        this.setState({
          goals: goalsSorted,
          goalsInputs: {},
        });
        return;
      }

      this.setState({ goalsLoading: true }, () => {
        createGoal({
          ...data,
          salesmanId: this.state.salespersonId,
          year: data.year.value,
        })
          .then(async () => {
            await deleteGoal({ salesmanId: this.state.salespersonId, year: id });
            const goalsSorted = this.sortGoals([
              { ...data, id: data.year.value, editing: false, total },
              ...goals.filter(el => el.id !== 'addNewGoal' && Number(el.id) !== Number(id)),
            ]);
            this.setState({
              goalsLoading: false,
              goals: goalsSorted,
              goalsInputs: {},
            });
          })
          .catch(e => {
            const errorMessage = this.getErrorMessage(e);
            this.setState({ goals, goalsLoading: false, goalsError: errorMessage });
          });
      });
      return;
    }
    if (!isEditMode) {
      const goalsSorted = this.sortGoals([
        ...goals.map(el => (el.id === id ? { ...data, id: el.id, editing: false, total } : el)),
      ]);
      this.setState({
        goals: goalsSorted,
        goalsInputs: {},
      });
      return;
    }

    this.setState({ goalsLoading: true }, () => {
      editGoal({
        ...data,
        salesmanId: this.state.salespersonId,
        year: data.year.value,
      })
        .then(() => {
          const goalsSorted = this.sortGoals([
            ...goals.map(el => (el.id === id ? { ...data, id: el.id, editing: false, total } : el)),
          ]);
          this.setState({
            goalsLoading: false,
            goals: goalsSorted,
            goalsInputs: {},
          });
        })
        .catch(e => {
          const errorMessage = this.getErrorMessage(e);
          this.setState({ goals, goalsLoading: false, goalsError: errorMessage });
        });
    });
  };

  handleDeleteGoal = id => {
    const { goals, goalsError, isEditMode } = this.state;

    if (id?.toString() === moment().format('YYYY')) {
      this.setState({ goalsError: 'Cannot delete current year goal' });
      return;
    }

    if (goalsError?.length) {
      this.setState({ goalsError: null });
    }

    const sorted = this.sortGoals([...goals.filter(el => el.id !== id)]);
    this.setState(
      { goals: sorted, goalsInputs: {}, goalsLoading: isEditMode },
      isEditMode
        ? () => {
          deleteGoal({ salesmanId: this.state.salespersonId, year: id })
            .then(() => this.setState({ goalsLoading: false }))
            .catch(e => {
              const errorMessage = this.getErrorMessage(e);
              this.setState({ goals, goalsLoading: false, goalsError: errorMessage });
            });
        }
        : null
    );
  };

  renderActions = id => {
    const { goals } = this.state;
    let targetGoal = { id: 'addNewGoal', editing: true };
    if (id !== 'addNewGoal') {
      targetGoal = goals.find(el => el.id === id);
      if (!targetGoal) {
        return <div />;
      }
    }

    return (
      <div className="actions-right">
        {targetGoal.editing ? (
          <Button
            type="button"
            onClick={() => this.handleSaveGoal(id)}
            className="btn-icon btn-round"
            color="success"
            size="sm"
          >
            <i className="fa fa-save" />
          </Button>
        ) : (
          <Button
            type="button"
            onClick={() => {
              const sorted = this.sortGoals([
                ...goals
                  .map(el =>
                    // eslint-disable-next-line no-nested-ternary
                    el.id === id ? { ...el, editing: true } : el.id === 'addNewGoal' ? null : { ...el, editing: false }
                  )
                  .filter(el => el),
              ]);
              this.setState({
                goals: sorted,
                goalsInputs: { ...goals.find(el => el.id === id), editing: true },
              });
            }}
            className="btn-icon btn-round"
            color="info"
            size="sm"
          >
            <i className="fa fa-edit" />
          </Button>
        )}

        {targetGoal.editing ? (
          <Button
            type="button"
            onClick={() =>
              this.setState(oldState => ({
                goals: [
                  ...this.sortGoals([
                    ...oldState.goals
                      .map(goal => ({ ...goal, editing: false }))
                      .filter(goal => goal.id !== 'addNewGoal'),
                  ]),
                ],
              }))
            }
            className="btn-icon btn-round"
            color="danger"
            size="sm"
          >
            <i className="fa fa-undo" />
          </Button>
        ) : (
          <Button
            type="button"
            onClick={() => this.handleDeleteGoal(id)}
            className="btn-icon btn-round"
            color="danger"
            size="sm"
          >
            <i className="fa fa-times" />
          </Button>
        )}
      </div>
    );
  };

  renderCells = cellInfo => {
    const { id } = cellInfo.original;
    const { id: key } = cellInfo.column;
    const { value } = cellInfo;
    const currentGoal = this.state.goals.find(el => el.id === id);
    const isEditing = !!currentGoal?.editing;
    // eslint-disable-next-line no-restricted-globals
    const isOld = !isNaN(id) && Number(id) < Number(moment().format('YYYY'));
    // eslint-disable-next-line no-nested-ternary
    const textColor = !isOld ? colors.black : key === 'total' ? '#555' : colors.gray;
    switch (key) {
      case 'year':
        if (isEditing) {
          const yearsList = this.getYearsList(id);
          return (
            <Select
              isDisabled={isOld}
              isClearable={false}
              value={
                this.state.goalsInputs?.year?.value?.toString()?.length
                  ? this.state.goalsInputs.year
                  : yearsList.find(el => el.value === id)
              }
              defaultValue={yearsList[0]}
              onChange={selected =>
                this.setState(oldState => ({ goalsInputs: { ...oldState.goalsInputs, year: selected } }))
              }
              options={yearsList}
            />
          );
        }
        return <span style={{ color: textColor }}>{value.value}</span>;
      case 'total':
        return (
          <NumberFormat
            disabled
            className="form-control"
            thousandSeparator=" "
            decimalSeparator="."
            decimalScale={2}
            style={{ border: 'none', color: textColor, backgroundColor: 'transparent', fontWeight: 'bold' }}
            value={
              isEditing
                ? checkZeroFormat(
                  (Number(this.state.goalsInputs.q1) || 0) +
                  (Number(this.state.goalsInputs.q2) || 0) +
                  (Number(this.state.goalsInputs.q3) || 0) +
                  (Number(this.state.goalsInputs.q4) || 0)
                )
                : value
            }
          />
        );
      default:
        return isEditing ? (
          <NumberFormatInput
            className="form-control"
            thousandSeparator=" "
            decimalSeparator="."
            decimalScale={2}
            style={{ borderColor: colors.blue }}
            onBlur={() => this.handleBlur(key)}
            value={this.state.goalsInputs[key]}
            onValueChange={data => {
              this.setState(oldState => ({
                goalsInputs: { ...oldState.goalsInputs, [key]: checkZeroFormat(data.floatValue) },
              }));
            }}
          />
        ) : (
          <span style={{ color: textColor }}>{value}</span>
        );
    }
  };

  handleBlur = (name, callback) => {
    const { goalsInputs } = this.state;
    this.setState(
      oldState => ({
        goalsInputs: {
          ...oldState.goalsInputs,
          [name]: checkZeroFormat(goalsInputs[name]),
        },
      }),
      callback
    );
  };

  getYearsList = id => {
    const { goals } = this.state;

    const years = [];
    const dateStart = moment();
    const dateEnd = moment().add(10, 'y');
    while (dateEnd.diff(dateStart, 'years') >= 0) {
      years.push(dateStart.format('YYYY'));
      dateStart.add(1, 'year');
    }
    return years
      .map(el =>
        goals.find(goal => goal.year.value?.toString() === el && goal.id !== id) ? null : { value: el, label: el }
      )
      .filter(el => el);
  };

  render() {
    const {
      avatarUrl,
      avatarFile,
      name,
      nameState,
      phone,
      phoneState,
      username,
      usernameState,
      email,
      emailState,
      isActive,
      color,
      isColorPickerVisible,
      showPasswordModal,
      showSuccessModal,
      showErrorStripeModal,
      isLoading,
      errorMessage,
      previewInvoiceModal,
      showTrainingNotCompleteModal,
      showRejectModal,
      showRejectModalBilling,
      goalsLoading,
      goalsError,
    } = this.state;
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          {showPasswordModal && this.renderModal()}
          {showSuccessModal && this.renderSuccessModal()}
          {showErrorStripeModal && this.renderErrorStripeModal()}
          {showTrainingNotCompleteModal && this.renderTrainingNotCompleteModal()}
          {previewInvoiceModal && this.renderPreviewInvoiceModal()}
          {showRejectModal && this.renderRejectModal()}
          {showRejectModalBilling && this.renderRejectBillingModal()}
          <Row>
            <Col xs={12} md={12}>
              <Card className="card-stats card-raised" style={{ backgroundColor: colors.lightBlue }}>
                <form onSubmit={this.onSubmit}>
                  <CardBody style={{ padding: '2vw' }}>
                    <CardTitle>Profile</CardTitle>
                    <ImageUpload
                      avatar
                      imagePreviewUrl={avatarUrl ? `${config.imagesUrl}${avatarUrl}` : ''}
                      file={avatarFile}
                      ext={['png', 'jpg']}
                      maxSize={5}
                      onChange={this.typeImage('avatar')}
                    />
                    <br />
                    <Row>
                      <Col lg={6} md={12} xs={12}>
                        <CardTitle>User Information</CardTitle>
                        <div className="form-row">
                          <LabelInput
                            className={`col-md-12 col-xs-12 has-label ${nameState}`}
                            label="Name"
                            id="name"
                            onChange={e => this.typeInput(e, 'name', true)}
                            value={name}
                            validationClass={nameState}
                            required
                          />
                        </div>
                        <div className="form-row">
                          <LabelInput
                            className={`col-md-12 col-xs-12 has-label ${phoneState}`}
                            label="Phone"
                            id="phone"
                            onChange={e => this.typeNumber(e)}
                            validationClass={phoneState}
                            value={phone}
                            required
                          />
                        </div>
                        <div className="form-row">
                          <LabelInput
                            className={`col-md-12 col-xs-12 has-label ${usernameState}`}
                            label="Username"
                            id="username"
                            placeholder=""
                            onChange={e => this.typeInput(e, 'username', true)}
                            value={username}
                            validationClass={usernameState}
                            required
                          />
                        </div>
                        <div className="form-row">
                          <LabelInput
                            className={`col-md-12 col-xs-12 has-label ${emailState}`}
                            label="Email Address"
                            id="email"
                            autoComplete="off"
                            onChange={e => this.typeEmail(e)}
                            type="email"
                            validationClass={emailState}
                            value={email}
                            required
                          />
                        </div>
                        <FormGroup className="col-md-12 col-xs-12 has-label">
                          <Label for="isActive" style={styles.label}>
                            Status
                          </Label>
                          <Switch
                            id="isActive"
                            defaultValue={false}
                            value={isActive}
                            onChange={() => this.billingCheck() && this.setState({ isActive: !isActive })}
                          />
                        </FormGroup>
                        <FormGroup className="col-md-12 col-xs-12 has-label">
                          <div style={styles.row}>
                            <Label style={styles.label}>Choose a color</Label>
                            <div
                              tabIndex={0}
                              onKeyDown={this.handleColorPicker}
                              role="button"
                              style={{
                                ...styles.colorButton,
                                ...{ backgroundColor: color },
                              }}
                              onClick={this.handleColorPicker}
                            />
                            {isColorPickerVisible ? (
                              <div style={styles.popover}>
                                <div
                                  role="button"
                                  tabIndex={0}
                                  onKeyDown={this.handleClose}
                                  style={styles.cover}
                                  onClick={this.handleClose}
                                />
                                <SketchPicker color={color} onChangeComplete={this.handleColor} />
                              </div>
                            ) : null}
                          </div>
                        </FormGroup>
                        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                        <div className="col-md-12 col-xs-12">
                          <Button disabled={isLoading} type="submit" color="info" className="pull-right">
                            Save
                          </Button>
                        </div>
                      </Col>
                      <Col lg={6} md={12} xs={12}>
                        <CardTitle>Goals</CardTitle>
                        <Button
                          type="button"
                          onClick={() => {
                            if (this.state.goals.find(goal => goal.id === 'addNewGoal')) {
                              return;
                            }
                            const year = this.getYearsList('addNewGoal')[0];
                            this.setState(oldState => ({
                              goals: [
                                {
                                  id: 'addNewGoal',
                                  q1: 0,
                                  q2: 0,
                                  q3: 0,
                                  total: 0,
                                  year,
                                  editing: true,
                                },
                                ...this.sortGoals([...oldState.goals?.map(el => ({ ...el, editing: false }))]),
                              ],
                              goalsInputs: {
                                ...oldState.goalsInputs,
                                year,
                              },
                            }));
                          }}
                          color="info"
                          className="btn-round"
                        >
                          <i className="now-ui-icons ui-1_simple-add" /> Add goal
                        </Button>
                        <ReactTable
                          showPagination={false}
                          data={this.state.goals}
                          loading={isLoading || goalsLoading}
                          minRows={3}
                          columns={[
                            {
                              Header: 'YEAR',
                              accessor: 'year',
                              sortable: false,
                              filterable: false,
                              Cell: this.renderCells,
                            },
                            {
                              Header: 'Q1 ($)',
                              accessor: 'q1',
                              sortable: false,
                              filterable: false,
                              Cell: this.renderCells,
                            },
                            {
                              Header: 'Q2 ($)',
                              accessor: 'q2',
                              sortable: false,
                              filterable: false,
                              Cell: this.renderCells,
                            },
                            {
                              Header: 'Q3 ($)',
                              accessor: 'q3',
                              sortable: false,
                              filterable: false,
                              Cell: this.renderCells,
                            },
                            {
                              Header: 'Q4 ($)',
                              accessor: 'q4',
                              sortable: false,
                              filterable: false,
                              Cell: this.renderCells,
                            },
                            {
                              Header: 'TOTAL',
                              accessor: 'total',
                              sortable: false,
                              filterable: false,
                              Cell: this.renderCells,
                            },
                            {
                              Header: 'ACTIONS',
                              accessor: 'actions',
                              sortable: false,
                              filterable: false,
                              Cell: data => this.renderActions(data.original?.id),
                            },
                          ]}
                          // pageSize={this.getPageSize()}
                          showPaginationBottom={this.state.goals.length > 5}
                          showPaginationTop={false}
                          className="-striped -highlight"
                        />
                        {goalsError && (
                          <Alert color="danger" onClick={() => this.setState({ goalsError: null })}>
                            {goalsError}
                          </Alert>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </form>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const AddEditSalePersonForm = props => {
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ stripe, elements }) => <AddEditSalePerson stripe={stripe} elements={elements} {...props} />}
      </ElementsConsumer>
    </Elements>
  );
};

export default observer(AddEditSalePersonForm);
