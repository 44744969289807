import React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Form,
  Container,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  Label,
} from 'reactstrap';

import image from 'assets/img';
import { login } from 'lib/api/authApi';
import authService from 'lib/services/authService';
import { parseJwt } from 'utils/helpers';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      usernameState: '',
      password: '',
      passwordState: '',
      errorState: '',
      isInProgress: false,
    };
  }

  componentDidMount() {
    document.body.classList.add('login-page');
  }

  componentWillUnmount() {
    document.body.classList.remove('login-page');
  }

  checkAllFields = () => {
    const { username, password } = this.state;

    const errors = {};

    errors.usernameState = !username ? 'has-danger' : '';
    errors.passwordState = !password ? 'has-danger' : '';

    const isValid = username && password;

    this.setState({
      errorState: !isValid ? 'All fields are required' : '',
      ...errors,
    });

    return isValid;
  };

  onSubmit = event => {
    event.preventDefault();

    if (!this.checkAllFields()) {
      return;
    }

    const { username, password } = this.state;
    this.setState({ isInProgress: true });

    const platform = window.navigator.userAgent;
    const clientType = 'WEBPORTAL';

    login({ username, password, platform, clientType })
      .then(({ token }) => {
        const { roles } = parseJwt(token);
        authService.setUser({
          username,
          token,
          roles,
        });
        const { history } = this.props;
        if (authService.isSuperUser()) {
          history.push('/super-admin');
        } else {
          history.push('/');
        }
      })
      .catch(() => {
        this.setState({
          errorState: 'Wrong login or password',
          isInProgress: false,
        });
      });
  };

  handleOnChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  goToRegisterPage = () => {
    const { history } = this.props;

    history.push('/admin/register');
  };

  goToRestorePage = () => {
    const { history } = this.props;

    history.push('/auth/restore-page');
  };

  render() {
    const { usernameFocus, passwordFocus, usernameState, passwordState, errorState, isInProgress } = this.state;
    return (
      <>
        <div className="login-page">
          <Container>
            <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
              <Form onSubmit={this.onSubmit}>
                <Card className="card-login card-plain">
                  <CardHeader>
                    <div className="logo-container">
                      <img src={image.logo} alt="now-logo" />
                    </div>
                  </CardHeader>
                  <CardBody>
                    {errorState && <Label style={{ color: 'red' }}>{errorState}</Label>}
                    <InputGroup
                      className={`no-border form-control-lg ${usernameState} ${
                        usernameFocus ? 'input-group-focus' : ''
                      }`}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons users_circle-08" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        placeholder="username"
                        onFocus={e => this.setState({ usernameFocus: true })}
                        onBlur={e => this.setState({ usernameFocus: false })}
                        onChange={this.handleOnChange}
                        name="username"
                      />
                    </InputGroup>
                    <InputGroup
                      className={`no-border form-control-lg ${passwordState} ${
                        passwordFocus ? 'input-group-focus' : ''
                      }`}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons ui-1_lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="password"
                        placeholder="password"
                        onFocus={e => this.setState({ passwordFocus: true })}
                        onBlur={e => this.setState({ passwordFocus: false })}
                        onChange={this.handleOnChange}
                        name="password"
                      />
                    </InputGroup>
                    <div className="forgot-button mt-3 ml-3" onClick={this.goToRestorePage}>
                      Forgot your password?
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Button
                      disabled={isInProgress}
                      type="submit"
                      block
                      color="info"
                      size="lg"
                      className="mb-3 btn-round"
                    >
                      Login
                    </Button>
                    <Button
                      style={{ maxWidth: '120px', margin: '0 auto' }}
                      block
                      color="info"
                      size="lg"
                      className="mb-3 btn-round"
                      onClick={this.goToRegisterPage}
                    >
                      Register
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
            <div className="privacy-terms">
              <a href="https://marblplatform.com/terms/" className="pterms-link">
                Terms&Conditions
              </a>
              <a href="https://marblplatform.com/privacy/" className="pterms-link">
                Privacy Policy
              </a>
            </div>
          </Container>
        </div>
        <div className="full-page-background" style={{ backgroundImage: `url(${image.background})` }} />
      </>
    );
  }
}

export default LoginPage;
