export const Status = {
  ACTIVE: true,
  INACTIVE: false,
};

export const SortType = {
  ASC: 0,
  DESC: 1,
};

export const Location = {
  CITY: 'City',
  STATE: 'State',
  ZIP_CODE: 'Zip',
};

export const generateStatusCompanyParams = status => ({
  OperatorType: 1,
  Operands: [
    {
      OperatorType: 0,
      Operands: null,
      Field: 'IsActiveOnUserCount',
      FilterType: 1,
      Value: status,
    },
  ],
});

export const generateSearchParams = value => {
  return {
    OperatorType: 1,
    Operands: [
      {
        OperatorType: 2,
        Operands: [
          {
            OperatorType: 0,
            Field: Location.CITY,
            FilterType: 7,
            Value: value,
          },
          {
            OperatorType: 0,
            Field: Location.STATE,
            FilterType: 7,
            Value: value,
          },
          {
            OperatorType: 0,
            Field: Location.ZIP_CODE,
            FilterType: 7,
            Value: value,
          },
          {
            OperatorType: 0,
            Field: 'Name',
            FilterType: 7,
            Value: value,
          },
          {
            OperatorType: 0,
            Field: 'Phone1',
            FilterType: 7,
            Value: value,
          },
          {
            OperatorType: 0,
            Field: 'Line1',
            FilterType: 7,
            Value: value,
          },
        ],
      },
    ],
  };
};

export const generateLocationParams = ({ city, state, zip } = {}) => {
  return {
    OperatorType: 1,
    Operands: [
      {
        OperatorType: 0,
        Field: Location.CITY,
        FilterType: 7,
        Value: city,
      },
      {
        OperatorType: 0,
        Field: Location.STATE,
        FilterType: 7,
        Value: state,
      },
      {
        OperatorType: 0,
        Field: Location.ZIP_CODE,
        FilterType: 7,
        Value: zip,
      },
    ],
  };
};

export const generateOnBoardingStatusParams = status => {
  return {
    OperatorType: 1,
    Operands: [
      {
        OperatorType: 0,
        Field: 'IsTrainingComplete',
        FilterType: 1,
        Value: status,
      },
    ],
  };
};

export const generateSortParams = sortType => {
  return {
    OrderBy: [
      {
        Field: 'CreatedAt',
        Order: sortType,
      },
    ],
  };
};

export const generateSignUpDateParams = (startDate, endDate) => {
  return {
    OperatorType: 1,
    Operands: [
      {
        OperatorType: 0,
        Field: 'CreatedAt',
        FilterType: 6,
        Value: startDate,
      },
      {
        OperatorType: 0,
        Field: 'CreatedAt',
        FilterType: 5,
        Value: endDate, // + 1 day,
      },
    ],
  };
};

export const generateUserNumberParams = userNumber => {
  return {
    OperatorType: 0,
    Field: 'ActiveUsersCount',
    FilterType: 1,
    Value: userNumber,
  };
};
