import authService from '../services/authService';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const DEFAULT_AJAX_OPTIONS = {
  json: true,
  headers: {},
  suppress401Action: false,
  supressErrorHandling: false,
};

async function errorCodesHandler(response) {
  if (response.ok) {
    return response;
  }

  const { status } = response;

  if (response && response.status === 401) {
    // auto logout if we send /api/logout
    // we get 401 it works
    authService.deleteUser();
    window.location.reload();
  }

  let responseText = '';
  try {
    responseText = await response.text();
  } catch (e) {
    responseText = null;
  }

  return response
    .json()
    .catch(() => {
      return response.text();
    })
    .catch(err => {
      return Promise.reject({
        statusCode: status,
        statusText: response.statusText,
        responseText,
      });
    })
    .then(body =>
      Promise.reject({
        ...body,
      })
    );
}

function ajaxInternal(url, method, data, additionalOptions) {
  const targetURL = new URL(url);

  const options = {
    ...DEFAULT_AJAX_OPTIONS,
    ...additionalOptions,

    requestId: (additionalOptions && additionalOptions.requestId) || `${url}--${Date.now()}`,
  };

  const fetchOptions = {
    method,
    headers: {
      Accept: '*/*',

      ...options.headers,
    },

    credentials: 'same-origin',
  };

  if (data && method !== 'GET') {
    if (options.json) {
      fetchOptions.headers['Content-Type'] = 'application/json';
      fetchOptions.body = JSON.stringify(data);
    } else {
      fetchOptions.body = data;
    }
  }

  if (data && method === 'GET' && Object.keys(data).length) {
    const searchParams = new URLSearchParams();
    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key])) {
        data[key].forEach(el => searchParams.append(key, typeof el === 'object' ? JSON.stringify(el) : el));
      } else {
        searchParams.append(key, typeof data[key] === 'object' ? JSON.stringify(data[key]) : data[key]);
      }
    });
    targetURL.search = searchParams;
  }

  return fetch(targetURL, fetchOptions).then(response => {
    return response;
  });
}

function ajax(url, method, data, options) {
  return ajaxInternal(`${BASE_URL}${url}`, method, data, options)
    .then(response => {
      if (options && options.supressErrorHandling) {
        return response;
      }

      return errorCodesHandler(response);
    })

    .then(response => response.text())
    .then(text => (text ? JSON.parse(text) : {}));
}

export function postJSON(url, data, options) {
  return ajax(url, 'POST', data, options);
}

export function getJSON(url, options, data) {
  return ajax(url, 'GET', data, options);
}

export function putJSON(url, data, options) {
  return ajax(url, 'PUT', data, options);
}

export function deleteJSON(url, data, options) {
  return ajax(url, 'DELETE', data, options);
}

export function downloadFile(fileUrl, options) {
  fetch(`${BASE_URL}${fileUrl}`, {
    method: 'GET',
    headers: options.headers,
  })
    .then(response => response.blob())
    .then(blob => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Customer import template.xlsx`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
}

export function submitMultipartForm(url, fields, newOptions) {
  const form = new FormData();

  for (const key in fields) {
    if (fields.hasOwnProperty(key)) {
      form.append(key, fields[key]);
    }
  }

  const options = newOptions || {};
  options.json = false;

  // won't set Content-Type
  // will be set automatically because of FormData

  return ajax(url, options.method || 'PUT', form, options);
}
