import authService from 'lib/services/authService';

import { deleteJSON, getJSON, postJSON } from './ajax';

export function getBillingInformation(SubscriptionType) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return getJSON('/api/billinginfo', REQUEST_OPTIONS, { SubscriptionType });
}
export function getPublicKey() {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return getJSON('/api/stripe/publickey', REQUEST_OPTIONS);
}

export function createSubscribe(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return postJSON('/api/subscription', data, REQUEST_OPTIONS);
}

export function changeSubscriptionPayment(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return postJSON('/api/changepayment', data, REQUEST_OPTIONS);
}

export function getSubscriptionPreviewInvoice() {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return getJSON('/api/subscription/preview-invoice', REQUEST_OPTIONS);
}
export function deleteSubscribe() {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return deleteJSON('/api/subscription', null, REQUEST_OPTIONS);
}

export function restoreSubscribe() {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return postJSON('/api/subscription/restore', null, REQUEST_OPTIONS);
}

export function addDiscount(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return postJSON('/api/promotioncode', data, REQUEST_OPTIONS);
}

export function removeDiscount() {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return postJSON('/api/unpinpromotioncode', null, REQUEST_OPTIONS);
}

export function getSubscriptionMeneagmentData() {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return postJSON('/api/subscription/management/embedded', null, REQUEST_OPTIONS);
}

export function getBillingInfoPage() {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return getJSON('/api/billinginfo/billingpage', REQUEST_OPTIONS);
}
