export const PRICE_BUILDER_INITIAL_STATE = {
  leadLaborRate: {
    defaultValue: '',
    isStrictInequality: true,
  },
  helperLaborRate: {
    defaultValue: '',
    isStrictInequality: true,
  },
  billableEfficiencyPercent: {
    defaultValue: '',
    isStrictInequality: true,
  },
  laborOverheadPercent: {
    defaultValue: '',
    isStrictInequality: true,
  },
  financeFeeToContractor: {
    defaultValue: '',
    isStrictInequality: false,
  },
  installationDepartmentOverheadPercent: {
    defaultValue: '',
    isStrictInequality: true,
  },
  salesCommissionPercent: {
    defaultValue: '',
    isStrictInequality: true,
  },
  targetNetProfitPercent: {
    defaultValue: '',
    isStrictInequality: true,
  },
  materialSalesTaxPercent: {
    defaultValue: '0',
    isStrictInequality: false,
  },
  averageMilesRoundTrip: {
    defaultValue: '',
    isStrictInequality: true,
  },
  vehicleCostPerMile: {
    defaultValue: '',
    isStrictInequality: false,
  },
  hourlyTruckCharge: {
    defaultValue: '',
    isStrictInequality: false,
  },
  riskAndProficiencyAndWarrantyPercent: {
    defaultValue: '',
    isStrictInequality: false,
  },
  electricalSubcontractorPerIndoor: {
    defaultValue: '',
    isStrictInequality: false,
  },
  electricalSubcontractorPerOutdoor: {
    defaultValue: '',
    isStrictInequality: false,
  },
};
