import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PerfectScrollbar from 'perfect-scrollbar';
import NotificationAlert from 'react-notification-alert';
import AdminHeader from 'components/Navbars/AdminHeader';
import Footer from 'components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';
import { superUserRoutes, unauthorizedRoutes } from 'routes';
import authService from 'lib/services/authService';
import SweetAlert from 'react-bootstrap-sweetalert';
import { logout } from '../lib/api/authApi';
// import FixedPlugin from 'components/FixedPlugin/FixedPlugin';

let ps;

const renderModal = (onConfirm, onCancel, confirmBtnText) => (
  <SweetAlert
    warning
    style={{ display: 'block', marginTop: '-15%', fontSize: '0.8571em' }}
    title="Are you sure?"
    onConfirm={onConfirm}
    onCancel={onCancel}
    confirmBtnBsStyle="info"
    cancelBtnBsStyle="danger"
    confirmBtnText={confirmBtnText}
    cancelBtnText="Cancel"
    showCancel
  />
);

class SuperAdmin extends React.Component {
  constructor(props) {
    super(props);
    // sidebarMini: true,
    this.state = {
      backgroundColor: 'blue',
      showLogoutModal: false,
      isAuthorized: !!authService.getUser().token,
    };

    this.notificationAlert = React.createRef();

    this.mainPanel = React.createRef();
  }

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      document.documentElement.className += ' perfect-scrollbar-on';
      document.documentElement.classList.remove('perfect-scrollbar-off');
      ps = new PerfectScrollbar(this.mainPanel.current);
    }
  }

  componentDidUpdate(e) {
    if (e.history.action === 'PUSH') {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.mainPanel.current.scrollTop = 0;
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
      document.documentElement.className += ' perfect-scrollbar-off';
      document.documentElement.classList.remove('perfect-scrollbar-on');
    }
  }

  minimizeSidebar = () => {
    if (document.body.classList.contains('sidebar-mini')) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle('sidebar-mini');
  };
  // handleColorClick = color => {
  //   this.setState({ backgroundColor: color });
  // };
  renderLogoutModal = () =>
    renderModal(
      () => {
        logout();
        authService.deleteUser();
        this.props.history.push('/auth/login-page');
      },
      () => this.setState({ showLogoutModal: false }),
      'Yes'
    );

  getRoutes = routes => {
    return routes.map(prop => {
      if (prop.layout === '/super-admin') {
        return <Route path={prop.layout + prop.path} component={prop.component} key={prop.path} />;
      }
      return null;
    });
  };

  getActiveRoute = routes => {
    const activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i += 1) {
      if (window.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return activeRoute;
  };

  render() {
    const { backgroundColor, showLogoutModal, isAuthorized } = this.state; // sidebarMini,
    return (
      <div className="wrapper">
        {showLogoutModal && this.renderLogoutModal()}
        <NotificationAlert ref={this.notificationAlert} />
        <Sidebar
          {...this.props}
          routes={isAuthorized ? superUserRoutes.filter(e => e.menu) : unauthorizedRoutes}
          minimizeSidebar={this.minimizeSidebar}
          backgroundColor={backgroundColor}
          logout={() => this.setState({ showLogoutModal: true })}
        />
        <div className="main-panel" ref={this.mainPanel}>
          <AdminHeader {...this.props} brandText={this.getActiveRoute([...superUserRoutes])} />
          <Switch>
            {this.getRoutes(superUserRoutes)}
            <Redirect from="/super-admin" to="/super-admin/dashboard/" />
          </Switch>
          {window.location.href.indexOf('full-screen-maps') !== -1 ? null : <Footer fluid />}
        </div>
        {/* <FixedPlugin handleMiniClick={this.minimizeSidebar} sidebarMini={sidebarMini} bgColor={backgroundColor} handleColorClick={this.handleColorClick} /> */}
      </div>
    );
  }
}

export default SuperAdmin;
