import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'perfect-scrollbar';
import { Nav, Collapse, Button } from 'reactstrap';
import avatar from 'assets/img/default-avatar.png';
import authService from 'lib/services/authService';
import image from '../../assets/img';

let ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      ...this.getCollapseStates(props.routes),
    };
    this.sidebar = React.createRef();
  }

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
    // to stop the warning of calling setState of unmounted component
    let id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  }

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      }
      if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        const st = {};
        st[prop.state] = !this.state[prop.state];
        return (
          <li className={this.getCollapseInitialState(prop.views) ? 'active' : ''} key={key}>
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }

      if (prop.path === '/logout') {
        return (
          <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
            <a
              href="/#"
              onClick={e => {
                e.preventDefault();
                // запрос на разлогиниваение
                this.props.logout();
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>{prop.name}</p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">{prop.name}</span>
                </>
              )}
            </a>
          </li>
        );
      }

      return (
        <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink to={prop.layout + prop.path} activeClassName="">
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p className="sidebar-normal">{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return window.location.href.indexOf(routeName) > -1 ? 'active' : '';
  };

  render() {
    const { username } = authService.getUser();

    return (
      <>
        <div className="sidebar" data-color={this.props.backgroundColor}>
          <div className="sidebar-wrapper" ref={this.sidebar}>
            <div className="logo">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a role="button" className="simple-text logo-mini">
                <div className="logo-img">
                  <img src={image.marblButton} alt="react-logo" />
                </div>
              </a>
              <div className="info">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <span className="simple-text logo-normal" data-toggle="collapse">
                  Marbl Portal
                </span>
              </div>
              {/* */}
              <div className="navbar-minimize">
                <Button
                  outline
                  className="btn-round btn-icon"
                  color="neutral"
                  id="minimizeSidebar"
                  onClick={() => this.props.minimizeSidebar()}
                >
                  <i className="now-ui-icons text_align-center visible-on-sidebar-regular" />
                  <i className="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini" />
                </Button>
              </div>
            </div>

            {username && (
              <div className="user">
                <div className="photo">
                  <img src={avatar} alt="Avatar" />
                </div>
                <div className="info">
                  <a
                    href="#pablo"
                    data-toggle="collapse"
                    aria-expanded={this.state.openAvatar}
                    onClick={() => this.setState({ openAvatar: !this.state.openAvatar })}
                  >
                    <span>
                      {username || ''}
                      {/* <b className="caret" /> */}
                    </span>
                  </a>
                  {/* <Collapse isOpen={this.state.openAvatar}> */}
                  {/*  <ul className="nav"> */}
                  {/*    <li> */}
                  {/*      <a href="#pablo" onClick={e => e.preventDefault}> */}
                  {/*        <span className="sidebar-mini-icon">MP</span> */}
                  {/*        <span className="sidebar-normal">My Profile</span> */}
                  {/*      </a> */}
                  {/*    </li> */}
                  {/*    <li> */}
                  {/*      <a href="#pablo" onClick={e => e.preventDefault}> */}
                  {/*        <span className="sidebar-mini-icon">EP</span> */}
                  {/*        <span className="sidebar-normal">Edit Profile</span> */}
                  {/*      </a> */}
                  {/*    </li> */}
                  {/*    <li> */}
                  {/*      <a href="#pablo" onClick={e => e.preventDefault}> */}
                  {/*        <span className="sidebar-mini-icon">S</span> */}
                  {/*        <span className="sidebar-normal">Settings</span> */}
                  {/*      </a> */}
                  {/*    </li> */}
                  {/*  </ul> */}
                  {/* </Collapse> */}
                </div>
              </div>
            )}

            <Nav>{this.createLinks(this.props.routes)}</Nav>
          </div>
        </div>
      </>
    );
  }
}

Sidebar.defaultProps = {
  routes: [],
  showNotification: false,
  backgroundColor: 'blue',
  minimizeSidebar: () => {},
};

Sidebar.propTypes = {
  // links that are rendered
  routes: PropTypes.arrayOf(PropTypes.object),
  // if you want to show a notification when switching between mini sidebar and normal
  showNotification: PropTypes.bool,
  // background color for the component
  backgroundColor: PropTypes.oneOf(['blue', 'yellow', 'green', 'orange', 'red']),
  // function that is called upon pressing the button near the logo
  minimizeSidebar: PropTypes.func,
};

export default Sidebar;
