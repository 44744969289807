import React from 'react';
// used for making the prop types of this component
import PropTypes from 'prop-types';

// reactstrap components
import { Button } from 'reactstrap';

// core components
import defaultImage from 'assets/img/image_placeholder.jpg';
import defaultAvatar from 'assets/img/placeholder.jpg';
import { colors } from 'theme';
import { EXTENTIONS } from './constants';

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      defaulPreview: props.avatar ? defaultAvatar : defaultImage,
      currentImagePreviewUrl: '',
      errorText: '',
    };
    this.fileInput = React.createRef();
  }

  validateImage = file => {
    const { ext, maxSize } = this.props;

    if (file.size > maxSize * 1048576) {
      this.setState({
        errorText: 'File size is larger than allowed',
      });
      return false;
    }

    const isAllowedExtentions = ext.some(e => EXTENTIONS[e] === file.type);

    if (!isAllowedExtentions) {
      this.setState({
        errorText: 'Invalid extension',
      });
    }

    return isAllowedExtentions;
  };

  handleImageChange = e => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    if (!this.validateImage(file)) {
      return;
    }

    reader.onloadend = () => {
      this.setState({
        file,
        currentImagePreviewUrl: reader.result,
        errorText: '',
      });
      this.props.onChange(file);
    };
    reader.readAsDataURL(file);
  };

  handleSubmit = e => {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  };

  handleClick = () => {
    this.fileInput.current.click();
  };

  handleRemove = () => {
    this.setState({
      file: null,
      currentImagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
    });
    this.props.onChange(null);
    this.fileInput.current.value = null;
  };

  render() {
    const { imagePreviewUrl, avatar, ext, maxSize } = this.props;
    const { defaulPreview, currentImagePreviewUrl, file, errorText } = this.state;

    const filePreview = currentImagePreviewUrl || imagePreviewUrl  || defaulPreview;

    return (
      <div className="fileinput text-center">
        <input type="file" onChange={this.handleImageChange} ref={this.fileInput} />
        <div className={`thumbnail${avatar ? ' img-circle' : ''}`}>
          <img src={filePreview} alt="..." />
        </div>
        <div>
          {!file ? (
            <span>
              <Button className="btn-round" onClick={() => this.handleClick()}>
                {avatar ? 'Add Photo' : 'Select image'}
              </Button>
              {imagePreviewUrl && (
                <Button color="danger" className="btn-round" onClick={() => this.handleRemove()}>
                  <i className="fa fa-times" /> Remove
                </Button>
              )}
            </span>
          ) : (
            <span>
              <Button className="btn-round" onClick={() => this.handleClick()}>
                Change
              </Button>
              <Button color="danger" className="btn-round" onClick={() => this.handleRemove()}>
                <i className="fa fa-times" /> Remove
              </Button>
            </span>
          )}
        </div>
        <p style={{ fontSize: '0.714em', color: colors.gray }}>
          {ext.length > 0 && <span>Allowed file extensions: {ext.join(', ')}. </span>}
          {maxSize > 0 && <span>Max file size: {maxSize}Mb.</span>}
        </p>
        {errorText && <span style={{ fontSize: '0.8571em', color: colors.red }}>{errorText}</span>}
      </div>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  imagePreviewUrl: PropTypes.string,
  ext: PropTypes.array,
};

ImageUpload.defaultProps = {
  ext: [],
};

export default ImageUpload;
