/* eslint-disable import/prefer-default-export */
import authService from 'lib/services/authService';

import { getJSON, postJSON } from './ajax';

export function getBillingInformation() {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return getJSON('/api/pricebuilder', REQUEST_OPTIONS);
}

export function getStripeAccount() {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return postJSON('/api/stripe/account', null, REQUEST_OPTIONS);
}

export function getStripeAccountLink() {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return getJSON('/api/stripe/account/link', REQUEST_OPTIONS);
}
