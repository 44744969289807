import React from 'react';
import { Button, Modal, ModalBody, Col, Row, Input, ModalFooter, Alert } from 'reactstrap';
import ReactTable from 'react-table';
import SweetAlert from 'react-bootstrap-sweetalert';
import NumberFormat from 'react-number-format';
import { getPriceBookAdditionalItem, createPriceBookAdditionalItem, editPriceBookAdditionalItem, deletePriceBookAdditionalItem } from 'lib/api/proposalApi';
import colors from '../../theme/Color';
import { checkZeroFormat, toUSFormat } from './helpers';
import { NUM_DEC_REG_EX } from '../../utils/regularExpressions';

class Step2 extends React.Component {
  styles = {
    modalInputs: {
      borderWidth: 1,
      borderRadius: 15,
      borderColor: colors.blue,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      popupErrorMessage: '',
      tableErrorMessage: '',
      data: [],
      actionKeyId: null,
      showDeleteModal: false,
      showAddEditModal: false,
      isAddFlow: true,
      isInvalidForm: false,
    };

    this.fetchAdditionalItems();
  }

  fetchAdditionalItems = () => {
    return getPriceBookAdditionalItem().then(({ additionalItemList }) => {
      this.setState({
        isLoading: false,
        data: additionalItemList.map(item => {
          return {
            id: item.id,
            item: item.name,
            description: item.description,
            price: toUSFormat(item.basePrice),
            actions: this.renderActions(item.id),
          };
        }),
      });
    });
  };

  renderDeleteModal = () =>
    this.renderModal(
      () => this.delete(),
      () => this.setState({ showDeleteModal: false }),
      'Yes, delete it'
    );

  renderModal = (onConfirm, onCancel, confirmBtnText) => (
    <SweetAlert
      warning
      style={{ display: 'block', marginTop: '-15%', fontSize: '0.8571em' }}
      title="Are you sure?"
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText={confirmBtnText}
      cancelBtnText="Cancel"
      showCancel
    />
  );

  delete = () => {
    const { actionKeyId, isLoading } = this.state;
    if (isLoading) {
      return;
    }

    this.setState({ isLoading: true, tableErrorMessage: '' });

    deletePriceBookAdditionalItem({ additionalItemId: actionKeyId })
      .then(() => {
        this.fetchAdditionalItems();
        this.setState({ showDeleteModal: false });
      })
      .catch(({ message }) => {
        this.setState({ showDeleteModal: false, isLoading: false, tableErrorMessage: message });
      });
  };

  addData = (itemName, description, price, toggleModal) => {
    const isInvalidForm = !itemName || !description || !price;
    this.setState({ isInvalidForm });
    if (isInvalidForm) {
      return;
    }
    const newItem = {
      name: itemName,
      description,
      basePrice: parseFloat(price.replace(NUM_DEC_REG_EX, '')),
    };

    this.setState({ isLoading: true, popupErrorMessage: '' });

    createPriceBookAdditionalItem(newItem)
      .then(() => {
        this.fetchAdditionalItems();
        toggleModal();
      })
      .catch(({ message }) => {
        this.setState({ isLoading: false, popupErrorMessage: message });
      });
  };

  editData = (itemName, description, price, toggleModal, edit) => {
    const isInvalidForm = !itemName || !description || !price;

    this.setState({ isInvalidForm });

    if (isInvalidForm) {
      return;
    }
    const newItem = {
      additionalItemId: edit.id,
      name: itemName,
      description,
      basePrice: parseFloat(price.replace(NUM_DEC_REG_EX, '')),
    };

    this.setState({ isLoading: true, popupErrorMessage: '' });

    editPriceBookAdditionalItem(newItem)
      .then(() => {
        this.fetchAdditionalItems();
        toggleModal();
      })
      .catch(({ message }) => {
        this.setState({ isLoading: false, popupErrorMessage: message });
      });
  };

  toggleAddEditModal = () => {
    this.setState(prevState => ({ showAddEditModal: !prevState.showAddEditModal, isAddFlow: true, isInvalidForm: false, tempItemEdit: {} }));
  };

  renderAddEditModal = (active, toggleModal, edit = {}) => {
    const { isAddFlow, isInvalidForm, isLoading, popupErrorMessage } = this.state;

    const handleSubmit = event => {
      event.preventDefault();
      const { item, description, price } = event.target;

      if (isAddFlow) {
        this.addData(item.value, description.value, price.value, toggleModal);
      } else {
        this.editData(item.value, description.value, price.value, toggleModal, edit);
      }
    };

    const onChange = ({ target }) => {
      const { name, value } = target;
      this.setState(prevState => ({ tempItemEdit: { ...prevState.tempItemEdit, [name]: value } }));
    };

    const onBlur = ({ target }) => {
      const { name, value } = target;
      this.setState(prevState => ({ tempItemEdit: { ...prevState.tempItemEdit, [name]: checkZeroFormat(value) } }));
    };

    const onNumberChange = ({ target }) => {
      const { name, value } = target;
      this.setState(prevState => ({ tempItemEdit: { ...prevState.tempItemEdit, [name]: value.replace(NUM_DEC_REG_EX, '') } }));
    };

    const { modalInputs } = this.styles;

    return (
      <Modal isOpen={active} toggle={toggleModal} size="lg">
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <Row>
              <Col style={{ flex: 1 }}>
                Item
                <Input
                  name="item"
                  className="form-control-sm"
                  value={edit.item}
                  onChange={onChange}
                  style={isInvalidForm && !edit.item ? { ...modalInputs, borderColor: colors.red } : { ...modalInputs }}
                  type="text"
                  placeholder="Item name"
                />
              </Col>
              <Col style={{ flex: 1 }}>
                Description
                <Input
                  name="description"
                  value={edit.description}
                  onChange={onChange}
                  style={isInvalidForm && !edit.description ? { ...modalInputs, borderColor: colors.red } : { ...modalInputs }}
                  className="form-control-sm"
                  type="textarea"
                  placeholder="Item description..."
                />
              </Col>
              <Col style={{ flex: 1 }}>
                Price to consumer
                <NumberFormat
                  className="form-control-sm form-control"
                  thousandSeparator
                  prefix="$"
                  decimalSeparator="."
                  decimalScale={2}
                  name="price"
                  value={edit.price}
                  onChange={onNumberChange}
                  onBlur={onBlur}
                  style={isInvalidForm && !edit.price ? { ...modalInputs, borderColor: colors.red } : { ...modalInputs }}
                  placeholder="$54.3"
                  min={0}
                />
              </Col>
            </Row>
            {popupErrorMessage && <Alert color="danger">{popupErrorMessage}</Alert>}
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              className="btn-round"
              onClick={() => {
                edit && this.setState({ tempItemEdit: {} });
                toggleModal();
              }}
            >
              Close
            </Button>
            <Button disabled={isLoading} type="submit" className="btn-round" color="info">
              Save
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  };

  renderActions = key => (
    // we've added some custom button actions
    <div className="actions-right">
      {/* use this button to add a edit kind of action */}
      <Button
        onClick={() => {
          const { data } = this.state;
          const obj = data.find(o => o.id === key);
          this.setState({ showAddEditModal: true, tempItemEdit: obj, isAddFlow: false });
        }}
        className="btn-icon btn-round"
        color="warning"
        size="sm"
      >
        <i className="fa fa-edit" />
      </Button>
      {/* use this button to remove the data row */}
      <Button onClick={() => this.setState({ showDeleteModal: true, actionKeyId: key })} className="btn-icon btn-round" color="danger" size="sm">
        <i className="fa fa-times" />
      </Button>
    </div>
  );

  getPageSize = () => {
    const { data } = this.state;
    if (!data.length) {
      return 5;
    }
    return data.length <= 5 ? data.length : 5;
  };

  render() {
    const { data, showDeleteModal, showAddEditModal, tempItemEdit, tableErrorMessage, isLoading } = this.state;
    return (
      <div>
        {showDeleteModal && this.renderDeleteModal()}
        {showAddEditModal && this.renderAddEditModal(showAddEditModal, this.toggleAddEditModal, tempItemEdit)}
        <ReactTable
          data={data}
          filterable
          loading={isLoading}
          defaultFilterMethod={(filter, row) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined
              ? row[id]
                  .toString()
                  .toLowerCase()
                  .includes(filter.value)
              : true;
          }}
          columns={[
            {
              Header: 'ITEM',
              accessor: 'item',
            },
            {
              Header: 'DESCRIPTION',
              accessor: 'description',
            },
            {
              Header: 'PRICE TO CONSUMER',
              accessor: 'price',
            },
            {
              Header: 'ACTIONS',
              accessor: 'actions',
              sortable: false,
              filterable: false,
            },
          ]}
          pageSize={this.getPageSize()}
          showPaginationBottom={data.length > 5}
          showPaginationTop={false}
          className="-striped -highlight"
        />
        <Button onClick={this.toggleAddEditModal} className="btn-round" color="info">
          Add New
        </Button>
        {tableErrorMessage && <Alert color="danger">{tableErrorMessage}</Alert>}
      </div>
    );
  }
}

export default Step2;
