import React, { Component } from 'react';
// react component for creating dynamic tables
import ReactTable from 'react-table';

// reactstrap components
import { Card, CardBody, Row, Col } from 'reactstrap';

// core components
import PanelHeader from 'components/PanelHeader/PanelHeader';
import defaultFilter from '../../utils/tableFilter';

const proposalsPlaceholder = [
  { customerName: 'Fabricio', customerAddress: 'Chamizo City', isSold: true, proposedValue: 156, saleValue: 202 },
  { customerName: 'Jonathon', customerAddress: 'Unknown City', isSold: false, proposedValue: 156, saleValue: 202 },
  { customerName: 'Darren', customerAddress: 'Punta del este', isSold: false, proposedValue: 156, saleValue: 202 },
  { customerName: 'Gonzalo', customerAddress: 'Mvdeo', isSold: true, proposedValue: 156, saleValue: 400 },
  { customerName: 'Fabricio', customerAddress: 'Chamizo City', isSold: true, proposedValue: 156, saleValue: 202 },
  { customerName: 'Fabricio', customerAddress: 'Chamizo City', isSold: true, proposedValue: 156, saleValue: 202 },
  { customerName: 'Fabricio', customerAddress: 'Chamizo City', isSold: false, proposedValue: 156, saleValue: 202 },
  { customerName: 'Libny', customerAddress: 'Mvdeo', isSold: false, proposedValue: 156, saleValue: 202 },
  { customerName: 'Fabricio', customerAddress: 'Chamizo City', isSold: true, proposedValue: 156, saleValue: 202 },
  { customerName: 'Fabricio', customerAddress: 'Chamizo City', isSold: true, proposedValue: 156, saleValue: 202 },
];

class ViewProposals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: proposalsPlaceholder.map((item, key) => {
        return {
          id: key,
          customerAddress: item.customerAddress,
          customerName: item.customerName,
          isSold: item.isSold,
          proposedValue: item.proposedValue,
          saleValue: item.saleValue,
        };
      }),
    };
  }

  render() {
    const { data } = this.state;
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardBody>
                  <ReactTable
                    data={data}
                    defaultFilterMethod={defaultFilter}
                    filterable
                    columns={[
                      {
                        Header: 'Customer',
                        accessor: 'customerName',
                      },
                      {
                        Header: 'Customer Address',
                        accessor: 'customerAddress',
                      },
                      {
                        Header: 'Proposed Value',
                        accessor: 'proposedValue',
                        Cell: row => `U$S ${row.original.proposedValue}`,
                        filterable: false,
                      },
                      {
                        Header: 'Sold',
                        accessor: 'isSold',
                        Cell: row => (row.original.isSold ? 'Yes' : 'No'),
                        filterable: false,
                      },
                      {
                        Header: 'Sale Value',
                        accessor: 'saleValue',
                        Cell: row => `U$S ${row.original.saleValue}`,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default ViewProposals;
