import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from 'reactstrap';
import image from '../../../assets/img';

class EmailEnterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailState: '',
      errorState: '',
      errorsState: {},
      isInProgress: false,
    };
    this.inputRef = null;
  }

  checkAllFields = () => {
    const { email } = this.state;

    const errors = {};

    errors.emailState = !email ? 'has-danger' : '';

    const isValid = email;

    this.setState({
      errorState: !isValid ? 'Email field is required' : '',
      ...errors,
    });

    return isValid;
  };

  onSubmit = event => {
    event.preventDefault();
    const { email } = this.state;
    const { getUsersByEmail, nextStep } = this.props;

    if (!this.checkAllFields()) {
      return;
    }
    this.setState({ isInProgress: true, errorsState: {}, errorState: '' }, () => {
      getUsersByEmail(email)
        .then(step => this.setState({ isInProgress: false }, () => nextStep(step)))
        .catch(error => {
          if (error.errors) {
            this.setState({ errorsState: error.errors, isInProgress: false });
          } else {
            this.setState({ errorState: error?.message, isInProgress: false });
          }
        });
    });
  };

  handleOnChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  renderErrors = () => {
    const { errorsState } = this.state;
    const errors = Object.keys(errorsState);
    return errors.map(error => <Label style={{ color: 'red' }}>{errorsState[error]}</Label>);
  };

  render() {
    const { errorState, isInProgress, emailFocus, emailState, errorsState } = this.state;

    const { goToLoginPage } = this.props;

    return (
      <Form onSubmit={this.onSubmit}>
        <Card className="card-login card-plain">
          <CardHeader>
            <div className="logo-container">
              <img src={image.logo} alt="now-logo" />
            </div>
            <div className="login-header">Enter your email</div>
          </CardHeader>
          <CardBody>
            <InputGroup className={`no-border form-control-lg ${emailState} ${emailFocus ? 'input-group-focus' : ''}`}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="now-ui-icons business_briefcase-24" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder="email address"
                onFocus={e => this.setState({ emailFocus: true })}
                onBlur={e => this.setState({ emailFocus: false })}
                onChange={this.handleOnChange}
                name="email"
              />
            </InputGroup>
            {errorState && <Label style={{ color: 'red' }}>{errorState}</Label>}
            {Object.keys(errorsState)?.length > 0 && this.renderErrors()}
          </CardBody>
          <CardFooter>
            <Button
              onClick={this.onSubmit}
              disabled={isInProgress}
              block
              color="info"
              size="lg"
              className="mb-3 btn-round"
            >
              Submit
            </Button>
            <div className="back-button mt-3" onClick={goToLoginPage}>
              Back to Login page
            </div>
          </CardFooter>
        </Card>
      </Form>
    );
  }
}

export default EmailEnterForm;
