import React, { Component } from 'react';
import { observer } from 'mobx-react';
import ReactTable from 'react-table';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import PanelHeader from 'components/PanelHeader/PanelHeader';
import { getSalespersonList, editSalesperson, deleteSalesperson } from 'lib/api/salespersonApi';
import authService from 'lib/services/authService';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { getIsSalesInMetadata } from 'lib/api/companyApi';
import defaultFilter from '../../utils/tableFilter';
import ResetPasswordModal from './ResetPasswordModal';
import { getCashTerms, getPriceBuilder } from '../../lib/api/proposalApi';
import colors from '../../theme/Color';
import config from '../../config';
import store from '../../store';
import { getSubscriptionMeneagmentData, getSubscriptionPreviewInvoice } from '../../lib/api/billingApi';

const stripePromise = loadStripe(config.stripeKey);

class WorkforceComponents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      data: [],
      showDeactivateModal: false,
      showSuccessModal: false,
      showErrorStripeModal: false,
      showDeleteModal: false,
      showConfirmResetPasswordModal: false,
      actionKeyId: null,
      isRejectActiveSalesmen: null,
      billingErrorModalClosed: false,
      addSalesBillingError: false,
      subscriptionPreviewInvoice: {
        loading: false,
        upcomingInvoice: null,
        remainingDays: null,
        callback: null,
      },
      previewInvoiceModal: false,
      showTrainingNotCompleteModal: false,
      activateBillingError: false,
      showBillingWorning: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      getSubscriptionMeneagmentData()
        .then(response =>
          this.setState({
            showBillingWorning: !response.subscriptionEmbedded,
          })
        )
        .catch(() => {});
      this.fetchSalespersonList();
    });

    this.isActivatedStatusSales();

    const { fetchBillingInfo, data: company, fetchCompany } = store.company;
    // if (!billingInfo?.paymentMethod) {
    fetchBillingInfo();
    // }

    if (!company?.id?.length) {
      fetchCompany();
    }
  }

  isActivatedStatusSales = async () => {
    const { priceBuilder } = await getPriceBuilder();
    let isReject = false;
    if (!priceBuilder) {
      isReject = true;
    }
    const { cashTerms } = await getCashTerms();
    if (!cashTerms) {
      isReject = true;
    }
    this.setState({ isRejectActiveSalesmen: isReject });
  };

  fetchSalespersonList = () => {
    const { companyId } = authService.getUser();
    return getSalespersonList(companyId)
      .then(({ salesmanList }) => {
        this.setState({
          isLoading: false,
          data: salesmanList.map(({ avatarUrl, name, phone, userName, id, color, isActive, email, useCustomSmtp }) => {
            return {
              avatarUrl,
              name,
              phone,
              username: userName,
              email,
              id,
              color,
              isActive,
              useCustomSmtp,
              actions: this.renderActions(id),
            };
          }),
        });
      })
      .catch(() => this.setState({ isLoading: false }));
  };

  renderPreviewInvoiceModal = () => (
    <SweetAlert
      warning
      cancelBtnStyle={{ fontSize: '1em', backgroundColor: `${colors.blue}!important` }}
      style={{ display: 'block', marginTop: '-15%', fontSize: '0.8571em' }}
      title={
        this.state.subscriptionPreviewInvoice.loading
          ? 'Loading...'
          : `You've activated a New User. There’s ${this.state.subscriptionPreviewInvoice.remainingDays} days of billing left in this month's billing cycle. Do you agree to have your card charged for ${this.state.subscriptionPreviewInvoice.upcomingInvoice}?`
      }
      onConfirm={() => this.setState({ previewInvoiceModal: false })}
      closeOnClickOutside={false}
      confirmBtnBsStyle="danger"
      cancelBtnBsStyle="info"
      confirmBtnText="No"
      onCancel={() => {
        this.setState({ previewInvoiceModal: false }, () => {
          this.state.subscriptionPreviewInvoice.callback && this.state.subscriptionPreviewInvoice.callback();
        });
      }}
      cancelBtnText="Yes"
      confirmBtnStyle={{ fontSize: '1em', backgroundColor: colors.red, color: 'white' }}
      showCancel
    />
  );

  preparePreviewInvoiceModal = callback => {
    this.setState({ subscriptionPreviewInvoice: { loading: true, callback }, previewInvoiceModal: true }, async () => {
      try {
        const result = await getSubscriptionPreviewInvoice();
        this.setState({
          subscriptionPreviewInvoice: { ...this.state.subscriptionPreviewInvoice, loading: false, ...result },
        });
      } catch (e) {
        this.setState({ subscriptionPreviewInvoice: { loading: false }, previewInvoiceModal: false });
      }
    });
  };

  renderModal = (onConfirm, onCancel, confirmBtnText, cancelBtnText, info, showCancel = true) => {
    if (info)
      return (
        <SweetAlert
          warning
          confirmBtnStyle={{ fontSize: '1em' }}
          style={{ display: 'block', marginTop: '-15%', fontSize: '0.8571em' }}
          title={info}
          onConfirm={onConfirm}
          confirmBtnBsStyle="info"
          confirmBtnText={confirmBtnText}
          onCancel={onCancel}
          cancelBtnText={cancelBtnText}
          cancelBtnStyle={{ fontSize: '1em', backgroundColor: colors.red, color: 'white' }}
          showCancel={showCancel}
        />
      );

    return (
      <SweetAlert
        warning
        style={{ display: 'block', marginTop: '-15%', fontSize: '0.8571em' }}
        title="Are you sure?"
        onConfirm={onConfirm}
        onCancel={onCancel}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText={confirmBtnText}
        cancelBtnText="Cancel"
        showCancel
      />
    );
  };

  renderDeleteModal = () =>
    this.renderModal(this.delete, () => this.setState({ showDeleteModal: false }), 'Yes, delete it');

  renderSuccessModal = () =>
    this.renderModal(
      this.confirmSuccess,
      () => this.setState({ showSuccessModal: false }),
      'Ok',
      '',
      'Payment has been successfully processed',
      false
    );

  renderErrorStripeModal = () =>
    this.renderModal(
      () => this.setState({ showErrorStripeModal: false }),
      () => null,
      'Ok',
      '',
      'Something went wrong with your payment. Please check your billing information',
      false
    );

  renderDeactivateModal = () =>
    this.renderModal(this.deactivate, () => this.setState({ showDeactivateModal: false }), 'Yes, deactivate it');

  renderActivateModal = () => (
    <SweetAlert
      warning
      style={{ display: 'block', marginTop: '-15%', fontSize: '0.8571em' }}
      title={
        this.state.activateBillingError
          ? 'To activate a salesperson please insert Pricebook info on the "Pricebook Page"'
          : 'To add a new salesperson please insert Pricebook info on the "Pricebook Page"'
      }
      onConfirm={() => this.setState({ showRejectModal: false, activateBillingError: false })}
      closeOnClickOutside={false}
      onCancel={() => this.props.history.push(`/admin/pricebook`)}
      confirmBtnBsStyle="danger"
      cancelBtnBsStyle="info"
      confirmBtnText="Not now"
      cancelBtnText="Ok"
      showCancel
    />
  );

  renderAddSalesErrorModal = () => (
    <SweetAlert
      warning
      style={{ display: 'block', marginTop: '-15%', fontSize: '0.8571em' }}
      title={
        this.state.activateBillingError
          ? 'To activate a salesperson please insert Billing info on the "Billing Page"'
          : 'To add a new salesperson please insert Billing info on the "Billing Page"'
      }
      onConfirm={() => this.setState({ addSalesBillingError: false, activateBillingError: false })}
      closeOnClickOutside={false}
      onCancel={() => this.props.history.push(`/admin/billing-Info`)}
      confirmBtnBsStyle="danger"
      cancelBtnBsStyle="info"
      confirmBtnText="Not now"
      cancelBtnText="Ok"
      showCancel
    />
  );

  renderResetPasswordModal = () =>
    this.renderModal(
      this.resetPassword,
      () => this.setState({ showConfirmResetPasswordModal: false }),
      'Yes, reset password'
    );

  deactivate = (key = null) => {
    const { data, actionKeyId, isLoading } = this.state;
    if (isLoading) {
      return;
    }
    const item = data.find(o => o.id === (key || actionKeyId));

    const newItem = {
      salesmanId: item.id,
      name: item.name,
      userName: item.username,
      email: item.email,
      phone: item.phone,
      isActive: !item.isActive,
      color: item.color,
      avatarUrl: item.avatarUrl,
    };

    this.setState({ isLoading: true });

    editSalesperson({ ...newItem })
      .then(res => {
        if (!res.paymentIntent) {
          this.setState({ showDeactivateModal: false });
          this.fetchSalespersonList();
          return;
        }
        if (
          res?.paymentIntent?.status === 'requires_action' ||
          res?.paymentIntent?.status === 'requires_payment_method'
        ) {
          this.props.stripe
            .confirmCardPayment(res?.paymentIntent?.clientSecret, {
              payment_method: res?.paymentIntent?.paymentMethod,
            })
            .then(result => {
              console.log('stripe activate result', result);
              this.setState({ isLoading: false });
              if (result.error) {
                // Start code flow to handle updating the payment details.
                // Display error message in your UI.
                // The card was declined (i.e. insufficient funds, card has expired, etc).
                throw result;
              } else if (result.paymentIntent.status === 'succeeded') {
                this.setState({ showSuccessModal: true });
              }
            })
            .catch(error => {
              this.setState({ showErrorStripeModal: true, isLoading: false });
              console.log('stripe activate error', error);
            });
        } else if (res?.paymentIntent?.status === 'succeeded') {
          this.setState({ showSuccessModal: true, isLoading: false });
        }
        this.setState({ isLoading: true }, () => {
          this.fetchSalespersonList();
        });
      })
      .catch(() => {
        this.setState({ showDeactivateModal: false, isLoading: true }, this.fetchSalespersonList);
      });
  };

  delete = () => {
    const { actionKeyId, isLoading } = this.state;
    if (isLoading) {
      return;
    }

    this.setState({ isLoading: true });

    deleteSalesperson({ salesmanId: actionKeyId })
      .then(() => {
        this.fetchSalespersonList();
        this.setState({ showDeleteModal: false, isLoading: false });
      })
      .catch(() => {
        this.setState({ showDeleteModal: false, isLoading: false });
      });
  };

  confirmSuccess = () => {
    this.fetchSalespersonList();
    this.setState({ showSuccessModal: false });
  };

  resetPassword = () => {
    this.setState({ showConfirmResetPasswordModal: false, showResetPasswordModal: true });
  };

  renderSTMPStatus = (isActive, key) => (
    <i key={key} className={`fa ${isActive ? 'fa-check' : 'fa-times'}`} style={{ color: colors.gray }} />
  );

  renderStatus = (isActive, key) => (
    // we've added some custom button actions
    // use this button to add a like kind of action
    <Button
      disabled={this.state.isLoading}
      onClick={
        isActive
          ? () => this.setState({ showDeactivateModal: true, actionKeyId: key })
          : () => {
              const { data: company } = store.company;
              if (!company.isTrainingComplete) {
                this.setState({ showTrainingNotCompleteModal: true });
                return;
              }

              const { billingInfo, billingLoading } = store.company;

              if (billingLoading) {
                return;
              }
              if (this.state.billingError?.length) {
                this.setState({ billingErrorModalClosed: false });
                return;
              }
              // if (!billingInfo.paymentMethod) {
              //   this.setState({ addSalesBillingError: true, activateBillingError: true });
              //   return;
              // }
              if (this.state.isRejectActiveSalesmen === null) {
                return;
              }
              if (this.state.isRejectActiveSalesmen) {
                this.setState({ showRejectModal: true, activateBillingError: true });
                return;
              }

              getIsSalesInMetadata(key)
                .then(({ isSalesmanInMetadata }) => {
                  if (!isSalesmanInMetadata) {
                    this.preparePreviewInvoiceModal(() => this.deactivate(key));
                    return;
                  }
                  this.deactivate(key);
                })
                .catch(() => {
                  this.preparePreviewInvoiceModal(() => this.deactivate(key));
                });
            }
      }
      className="btn-icon btn-round"
      color={isActive ? 'success' : 'default'}
      size="sm"
    />
  );

  renderActions = key => (
    // we've added some custom button actions
    <div className="actions-right">
      {/* use this button to add a edit kind of action */}
      <Button
        onClick={() => {
          this.props.history.push(`/admin/editsaleperson/${key}`);
        }}
        className="btn-icon btn-round"
        color="warning"
        size="sm"
      >
        <i className="fa fa-edit" />
      </Button>
      {/* use this button to reset user password */}
      <Button
        onClick={() => this.setState({ showConfirmResetPasswordModal: true, actionKeyId: key })}
        className="btn-icon btn-round"
        color="info"
        size="sm"
      >
        <i className="now-ui-icons ui-1_lock-circle-open" />
      </Button>
      {/* use this button to remove the data row */}
      <Button
        onClick={() => this.setState({ showDeleteModal: true, actionKeyId: key })}
        className="btn-icon btn-round"
        color="danger"
        size="sm"
      >
        <i className="fa fa-times" />
      </Button>
    </div>
  );

  renderTrainingNotCompleteModal = () => (
    <SweetAlert
      warning
      cancelBtnStyle={{ fontSize: '1em' }}
      confirmBtnStyle={{ fontSize: '1em' }}
      style={{ display: 'block', marginTop: '-15%', fontSize: '0.8571em' }}
      title="In order to activate a Salesperson account you must go through an onboarding training. Do you want to sign-up for a training?"
      onCancel={() => {
        window.open('https://calendly.com/marblplatform');
        this.setState({ showTrainingNotCompleteModal: false });
      }}
      onConfirm={() => this.setState({ showTrainingNotCompleteModal: false })}
      closeOnClickOutside={false}
      confirmBtnBsStyle="danger"
      confirmBtnText="No"
      cancelBtnBsStyle="info"
      cancelBtnText="Yes"
      showCancel
    />
  );

  render() {
    const {
      showDeactivateModal,
      showDeleteModal,
      showConfirmResetPasswordModal,
      showResetPasswordModal,
      actionKeyId,
      isRejectActiveSalesmen,
      showRejectModal,
      showSuccessModal,
      isLoading,
      billingError,
      billingErrorModalClosed,
      addSalesBillingError,
      previewInvoiceModal,
      showErrorStripeModal,
      showTrainingNotCompleteModal,
      showBillingWorning,
    } = this.state;

    if (!billingErrorModalClosed && !billingError?.length && store.company.billingError?.message?.length) {
      this.setState({ billingError: store.company.billingError.message });
    }

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          {showDeactivateModal && this.renderDeactivateModal()}
          {showDeleteModal && this.renderDeleteModal()}
          {showRejectModal && this.renderActivateModal()}
          {addSalesBillingError && this.renderAddSalesErrorModal()}
          {showTrainingNotCompleteModal && this.renderTrainingNotCompleteModal()}
          {previewInvoiceModal && this.renderPreviewInvoiceModal()}
          {(store.company.billingError?.message?.length || billingError?.length) &&
            !billingErrorModalClosed &&
            this.renderModal(
              () => window.location.reload(false),
              () => {
                this.setState({ billingErrorModalClosed: true });
                store.company.resetBillingError();
              },
              'Try again',
              'Close',
              'An error occurred while loading billing information.\n\rPlease, try again later'
            )}
          {showSuccessModal && this.renderSuccessModal()}
          {showErrorStripeModal && this.renderErrorStripeModal()}
          {showConfirmResetPasswordModal && this.renderResetPasswordModal()}
          {showResetPasswordModal && (
            <ResetPasswordModal
              salesmanId={actionKeyId}
              toggleModal={() => {
                this.setState(prevState => ({
                  showResetPasswordModal: !prevState.showResetPasswordModal,
                }));
              }}
            />
          )}
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  {showBillingWorning && (
                    <div
                      style={{
                        position: 'absolute',
                        right: 8,
                        top: 4,
                        marginLeft: 300,
                        minWidth: 300,
                        lineHeight: 1.1,
                      }}
                    >
                      <p style={{ color: colors.red, fontWeight: 'bold', fontSize: 16 }}>
                        Important: You must first subscribe and enter payment information in "Plan and Billing" before
                        activating a salesperson
                      </p>
                    </div>
                  )}
                  <Button
                    color="info"
                    className="btn-round"
                    onClick={() => {
                      const { history } = this.props;
                      const { billingInfo, billingLoading } = store.company;

                      if (billingLoading) {
                        return;
                      }
                      if (billingError?.length) {
                        this.setState({ billingErrorModalClosed: false });
                        return;
                      }
                      // if (!billingInfo.paymentMethod) {
                      //   this.setState({ addSalesBillingError: true });
                      //   return;
                      // }
                      if (isRejectActiveSalesmen === null) {
                        return;
                      }
                      if (isRejectActiveSalesmen) {
                        this.setState({ showRejectModal: true });
                        return;
                      }
                      history.push('/admin/addsaleperson');
                    }}
                  >
                    <i className="now-ui-icons ui-1_simple-add" /> Add salesperson
                  </Button>
                </div>
                <CardBody>
                  <ReactTable
                    data={this.state.data}
                    filterable
                    loading={isLoading}
                    defaultFilterMethod={defaultFilter}
                    columns={[
                      {
                        Header: 'PROFILE',
                        accessor: 'image',
                        Cell: row => {
                          return (
                            <div>
                              <img
                                alt=""
                                height={34}
                                src={row.original.avatarUrl ? `${config.imagesUrl}${row.original.avatarUrl}` : null}
                              />
                            </div>
                          );
                        },
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: 'NAME',
                        accessor: 'name',
                      },
                      {
                        Header: 'PHONE',
                        accessor: 'phone',
                      },
                      {
                        Header: 'USERNAME',
                        accessor: 'username',
                      },
                      {
                        Header: 'STATUS',
                        accessor: 'isActive',
                        Cell: row => this.renderStatus(row.original.isActive, row.original.id),
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: 'USING A CUSTOM DOMAIN',
                        accessor: 'useCustomSmtp',
                        Cell: row => this.renderSTMPStatus(row.original.useCustomSmtp, row.original.id),
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: 'Actions',
                        accessor: 'actions',
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const Workforce = observer(WorkforceComponents);
const StripeForm = props => {
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ stripe, elements }) => <Workforce stripe={stripe} elements={elements} {...props} />}
      </ElementsConsumer>
    </Elements>
  );
};

export default StripeForm;
