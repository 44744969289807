import authService from 'lib/services/authService';

import { deleteJSON, getJSON, downloadFile, postJSON, putJSON, submitMultipartForm } from './ajax';

export function createAnnouncement(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return postJSON('/api/announcement/create', data, REQUEST_OPTIONS);
}

export function getAnnouncementList(pastDays) {
  const DATA = {
    pastDays: pastDays || 30,
  };
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return postJSON('/api/announcement/list', DATA, REQUEST_OPTIONS);
}

export function deleteAnnouncement(id) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return postJSON('/api/announcement/delete', { id }, REQUEST_OPTIONS);
}

export function updateAnnouncement(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return postJSON('/api/announcement/update', data, REQUEST_OPTIONS);
}
