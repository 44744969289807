import authService from '../authService';
import { postJSON, getJSON } from '../../api/ajax';
import {
  Status,
  generateSearchParams,
  generateLocationParams,
  generateStatusCompanyParams,
  generateOnBoardingStatusParams,
  generateSignUpDateParams,
  generateUserNumberParams,
} from './params';

export * from './params';

export class CompanyAdminService {
  static async getCompaniesByStatus(status = Status.ACTIVE, skipCount = 0, takeCount = 10, error) {
    let result = {
      companyList: [],
      totalRecordCount: 0,
    };

    const REQUEST_OPTIONS = {
      headers: { Authorization: `Bearer ${authService.getUser().token}` },
    };
    const DATA = {
      Filter: {
        Where: {
          OperatorType: 1,
          Operands: [
            {
              OperatorType: 0,
              Operands: null,
              Field: 'IsActiveOnUserCount',
              FilterType: 1,
              Value: status,
            },
          ],
        },
        OrderBy: [
          {
            Field: 'CreatedAt',
            Order: 1,
          },
        ],
        Skip: skipCount,
        Take: takeCount,
      },
    };

    try {
      result = await postJSON('/api/management/company/list', DATA, REQUEST_OPTIONS);
    } catch (e) {
      // eslint-disable-next-line no-param-reassign
      error = e;
    }

    return result;
  }

  static async getCompaniesBySearchQuery({ status, value } = {}, skipCount, takeCount) {
    let result = {
      companyList: [],
      totalRecordCount: 0,
    };

    const REQUEST_OPTIONS = {
      headers: { Authorization: `Bearer ${authService.getUser().token}` },
    };

    const statusCompanyParams = generateStatusCompanyParams(status);
    const searchParams = generateSearchParams(value);

    try {
      result = await postJSON(
        '/api/management/company/list',
        {
          Filter: {
            Where: {
              OperatorType: 1,
              Operands: [statusCompanyParams, searchParams],
            },
            Skip: skipCount,
            Take: takeCount,
          },
        },
        REQUEST_OPTIONS
      );
    } catch (error) {
      throw error;
    }

    return result;
  }

  static async getCompaniesByLocation({ status, city, state, zip, searchQuery = '' }, skipCount = 0, takeCount = 10) {
    let result = {
      companyList: [],
      totalRecordCount: 0,
    };

    const REQUEST_OPTIONS = {
      headers: { Authorization: `Bearer ${authService.getUser().token}` },
    };

    const statusCompanyParams = generateStatusCompanyParams(status);
    const locationParams = generateLocationParams({ city, state, zip });
    const searchParams = generateSearchParams(searchQuery);

    try {
      result = await postJSON(
        '/api/management/company/list',
        {
          Filter: {
            Where: {
              OperatorType: 1,
              Operands: [statusCompanyParams, locationParams, searchParams],
            },
            Skip: skipCount,
            Take: takeCount,
          },
        },
        REQUEST_OPTIONS
      );
    } catch (error) {
      throw error;
    }

    return result;
  }

  static async getCompaniesByOnBoardingStatus(
    { companyStatus, onBoardingStatus, searchQuery = '' } = {},
    skipCount,
    takeCount
  ) {
    let result = {
      companyList: [],
      totalRecordCount: 0,
    };

    const REQUEST_OPTIONS = {
      headers: { Authorization: `Bearer ${authService.getUser().token}` },
    };

    const companyStatusParams = generateStatusCompanyParams(companyStatus);
    const onBoardingStatusParams = generateOnBoardingStatusParams(onBoardingStatus);
    const searchParams = generateSearchParams(searchQuery);

    try {
      result = await postJSON(
        '/api/management/company/list',
        {
          Filter: {
            Where: {
              OperatorType: 1,
              Operands: [companyStatusParams, onBoardingStatusParams, searchParams],
            },
            Skip: skipCount,
            Take: takeCount,
          },
        },
        REQUEST_OPTIONS
      );
    } catch (error) {
      throw error;
    }

    return result;
  }

  static async getCompaniesBySignUpDate(
    { status, sortType = '', startDate = new Date(), endDate = new Date(), searchQuery = '' } = {},
    skipCount,
    takeCount
  ) {
    let result = {
      companyList: [],
      totalRecordCount: 0,
    };

    const REQUEST_OPTIONS = {
      headers: { Authorization: `Bearer ${authService.getUser().token}` },
    };

    let params;
    const statusCompanyParams = generateStatusCompanyParams(status);
    const searchParams = generateSearchParams(searchQuery);
    const dateRangeParams = generateSignUpDateParams(startDate, endDate);

    if (!sortType) {
      params = {
        Where: {
          OperatorType: 1,
          Operands: [statusCompanyParams, searchParams, dateRangeParams],
        },
        Skip: skipCount,
        Take: takeCount,
      };
    } else {
      params = {
        Where: {
          OperatorType: 1,
          Operands: [statusCompanyParams, searchParams],
        },
        OrderBy: [{ Field: 'CreatedAt', Order: sortType }],
        Skip: skipCount,
        Take: takeCount,
      };
    }

    try {
      result = await postJSON(
        '/api/management/company/list',
        {
          Filter: { ...params },
        },
        REQUEST_OPTIONS
      );
    } catch (error) {
      throw error;
    }

    return result;
  }

  static async getCompaniesByNumberOfUsers(
    { status, sortType = '', userNumber = '', searchQuery = '' } = {},
    skipCount = 0,
    takeCount = 10
  ) {
    let result = {
      companyList: [],
      totalRecordCount: 0,
    };

    let params;
    const statusCompanyParams = generateStatusCompanyParams(status);
    const searchParams = generateSearchParams(searchQuery);
    const userNumberParams = generateUserNumberParams(userNumber);
    const REQUEST_OPTIONS = {
      headers: { Authorization: `Bearer ${authService.getUser().token}` },
    };

    if (!sortType) {
      params = {
        Where: {
          OperatorType: 0,
          Field: 'ActiveUsersCount',
          FilterType: 1,
          Value: userNumber,
          Operands: [statusCompanyParams, userNumberParams, searchParams],
        },
      };
    } else {
      params = {
        OrderBy: [
          {
            Field: 'ActiveUsersCount',
            Order: sortType,
          },
        ],
      };
    }

    try {
      result = await postJSON(
        '/api/management/company/list',
        {
          Filter: {
            ...params,
            Skip: skipCount,
            Take: takeCount,
          },
        },
        REQUEST_OPTIONS
      );
    } catch (error) {
      throw error;
    }

    return result;
  }

  static async requestSearchCompany(id, error) {
    let result = {};

    const REQUEST_OPTIONS = {
      headers: {
        Authorization: `Bearer ${authService.getUser().token}`,
      },
    };

    try {
      result = await getJSON(`/api/management/company?companyId=${id}`, REQUEST_OPTIONS);
    } catch (e) {
      // eslint-disable-next-line no-param-reassign
      error = e;
    }

    return result;
  }
}


/*
OperatorType:
None = 0
And
Or

Order:
Asc = 0
Desc

FilterType :
Equal = 1
NotEqual
LessThan
GreaterThan
LessThanOrEqual
GreaterThanOrEqual
Contains
NotContains
StartsWith
NotStartsWith
 */
