import React from 'react';
import { Row, Col } from 'reactstrap';
import { colors } from 'theme';
import moment from 'moment';

const announcementItem = item => (
  <div
    className="form-control"
    key={item.id}
    style={{ marginBottom: 8, paddingLeft: 18, paddingTop: 10, paddingBottom: 10, paddingRight: 10 }}
  >
    <Row>
      <Col style={{ flex: 1 }}>
        <pre style={{ whiteSpace: 'break-spaces', fontSize: '1em' }}>{item.text}</pre>
      </Col>
    </Row>
    <Row>
      <Col>
        <p style={{ margin: 0, fontSize: '0.8571em', color: colors.gray, alignSelf: 'flex-end' }}>
          {moment(item.createdAt).format('MM/DD/YYYY')}
        </p>
      </Col>
    </Row>
  </div>
);

export default announcementItem;
