import authService from '../authService';
import { postJSON } from '../../api/ajax';
import { Status } from './params';

export * from './params';

export class SalesmanService {
  static async getSalesmanssByStatus(status = Status.ACTIVE, skipCount = 0, takeCount = 10, error) {
    let result = {
      salesmanList: [],
      totalRecordCount: 0,
    };

    const REQUEST_OPTIONS = {
      headers: { Authorization: `Bearer ${authService.getUser().token}` },
    };
    const DATA = {
      Filter: {
        Where: {
          OperatorType: 1,
          Operands: [
            {
              OperatorType: 0,
              Operands: null,
              Field: 'IsActive',
              FilterType: 1,
              Value: status,
            },
          ],
        },
        Skip: skipCount,
        Take: takeCount,
      },
    };

    try {
      result = await postJSON('/api/salesman/metrics/list', DATA, REQUEST_OPTIONS);
    } catch (e) {
      // eslint-disable-next-line no-param-reassign
      error = e;
    }

    return result;
  }
}

/*
OperatorType:
None = 0
And
Or

Order:
Asc = 0
Desc

FilterType :
Equal = 1
NotEqual
LessThan
GreaterThan
LessThanOrEqual
GreaterThanOrEqual
Contains
NotContains
StartsWith
NotStartsWith
 */
