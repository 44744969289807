import React, { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { stateList as STATE_LIST } from '../../variables/general';

const StateDropdown = ({ state, onChange, toggleStyle, stateList = STATE_LIST }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = index => {
    setIsOpen(false);
    onChange(index);
  };

  return (
    <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
      <DropdownToggle style={toggleStyle} caret color="info">
        {state}
      </DropdownToggle>
      <DropdownMenu
        modifiers={{
          setMaxHeight: {
            enabled: true,
            order: 890,
            fn: data => {
              return {
                ...data,
                styles: {
                  ...data.styles,
                  overflow: 'auto',
                  maxHeight: '320px',
                },
              };
            },
          },
        }}
      >
        {stateList.map((value, index) => (
          <DropdownItem key={value} onClick={() => onClick(index)}>
            {value}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default StateDropdown;
