/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState } from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, Row, Col, Collapse } from 'reactstrap';
import ProposalCard from './ProposalCard';

const JobCard = ({ job, sold, customerId, uploadProposalFile, onDeleteProposal, sendEmail }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { name, address, proposals } = job;

  const onOpen = () => setIsOpen(!isOpen);

  const renderProposals =
    proposals
      ?.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
      .map(p => (
        <ProposalCard
          key={p.id}
          proposal={p}
          sold={sold}
          uploadProposalFile={uploadProposalFile}
          customerId={customerId}
          onDeleteProposal={onDeleteProposal}
          sendEmail={sendEmail}
        />
      )) || null;

  return (
    <div>
      <Card className="card-job">
        <CardBody>
          <Row>
            <div
              className="rt-td rt-expandable"
              role="gridcell"
              style={{ flex: '35 0 auto', width: '35px', maxWidth: '35px' }}
              onClick={onOpen}
            >
              <div className={`rt-expander ${isOpen && '-open'}`}>1•</div>
            </div>

            <Col>
              <Row>
                <Col md="7">
                  <CardTitle className="card-subtitle">{name}</CardTitle>
                </Col>
                <Col md="5">
                  <CardSubtitle className="card-subtitle right">Proposals ({proposals?.length || 0})</CardSubtitle>
                </Col>
              </Row>
              <Row>
                <Col md="7">
                  <CardSubtitle className="card-subtitle">{`${address}`}</CardSubtitle>
                </Col>
                <Col md="5">
                  <CardSubtitle className="card-subtitle right">Job Status: {job.customerJobStatus}</CardSubtitle>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Collapse isOpen={isOpen}>{renderProposals}</Collapse>
    </div>
  );
};

export default JobCard;
