import React from 'react';
import { Container, Col } from 'reactstrap';

import { LoadingContainer } from 'components/LoadingContainer';
import image from 'assets/img';
import SweetAlert from 'react-bootstrap-sweetalert';
import authService from 'lib/services/authService';
import { parseJwt } from 'utils/helpers';
import EmailEnterForm from '../Forms/ResetPassword/EmailEnterForm';
import RestoreOptionsForm, { OPTION_TYPES } from '../Forms/ResetPassword/RestoreOptionsForm';
import CredentialsForm from '../Forms/ResetPassword/CredentialsForm';
import SelectCompanyForm from '../Forms/ResetPassword/SelectCompanyForm';
import CodeEnterForm from '../Forms/ResetPassword/CodeEnterForm';
import EnterNewPasswordForm from '../Forms/ResetPassword/EnterNewPasswordForm';
import { confirmByLink, getUsersByEmail, sendLoginСredentials, sendLoginCode } from '../../lib/api/authApi';

const STEP_TYPES = {
  EMAIL_ENTER: 0,
  COMPANY_SELECT: 1,
  OPTION_SELECT: 2,
  CREDENTIALS_SEND: 3,
  CODE_LOGIN: 4,
  NEW_PASSWORD: 5,
};

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const searchParams = new URLSearchParams(location.search);
    this.userId = searchParams.get('userId');
    this.code = searchParams.get('code');
    this.state = {
      email: '',
      username: '',
      code: this.code || '',
      showFinishModal: false,
      showInvalidLinkModal: false,
      inProgress: false,
      step: this.code && this.userId ? STEP_TYPES.NEW_PASSWORD : STEP_TYPES.EMAIL_ENTER,
      usersWithCompanies: [],
      hasPhoneNumber: false,
      credentialsErrors: null,
    };
  }

  componentDidMount() {
    if (this.userId && this.code) {
      this.setState({ inProgress: true });
      confirmByLink({
        userId: this.userId,
        code: this.code,
      })
        .then(({ email, userName }) => {
          this.setState({
            email,
            username: userName,
            step: STEP_TYPES.NEW_PASSWORD,
            inProgress: false,
          });
        })
        .catch(error => {
          this.setState({
            email: '',
            username: '',
            code: '',
            showFinishModal: false,
            step: STEP_TYPES.EMAIL_ENTER,
            inProgress: false,
            showInvalidLinkModal: true,
          });
          this.code = '';
          this.userId = '';
        });
    }
  }

  renderForm = () => {
    const { step, email, username, code, inProgress, hasPhoneNumber, usersWithCompanies } = this.state;
    if (inProgress) return <LoadingContainer />;
    switch (step) {
      case STEP_TYPES.EMAIL_ENTER:
        return (
          <EmailEnterForm
            goToLoginPage={this.goToLoginPage}
            getUsersByEmail={this.getUsersByEmail}
            nextStep={this.goToNextStep}
          />
        );
      case STEP_TYPES.COMPANY_SELECT:
        return (
          <SelectCompanyForm
            goToLoginPage={this.goToLoginPage}
            usersWithCompanies={usersWithCompanies}
            nextStep={this.selectUser}
          />
        );
      case STEP_TYPES.OPTION_SELECT:
        return (
          <RestoreOptionsForm
            goToLoginPage={this.goToLoginPage}
            hasPhoneNumber={hasPhoneNumber}
            selectRestoreOption={this.selectRestoreOption}
          />
        );
      case STEP_TYPES.CODE_LOGIN:
        return <CodeEnterForm goToLoginPage={this.goToLoginPage} userId={this.userId} nextStep={this.login} />;
      case STEP_TYPES.CREDENTIALS_SEND:
        return <CredentialsForm goToLoginPage={this.goToLoginPage} />;
      case STEP_TYPES.NEW_PASSWORD:
        return (
          <EnterNewPasswordForm
            email={email}
            username={username}
            code={code}
            goToLoginPage={this.goToLoginPage}
            nextStep={() => this.setState({ showFinishModal: true })}
          />
        );
      default:
        break;
    }
    return (
      <EmailEnterForm
        goToLoginPage={this.goToLoginPage}
        getUsersByEmail={this.getUsersByEmail}
        nextStep={this.goToNextStep}
      />
    );
  };

  login = ({ authResponse }) => {
    const { token, username } = authResponse;
    const { roles } = parseJwt(token);
    authService.setUser({
      username,
      token,
      roles,
    });
    const { history } = this.props;
    if (authService.isSuperUser()) {
      history.push('/super-admin');
    } else {
      history.push('/');
    }
  };

  handleOnChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  goToRegisterPage = () => {
    const { history } = this.props;

    history.push('/admin/register');
  };

  goToLoginPage = () => {
    const { history } = this.props;

    history.replace('/auth/login-page');
  };

  selectUser = userData => {
    const { userId, hasPhoneNumber } = userData;
    this.userId = userId;
    this.setState({ step: STEP_TYPES.OPTION_SELECT, hasPhoneNumber });
  };

  selectRestoreOption = optionType => {
    this.setState({ inProgress: true });
    switch (optionType) {
      case OPTION_TYPES.CREDENTIALS_VIA_EMAIL:
        sendLoginСredentials({ userId: this.userId, type: 0 })
          .then(() => this.setState({ step: STEP_TYPES.CREDENTIALS_SEND, inProgress: false }))
          .catch(error => {
            this.setState({ credentialsErrors: error, inProgress: false });
          });
        break;
      case OPTION_TYPES.CREDENTIALS_VIA_TEXT:
        sendLoginСredentials({ userId: this.userId, type: 1 })
          .then(() => this.setState({ step: STEP_TYPES.CREDENTIALS_SEND, inProgress: false }))
          .catch(error => {
            this.setState({ credentialsErrors: error, inProgress: false });
          });
        break;
      case OPTION_TYPES.CODE_VIA_EMAIL:
        sendLoginCode({ userId: this.userId, type: 0 })
          .then(() => this.setState({ step: STEP_TYPES.CODE_LOGIN, inProgress: false }))
          .catch(error => {
            this.setState({ credentialsErrors: error, inProgress: false });
          });
        break;
      case OPTION_TYPES.CODE_VIA_TEXT:
        sendLoginCode({ userId: this.userId, type: 1 })
          .then(() => this.setState({ step: STEP_TYPES.CODE_LOGIN, inProgress: false }))
          .catch(error => {
            this.setState({ credentialsErrors: error, inProgress: false });
          });
        break;

      default:
        break;
    }
  };

  getUsersByEmail = async email => {
    const response = await getUsersByEmail({ email });
    const { usersWithCompanies } = response;
    let nextStep = 1;
    let _hasPhoneNumber = false;

    if (!usersWithCompanies || usersWithCompanies.length === 0) {
      throw Error('User with this email address not found');
    }
    if (usersWithCompanies.length === 1) {
      nextStep = STEP_TYPES.OPTION_SELECT;
      this.userId = usersWithCompanies[0].userId;
      _hasPhoneNumber = usersWithCompanies[0].hasPhoneNumber;
    } else {
      nextStep = STEP_TYPES.COMPANY_SELECT;
    }
    this.setState({ usersWithCompanies, hasPhoneNumber: _hasPhoneNumber });
    return nextStep;
  };

  goToNextStep = step => {
    this.setState({ step });
  };

  renderInvalidLinkModal = () => {
    return (
      <SweetAlert
        warning
        style={{ display: 'block', marginTop: '-15%', fontSize: '0.8571em' }}
        onConfirm={() => {
          this.setState({ showInvalidLinkModal: false });
        }}
        title="Code has expired"
        confirmBtnBsStyle="info"
        confirmBtnText="Ok"
      ></SweetAlert>
    );
  };

  renderFinishModal = () => {
    const { history } = this.props;

    return (
      <SweetAlert
        success
        style={{ display: 'block', marginTop: '-15%', fontSize: '0.8571em' }}
        onConfirm={() => {
          this.setState({ showFinishModal: false });
          history.replace('/auth/login-page');
        }}
        title="Password reset is successful"
        confirmBtnBsStyle="info"
        confirmBtnText="Log in"
      >
        Thank you
      </SweetAlert>
    );
  };

  render() {
    const { showFinishModal, showInvalidLinkModal } = this.state;

    return (
      <>
        {showFinishModal && this.renderFinishModal()}
        {showInvalidLinkModal && this.renderInvalidLinkModal()}

        <div className="login-page">
          <Container>
            <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
              {this.renderForm()}
            </Col>
            <div className="privacy-terms">
              <a href="https://marblplatform.com/terms/" className="pterms-link">
                Terms&Conditions
              </a>
              <a href="https://marblplatform.com/privacy/" className="pterms-link">
                Privacy Policy
              </a>
            </div>
          </Container>
        </div>
        <div className="full-page-background" style={{ backgroundImage: `url(${image.background})` }} />
      </>
    );
  }
}

export default LoginPage;
