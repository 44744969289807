import { action, makeAutoObservable, observable } from 'mobx';
import { getStateList } from '../lib/api/customersApi';

export default class CompanyStore {
  constructor(store) {
    makeAutoObservable(this, {
      states: observable,
      stateList: observable,
      statesLoading: observable,
      fetchStates: action,
    });
    this.store = store;
  }

  states = [];

  stateList = [];

  statesLoading = false;

  fetchStates = (params, effects) => {
    this.statesLoading = true;
    effects?.started && effects.started(params);

    getStateList()
      .then(response => {
        this.states = response;
        this.stateList = response.map(s => `${s.abbreviation}`);
        effects?.done && effects.done(params);
      })
      .catch(error => {
        this.statesLoading = false;
        effects?.failed && effects.failed(params);
      });
  };
}
