import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import moment from 'moment';
import { colors } from 'theme';

const AnnouncementItem = ({ item, onEdit, onDelete }) => {
  const [display, setDisplay] = useState(false);
  return (
    <div
      className="form-control"
      key={item.id}
      style={{ marginBottom: 8, paddingLeft: 10, paddingTop: 10, paddingBottom: 10, paddingRight: 8 }}
      onMouseLeave={() => setDisplay(false)}
      onMouseEnter={() => setDisplay(true)}
    >
      <Row>
        <Col style={{ flex: 1, paddingRight: 0 }}>
          <pre style={{ whiteSpace: 'break-spaces' }}>{item.text}</pre>
        </Col>
        <Col style={{ flex: 0, paddingLeft: 0 }}>
          <div className={`btn-hover ${display ? 'shown' : 'hide'}`} style={{ marginTop: -10 }}>
            <Button
              onClick={() => onEdit(item.id)}
              className="btn-round-small"
              style={{ padding: 0, display: 'block' }}
              color="warning"
            >
              <div style={{ height: 26, width: 26 }}>
                <i style={{ marginTop: 7 }} className="fa fa-edit" />
              </div>
            </Button>
            <Button
              onClick={() => onDelete(item.id)}
              className="btn-round-small"
              style={{ padding: 0, display: 'block' }}
            >
              <div style={{ height: 26, width: 26 }}>
                <i style={{ marginTop: 7 }} className="fa fa-times" />
              </div>
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <p style={{ margin: 0, fontSize: '0.8571em', color: colors.gray, alignSelf: 'flex-end' }}>
            {moment(item.createdAt).format('MM/DD/YYYY')}
          </p>
        </Col>
      </Row>
    </div>
  );
};

AnnouncementItem.propTypes = {
  item: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

AnnouncementItem.defaultProps = {
  item: { id: '', text: '' },
  onEdit: () => {},
  onDelete: () => {},
};

export default AnnouncementItem;
