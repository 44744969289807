import React, { Component } from 'react';
import { observer } from 'mobx-react';
import ReactTable from 'react-table';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Card, CardBody, Row, Col, Button, Input } from 'reactstrap';
import PanelHeader from 'components/PanelHeader/PanelHeader';
import { getSalespersonList } from 'lib/api/salespersonApi';
import authService from 'lib/services/authService';
import { loadStripe } from '@stripe/stripe-js';
import { assignCustomers } from 'lib/api/customersApi';
import defaultFilter from '../../utils/tableFilter';
import config from '../../config';
import store from '../../store';

class AssignCustomersComponents extends Component {
  constructor(props) {
    super(props);
    this.customerIds = props.location?.state?.customerIds;
    this.state = {
      isLoading: false,
      data: [],
      billingErrorModalClosed: false,
      selectedSalesmanId: null,
      showSuccessModal: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      this.fetchSalespersonList();
    });
  }

  fetchSalespersonList = () => {
    const { companyId } = authService.getUser();
    return getSalespersonList(companyId)
      .then(({ salesmanList }) => {
        const data = salesmanList.map(({ avatarUrl, name, phone, userName, id, color, isActive, email }) => {
          return {
            avatarUrl,
            name,
            phone,
            username: userName,
            email,
            id,
            color,
            isActive,
          };
        });
        data.unshift({ name: 'Unassigned', id: null });
        this.setState({
          isLoading: false,
          data,
        });
      })
      .catch(() => this.setState({ isLoading: false }));
  };

  onSave = () => {
    const { selectedSalesmanId } = this.state;
    assignCustomers({ customerIds: this.customerIds, salesmanId: selectedSalesmanId }).then(() => {
      this.setState({ showSuccessModal: true });
    });
  };

  navigateToCustomers = () => {
    const { history } = this.props;
    history.push('/admin/customers');
  };

  renderSuccessModal = () => (
    <SweetAlert
      success
      confirmBtnStyle={{ fontSize: '1em' }}
      style={{ display: 'block', marginTop: '-15%', fontSize: '0.714em' }}
      title="Success"
      onConfirm={this.navigateToCustomers}
      confirmBtnBsStyle="info"
      confirmBtnText="Ok"
      onCancel={this.navigateToCustomers}
    />
  );

  renderStatus = (isActive, key) => (
    // we've added some custom button actions
    // use this button to add a like kind of action
    <Button disabled className="btn-icon btn-round" color={isActive ? 'success' : 'default'} size="sm" />
  );

  render() {
    const { data, selectedSalesmanId, isLoading, billingError, billingErrorModalClosed, showSuccessModal } = this.state;

    if (!billingErrorModalClosed && !billingError?.length && store.company.billingError?.message?.length) {
      this.setState({ billingError: store.company.billingError.message });
    }

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          {showSuccessModal && this.renderSuccessModal()}

          <Row>
            <Col xs={12} md={12}>
              <Card>
                <div style={{ marginLeft: 30, marginTop: 10 }}>
                  <Button color="info" className="btn-round" onClick={this.onSave}>
                    Save
                  </Button>
                </div>
                <CardBody>
                  <ReactTable
                    data={data}
                    filterable
                    loading={isLoading}
                    defaultFilterMethod={defaultFilter}
                    columns={[
                      {
                        Header: '',
                        accessor: '',
                        Cell: row => {
                          return (
                            <div style={{ marginLeft: 30, marginBottom: 20 }}>
                              <Input
                                type="checkbox"
                                checked={row?.value.id === selectedSalesmanId}
                                onClick={e => {
                                  if (data.some(s => s.id === row?.value.id)) {
                                    this.setState({ selectedSalesmanId: row?.value.id });
                                  }
                                }}
                              />
                            </div>
                          );
                        },
                        sortable: false,
                        filterable: false,
                        resizable: false,
                        width: 40,
                      },
                      {
                        Header: 'PROFILE',
                        accessor: 'image',
                        Cell: row => {
                          return (
                            <div>
                              <img
                                alt=""
                                height={34}
                                src={row.original.avatarUrl ? `${config.imagesUrl}${row.original.avatarUrl}` : null}
                              />
                            </div>
                          );
                        },
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: 'NAME',
                        accessor: 'name',
                      },
                      {
                        Header: 'PHONE',
                        accessor: 'phone',
                      },
                      {
                        Header: 'USERNAME',
                        accessor: 'username',
                      },
                      {
                        Header: 'STATUS',
                        accessor: 'isActive',
                        Cell: row => this.renderStatus(row.original.isActive, row.original.id),
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const AssignCustomers = observer(AssignCustomersComponents);

export default AssignCustomers;
