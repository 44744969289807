/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Card, CardBody, CardFooter, CardHeader, Input, InputGroup, Label } from 'reactstrap';
import image from '../../../assets/img';
import { authenticateByCode } from '../../../lib/api/authApi';

class CodeEnterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      codeState: '',
      errorState: '',
      codeFocus: false,
    };
  }

  handleOnChange = event => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        if (this.state.code.length === 6) {
          this.onSubmit();
        }
      }
    );
  };

  checkAllFields = () => {
    const { code } = this.state;

    const errors = {};

    errors.codeState = !code ? 'has-danger' : '';

    const isValid = code;

    this.setState({
      errorState: !isValid ? 'Enter code' : '',
      ...errors,
    });

    return isValid;
  };

  onSubmit = () => {
    const { userId, nextStep } = this.props;
    const { code } = this.state;

    if (!this.checkAllFields()) {
      return;
    }
    const platform = window.navigator.userAgent;
    const clientType = 'WEBPORTAL';
    authenticateByCode({
      userId,
      code,
      platform,
      clientType,
    })
      .then(response => {
        this.setState({ errorState: '' });
        nextStep({ authResponse: response });
      })
      .catch(error => {
        console.log('restore error', error);
        this.setState({ errorState: error?.message, code: '' });
      });
  };

  render() {
    const { email, goToLoginPage } = this.props;
    const { codeState, codeFocus, errorState, code } = this.state;

    return (
      <Card className="card-login card-plain">
        <CardHeader>
          <div className="logo-container">
            <img src={image.logo} alt="now-logo" />
          </div>
          <div className="login-header">Thank You</div>
          <div className="login-info mt-2">Confirmation cod has been sent to you</div>

          <div className="login-info mt-2">Please enter the code</div>
        </CardHeader>
        <CardBody style={{ margin: 'auto', width: '200px' }}>
          <InputGroup className={`no-border form-control-lg ${codeState} ${codeFocus ? 'input-group-focus' : ''}`}>
            <Input
              type="text"
              placeholder="code"
              onFocus={e => this.setState({ codeFocus: true })}
              onBlur={e => this.setState({ codeFocus: false })}
              onChange={this.handleOnChange}
              value={code}
              name="code"
            />
          </InputGroup>
          {errorState && (
            <Label className="ml-3" style={{ color: 'red' }}>
              {errorState}
            </Label>
          )}
        </CardBody>
        <CardFooter>
          <div className="back-button mt-3" onClick={goToLoginPage}>
            Back to Login page
          </div>
        </CardFooter>
      </Card>
    );
  }
}

export default CodeEnterForm;
