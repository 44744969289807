/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import { Card, CardTitle, CardSubtitle, Row, Col, Button, Spinner } from 'reactstrap';

const PlatformCard = ({ name, icon, subtitle, buttonText, onButtonClick, isLoading }) => {
  return (
    <Card className="card-block pb-2">
      <Row>
        <Col style={{ flex: 1 }}>
          <Row>
            <Col style={{ flex: 0 }}>{icon}</Col>
            <Col style={{ flex: 1 }}>
              <Row>
                <CardTitle className="title">{name}</CardTitle>
              </Row>
              <Row>
                <CardSubtitle className="card-subtitle">{subtitle}</CardSubtitle>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col style={{ flex: 0 }} className="text-right">
          {isLoading ? (
            <div style={{ width: 140 }}>
              <Spinner size="sm" color="secondary" />
            </div>
          ) : onButtonClick ? (
            <Button color="info" className="btn-round" onClick={onButtonClick}>
              {buttonText}
            </Button>
          ) : (
            <CardSubtitle className="card-subtitle gray" style={{ marginTop: 0 }}>
              {buttonText}
            </CardSubtitle>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default PlatformCard;
