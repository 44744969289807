import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PerfectScrollbar from 'perfect-scrollbar';
import { authRoutes } from 'routes';
// import FixedPlugin from 'components/FixedPlugin/FixedPlugin';

let ps;

class Auth extends React.Component {
  state = {
    filterColor: 'yellow',
  };

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      // eslint-disable-next-line react/no-string-refs
      ps = new PerfectScrollbar(this.refs.fullPages);
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }

  getRoutes = routes => {
    return routes.map((prop) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === '/auth') {
        return <Route path={prop.layout + prop.path} component={prop.component} key={prop.path} />;
      }
      return null;
    });
  };

  handleColorClick = color => {
    this.setState({ filterColor: color });
  };

  render() {
    return (
      <>
        {/* eslint-disable-next-line react/no-string-refs */}
        <div className="wrapper wrapper-full-page" ref="fullPages">
          <div className="full-page section-image" filter-color={this.state.filterColor}>
            <Switch>
              {this.getRoutes(authRoutes)}
              <Redirect from="/auth" to="/auth/login-page" />
            </Switch>
            {/* Commented in case of adding copyright */}
            {/* <Footer fluid /> */}
          </div>
        </div>
        {/* <FixedPlugin bgColor={this.state.filterColor} handleColorClick={this.handleColorClick} /> */}
      </>
    );
  }
}

export default Auth;
