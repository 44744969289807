/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import ReactTable from 'react-table';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Card, CardBody, Row, Col, Button, UncontrolledTooltip, Label, CardTitle } from 'reactstrap';
import PanelHeader from 'components/PanelHeader/PanelHeader';
import authService from 'lib/services/authService';
import {
  getCustomerList,
  getCustomerImportTemplate,
  deleteCustomers,
  uploadCustomerImport,
  sendPaymentLink,
  getCustomerListWithJobs,
  refundPayment,
} from 'lib/api/customersApi';
import debounce from 'lodash/debounce';
import { getSalespersonList } from 'lib/api/salespersonApi';
import { getSalesMetrics } from 'lib/api/salesMetricsApi';
import DropdownStatus from './DropdownStatus';
import {
  changeProposalSalesStatus,
  uploadSalesAgreement,
  deleteSalesAgreement,
  sendProposalEmail,
} from '../../lib/api/proposalApi';
import { getCustomerJobList } from '../../lib/api/jobApi';
import colors from '../../theme/Color';
import store from '../../store';
import { getSubscriptionPreviewInvoice } from '../../lib/api/billingApi';
import {
  customerProposalStatus,
  proposalStatus as PROPOSAL_STATUS,
  customerTags as CUSTOMER_TAGS,
} from '../../variables/general';
import JobCard from './JobCard';
import SalespersonList from './SalespersonList/SalespersonList';
import InfoBoard from './InfoBoard';

const styles = {
  checkbox: {
    width: '20px',
    height: '20px',
    marginRight: '8px',
    backgroundColor: colors.white,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkboxIcon: { color: colors.blue, fontWeight: 'bold' },
};

class CustomersComponents extends Component {
  constructor(props) {
    super(props);
    this.hiddenFileInput = React.createRef();
    this.hiddenProposalFileInput = React.createRef();

    this.state = {
      isLoading: false,
      data: [],
      tableSorted: [],
      tableFiltered: [],
      importErrors: [],
      salespersons: [],
      isSalespersonsLoading: false,
      deleteProposalModal: {
        show: false,
        onConfirm: () => { },
      },
      showDeactivateModal: false,
      showSuccessModal: false,
      showErrorStripeModal: false,
      showDeleteModal: false,
      showNoOneSelectModal: false,
      showImportSuccessModal: false,
      showDeleteErrorModal: false,
      showImportFailedModal: false,
      showUploadErrorModal: false,
      showSendPaymentModal: false,
      billingErrorModalClosed: false,
      addSalesBillingError: false,
      subscriptionPreviewInvoice: {
        loading: false,
        upcomingInvoice: null,
        remainingDays: null,
        callback: null,
      },
      customersProposalData: {},
      // customersJobData: {},
      previewInvoiceModal: false,
      activateBillingError: false,
      pageSize: 20,
      showImportModal: false,
      currentPage: 0,
      totalPages: 1,
      // filtres
      selectedSalespersonId: '',
      customerStatusTagName: '',
      isPaymentDue: true,
      isPaymentPastDue: true,
      isInfoDataLoading: true,
      infoBoardData: null,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      this.fetchCustomerList();
    });

    this.setState({ isSalespersonsLoading: true, isInfoDataLoading: true }, () => {
      this.fetchSalespersonList();
      this.fetchInfoBoardData();
    });

    const { billingInfo, fetchBillingInfo, data: company, fetchCompany } = store.company;
    if (!billingInfo?.paymentMethod) {
      fetchBillingInfo();
    }

    if (!company?.id?.length) {
      fetchCompany();
    }
  }

  fetchInfoBoardData = async () => {
    try {
      const data = await getSalesMetrics();
      this.setState({ infoBoardData: data?.salesMetrics, isInfoDataLoading: false });
    } catch (e) {
      this.setState({ isInfoDataLoading: false });
    }
  };

  fetchSalespersonList = () => {
    const { companyId } = authService.getUser();
    return getSalespersonList(companyId)
      .then(({ salesmanList }) => {
        this.setState({
          isLoading: false,
          salespersons: salesmanList.map(({ avatarUrl, name, userName, id, color, isActive }) => {
            return {
              avatarUrl,
              name,
              username: userName,
              id,
              color,
              isActive,
            };
          }),
        });
      })
      .catch(() => this.setState({ isLoading: false }));
  };

  fetchCustomerList = () => {
    this.setState({ isLoading: true });
    const {
      currentPage,
      pageSize,
      isPaymentDue,
      isPaymentPastDue,
      customerStatusTagName,
      selectedSalespersonId,
      tableFiltered,
      tableSorted,
    } = this.state;
    const isAscending = tableSorted?.length > 0;
    const requestData = {
      isPaymentDue,
      isPaymentPastDue,
      customerStatusTagName,
      pageIndex: currentPage,
      pageSize,
      salesmanId: selectedSalespersonId,
      FirstNameFilter: tableFiltered.find(f => f.id === 'firstName')?.value || '',
      LastNameFilter: tableFiltered.find(f => f.id === 'lastName')?.value || '',
      CityFilter: tableFiltered.find(f => f.id === 'city')?.value || '',
      StateFilter: tableFiltered.find(f => f.id === 'state')?.value || '',
      ZipFilter: tableFiltered.find(f => f.id === 'zip')?.value || '',
      OrderByCity: isAscending && tableSorted[0].id === 'city',
      OrderByFirstName: isAscending && tableSorted[0].id === 'firstName',
      OrderByLastName: isAscending && tableSorted[0].id === 'lastName',
      OrderBySalesman: isAscending && tableSorted[0].id === 'salesmanName',
      OrderByState: isAscending && tableSorted[0].id === 'state',
      OrderByZip: isAscending && tableSorted[0].id === 'zip',
      Ascending: isAscending ? tableSorted[0].desc : false,
    };
    getCustomerListWithJobs(requestData)
      .then(({ customersWithCustomerJobs: { totalPages, collection, pageIndex, pageSize: _pageSize } }) => {
        this.setState({
          totalPages,
          data: collection.map(item => ({ ...item, actions: this.renderActions(item.id) })),
          currentPage: pageIndex,
          pageSize: _pageSize,
          isLoading: false,
        });
      })
      .catch(e => {
        this.setState({ isLoading: false });
      });
    // const orders = {};
    // tableSorted.forEach(sort => {
    //   let key = sort.id;
    //   switch (key) {
    //     case 'salesmanName':
    //       key = 'Salesman.AppUser.FirstName';
    //       break;
    //     case 'status':
    //       key = 'Status';
    //       break;
    //     default:
    //       break;
    //   }
    //   orders[key] = sort.desc ? 1 : 0;
    // });
    // const operands = [];
    // tableFiltered.forEach(f => {
    //   const field = f.id;
    //   switch (f.id) {
    //     case 'salesmanName':
    //       operands.push({
    //         field: 'Salesman.AppUser.FirstName',
    //         value: f.value,
    //         type: 7,
    //       });
    //       break;
    //     case 'status':
    //       operands.push({ field: 'Status', value: f.value, type: 1 });
    //       break;
    //     default:
    //       operands.push({ field, value: f.value, type: 7 });
    //       break;
    //   }
    // });
    // return getCustomerList(companyId, pageSize * currentPage, pageSize, orders, operands)
    //   .then(({ customers, total }) => {
    //     const data = customers
    //       .filter(c => !c.isDelete)
    //       .map(
    //         ({
    //           firstName,
    //           lastName,
    //           id,
    //           city,
    //           state,
    //           line1,
    //           line2,
    //           zip,
    //           salesman: _salesman,
    //           email,
    //           phone,
    //           climateZone,
    //           notes,
    //           status,
    //         }) => {
    //           const salesman = _salesman || {};
    //           return {
    //             isSelected: false,
    //             firstName,
    //             lastName,
    //             address: `${line1}${line2 ? ` ${line2}` : ''}`,
    //             city,
    //             state,
    //             zip,
    //             email,
    //             phone,
    //             climateZone,
    //             notes,
    //             status: customerProposalStatus[status || 0],
    //             salesman,
    //             salesmanName:
    //               `${salesman.name ? `${salesman.name}${salesman.lastName ? ' ' : ''}` : ''}${
    //                 salesman.lastName ? `${salesman.lastName}` : ''
    //               }` ||
    //               salesman.id ||
    //               'Unassigned',
    //             id,
    //             actions: this.renderActions(id),
    //           };
    //         }
    //       );
    //     this.setState({
    //       totalPages: Math.ceil(total / pageSize),
    //       isLoading: false,
    //       data,
    //     });
    //   })
    //   .catch(e => {
    //     this.setState({ isLoading: false });
    //   });
  };

  // eslint-disable-next-line react/sort-comp
  debounceFetchCustomerList = debounce(this.fetchCustomerList, 1000);

  // fetchCustomerProposalData = async customerId => {
  //   try {
  //     const jobs = await getCustomerJobList(customerId);
  //     this.setState(prevProps => {
  //       const { customersJobData } = prevProps;
  //       customersJobData[customerId] = jobs?.customerJobs;
  //       return { customersJobData };
  //     });
  //   } catch (e) {
  //     this.setState(prevProps => {
  //       const { customersJobData } = prevProps;
  //       customersJobData[customerId] = [];
  //       return { customersJobData };
  //     });
  //   }
  // };

  onCustomerTagFilter = tag => {
    this.setState({ customerStatusTagName: tag }, () => this.fetchCustomerList());
  };

  compareTableFilters = (filter1, filter2) => {
    if (filter1.length !== filter2.length) {
      return true;
    }
    let result = false;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < filter1.length; i++) {
      if (filter1[i].name !== filter2[i].name || filter1[i].value !== filter2[i].name) {
        result = true;
        i = filter1.length;
      }
    }
    return result;
  };

  onTableFetchData = state => {
    const { pageSize, currentPage, tableFiltered, tableSorted } = this.state;
    const { pageSize: _pageSize, page, filtered, sorted } = state;
    if (!(_pageSize === pageSize && page === currentPage && sorted === tableSorted && filtered === tableFiltered)) {
      if (filtered.length > 0 && this.compareTableFilters(filtered, tableFiltered)) {
        this.setState(
          {
            currentPage: page,
            pageSize: _pageSize,
            tableSorted: sorted,
            tableFiltered: filtered,
          },
          () => {
            this.debounceFetchCustomerList();
          }
        );
      } else {
        this.debounceFetchCustomerList.cancel();
        this.setState(
          {
            currentPage: page,
            pageSize: _pageSize,
            isLoading: true,
            tableSorted: sorted,
            tableFiltered: filtered,
          },
          () => this.fetchCustomerList()
        );
      }
    }
  };

  onSalespersonPress = salespersonId => {
    this.setState({ selectedSalespersonId: salespersonId }, () => this.fetchCustomerList());
  };

  onImport = () => {
    this.setState({ showImportModal: true });
  };

  handleUpload = () => {
    this.hiddenFileInput.current.click();
  };

  uploadFile = event => {
    const fileUploaded = event.target.files[0];
    uploadCustomerImport(fileUploaded)
      .then(() => {
        this.hiddenFileInput.current.value = null;
        this.setState({ showImportSuccessModal: true });
        this.fetchCustomerList();
      })
      .catch(e => {
        const importErrors = e?.code === 422 ? Object.values(e.errors) || [] : [];
        this.setState({ showImportFailedModal: true, importErrors });
        this.hiddenFileInput.current.value = null;
      });
  };

  sendPaymentLink = () => {
    const { data } = this.state;
    const proposalIds = [];
    const selectedCustomers = data.filter(v => v.isSelected);
    selectedCustomers.forEach(c => c.customerJobs?.forEach(j => j.proposals?.forEach(p => proposalIds.push(p.id))));
    sendPaymentLink({ proposalIds });
    this.setState({ showSendPaymentModal: false });
  };

  renderImportModal = () => (
    <SweetAlert
      info
      title="Do you want to download the import file template?"
      cancelBtnStyle={{ fontSize: '1em', backgroundColor: `${colors.blue}!important` }}
      style={{ display: 'block', marginTop: '-15%', fontSize: '0.714em' }}
      onConfirm={() => this.setState({ showImportModal: false }, () => getCustomerImportTemplate())}
      closeOnClickOutside={false}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="link"
      confirmBtnText="Yes"
      onCancel={() => this.setState({ showImportModal: false }, this.handleUpload)}
      cancelBtnText="Skip"
      confirmBtnStyle={{ fontSize: '1em' }}
      showCancel
    />
  );

  renderPreviewInvoiceModal = () => (
    <SweetAlert
      warning
      cancelBtnStyle={{ fontSize: '1em', backgroundColor: `${colors.blue}!important` }}
      style={{ display: 'block', marginTop: '-15%', fontSize: '0.714em' }}
      title={
        this.state.subscriptionPreviewInvoice.loading
          ? 'Loading...'
          : `You've activated a New User. There’s ${this.state.subscriptionPreviewInvoice.remainingDays} days of billing left in this month's billing cycle. Do you agree to have your card charged for ${this.state.subscriptionPreviewInvoice.upcomingInvoice}?`
      }
      onConfirm={() => this.setState({ previewInvoiceModal: false })}
      closeOnClickOutside={false}
      confirmBtnBsStyle="danger"
      cancelBtnBsStyle="info"
      confirmBtnText="No"
      onCancel={() => {
        this.setState({ previewInvoiceModal: false }, () => {
          this.state.subscriptionPreviewInvoice.callback && this.state.subscriptionPreviewInvoice.callback();
        });
      }}
      cancelBtnText="Yes"
      confirmBtnStyle={{ fontSize: '1em', backgroundColor: colors.red, color: 'white' }}
      showCancel
    />
  );

  preparePreviewInvoiceModal = callback => {
    this.setState(
      { subscriptionPreviewInvoice: { loading: true, callback }, previewInvoiceModal: true },
      async prev => {
        try {
          const result = await getSubscriptionPreviewInvoice();
          this.setState({
            subscriptionPreviewInvoice: { ...prev.subscriptionPreviewInvoice, loading: false, ...result },
          });
        } catch (e) {
          this.setState({ subscriptionPreviewInvoice: { loading: false }, previewInvoiceModal: false });
        }
      }
    );
  };

  renderModal = (onConfirm, onCancel, confirmBtnText, cancelBtnText, info, showCancel = true) => {
    if (info)
      return (
        <SweetAlert
          warning
          confirmBtnStyle={{ fontSize: '1em' }}
          style={{ display: 'block', marginTop: '-15%', fontSize: '0.714em' }}
          title={info}
          onConfirm={onConfirm}
          confirmBtnBsStyle="info"
          confirmBtnText={confirmBtnText}
          onCancel={onCancel}
          cancelBtnText={cancelBtnText}
          cancelBtnStyle={{ fontSize: '1em', backgroundColor: colors.red, color: 'white' }}
          showCancel={showCancel}
        />
      );

    return (
      <SweetAlert
        warning
        style={{ display: 'block', marginTop: '-100px', fontSize: '0.714em' }}
        title="Are you sure?"
        onConfirm={onConfirm}
        onCancel={onCancel}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText={confirmBtnText}
        cancelBtnText="Cancel"
        showCancel
      />
    );
  };

  renderImportSuccessModal = () => (
    <SweetAlert
      success
      confirmBtnStyle={{ fontSize: '1em' }}
      style={{ display: 'block', marginTop: '-15%', fontSize: '0.714em' }}
      title="Success"
      onConfirm={() => this.setState({ showImportSuccessModal: false })}
      confirmBtnBsStyle="info"
      confirmBtnText="Ok"
      onCancel={() => this.setState({ showImportSuccessModal: false })}
    />
  );

  renderImportFailedModal = () => (
    <SweetAlert
      warning
      confirmBtnStyle={{ fontSize: '1em' }}
      style={{ display: 'block', marginTop: '-15%', fontSize: '0.714em' }}
      title="Upload failed"
      onConfirm={() => this.setState({ showImportFailedModal: false, importErrors: [] })}
      confirmBtnBsStyle="info"
      confirmBtnText="Ok"
      onCancel={() => this.setState({ showImportFailedModal: false, importErrors: [] })}
    >
      <div style={{ overflow: 'auto', maxHeight: 250 }}>
        {this.state.importErrors.length > 0 ? this.state.importErrors.map(text => <div key={text}>{text}</div>) : null}
      </div>
    </SweetAlert>
  );

  renderUploadFailedModal = () => (
    <SweetAlert
      warning
      confirmBtnStyle={{ fontSize: '1em' }}
      style={{ display: 'block', marginTop: '-15%', fontSize: '0.714em' }}
      title="Upload failed"
      onConfirm={() => this.setState({ showUploadErrorModal: false })}
      confirmBtnBsStyle="info"
      confirmBtnText="Ok"
      onCancel={() => this.setState({ showUploadErrorModal: false })}
    />
  );

  renderDeleteFailedModal = () => (
    <SweetAlert
      warning
      confirmBtnStyle={{ fontSize: '1em' }}
      style={{ display: 'block', marginTop: '-15%', fontSize: '0.714em' }}
      title="Failed to delete"
      onConfirm={() => this.setState({ showDeleteErrorModal: false })}
      confirmBtnBsStyle="info"
      confirmBtnText="Ok"
      onCancel={() => this.setState({ showDeleteErrorModal: false })}
    />
  );

  renderNoOneSelectedModal = () => (
    <SweetAlert
      warning
      confirmBtnStyle={{ fontSize: '1em' }}
      style={{ display: 'block', marginTop: '-15%', fontSize: '0.714em' }}
      title="Choose at least one customer"
      onConfirm={() => this.setState({ showNoOneSelectModal: false })}
      confirmBtnBsStyle="info"
      confirmBtnText="Ok"
      onCancel={() => this.setState({ showNoOneSelectModal: false })}
    />
  );

  renderDeleteModal = () => this.renderModal(this.delete, () => this.setState({ showDeleteModal: false }), 'Yes');

  renderProposalDeleteModal = () => {
    return this.renderModal(
      this.state.deleteProposalModal.onConfirm,
      () =>
        this.setState({
          deleteProposalModal: {
            show: false,
            onConfirm: () => { },
          },
        }),
      'Yes',
      'Cancel',
      'Do you want to delete the proposal?',
      true
    );
  };

  renderSendPaymentLinkModal = () => {
    const { data } = this.state;
    const count = data.filter(v => v.isSelected).length;
    return this.renderModal(
      this.sendPaymentLink,
      () => this.setState({ showSendPaymentModal: false }),
      'Yes',
      'Cancel',
      `You will send a payment link to ${count} ${count > 1 ? 'customers' : 'customer'}`,
      true
    );
  };

  renderSuccessModal = () =>
    this.renderModal(
      this.confirmSuccess,
      () => this.setState({ showSuccessModal: false }),
      'Ok',
      '',
      'Payment has been successfully processed',
      false
    );

  renderErrorStripeModal = () =>
    this.renderModal(
      () => this.setState({ showErrorStripeModal: false }),
      () => null,
      'Ok',
      '',
      'Something went wrong with your payment. Please check your billing information',
      false
    );

  renderDeactivateModal = () =>
    this.renderModal(this.deactivate, () => this.setState({ showDeactivateModal: false }), 'Yes, deactivate it');

  renderActivateModal = () => (
    <SweetAlert
      warning
      style={{ display: 'block', marginTop: '-15%', fontSize: '0.8571em' }}
      title={
        this.state.activateBillingError
          ? 'To activate a salesperson please insert Pricebook info on the "Pricebook Page"'
          : 'To add a new salesperson please insert Pricebook info on the "Pricebook Page"'
      }
      onConfirm={() => this.setState({ showRejectModal: false, activateBillingError: false })}
      closeOnClickOutside={false}
      onCancel={() => this.props.history.push(`/admin/pricebook`)}
      confirmBtnBsStyle="danger"
      cancelBtnBsStyle="info"
      confirmBtnText="Not now"
      cancelBtnText="Ok"
      showCancel
    />
  );

  renderAddCustomerErrorModal = () => (
    <SweetAlert
      warning
      style={{ display: 'block', marginTop: '-15%', fontSize: '0.8571em' }}
      title={'To add a new customer please insert Billing info on the "Billing Page"'}
      onConfirm={() => this.setState({ addSalesBillingError: false, activateBillingError: false })}
      closeOnClickOutside={false}
      onCancel={() => this.props.history.push(`/admin/billing-Info`)}
      confirmBtnBsStyle="danger"
      cancelBtnBsStyle="info"
      confirmBtnText="Not now"
      cancelBtnText="Ok"
      showCancel
    />
  );

  delete = () => {
    const { isLoading, data } = this.state;
    if (isLoading) {
      return;
    }

    this.setState({ isLoading: true });
    const selectedIds = [];
    data.forEach(c => {
      if (c.isSelected) {
        selectedIds.push(c.id);
      }
    });
    deleteCustomers(selectedIds)
      .then(() => {
        this.fetchCustomerList();
        this.setState({ showDeleteModal: false, isLoading: false });
      })
      .catch(() => {
        this.setState({ showDeleteModal: false, isLoading: false });
      });
  };

  onDelete = () => {
    const selectedIds = [];
    this.state.data.forEach(c => {
      if (c.isSelected) {
        selectedIds.push(c.id);
      }
    });
    if (selectedIds.length === 0) {
      this.setState({
        showNoOneSelectModal: true,
      });
    } else {
      this.setState({ showDeleteModal: true });
    }
  };

  onSendPaymentLink = () => {
    const selectedIds = [];
    this.state.data.forEach(c => {
      if (c.isSelected) {
        selectedIds.push(c.id);
      }
    });
    if (selectedIds.length === 0) {
      this.setState({
        showNoOneSelectModal: true,
      });
    } else {
      this.setState({ showSendPaymentModal: true });
    }
  };

  onAssign = () => {
    const customerIds = [];
    this.state.data.forEach(c => {
      if (c.isSelected) customerIds.push(c.id);
    });
    if (customerIds.length === 0) {
      this.setState({
        showNoOneSelectModal: true,
      });
    } else {
      this.props.history.push({
        pathname: `/admin/assigncustomers`,
        state: { customerIds },
      });
    }
  };

  confirmSuccess = () => {
    this.fetchCustomerList();
    this.setState({ showSuccessModal: false });
  };

  renderActions = key => (
    // we've added some custom button actions

    <div className="actions-right">
      {/* use this button to add a edit kind of action */}

      <Button
        id={`editButton${key}`}
        onClick={() => {
          this.props.history.push({
            pathname: `/admin/editcustomer`,
            state: { customer: JSON.stringify(this.state.data.find(c => c.id === key)) },
          });
        }}
        className="btn-icon btn-round"
        color="warning"
        size="sm"
      >
        <i className="fa fa-edit" />
      </Button>
      <UncontrolledTooltip delay={{ show: 300, hide: 0 }} target={`editButton${key}`}>
        Edit customer
      </UncontrolledTooltip>
    </div>
  );

  sendEmail = async emailData => {
    try {
      await sendProposalEmail(emailData);
      alert(`Email sent`);
    } catch (e) {
      alert(`Something went wrong`);
    }
  };

  uploadProposalFile = async (file, proposalId, customerId) => {
    try {
      await uploadSalesAgreement({ file, proposalId });
      // this.fetchCustomerProposalData(customerId);
      this.fetchCustomerList();
      this.setState({ showImportSuccessModal: true });
    } catch (e) {
      this.setState({ showUploadErrorModal: true });
    }
  };

  onDeleteProposal = (proposalId, customerId) => {
    this.handleDeleteProposal(proposalId, customerId);
    this.setState({
      deleteProposalModal: {
        show: false,
        onConfirm: () => { },
      },
    });
  };

  handleDeleteProposal = (proposalId, customerId) => {
    deleteSalesAgreement({ proposalId })
      .then(() => {
        // this.fetchCustomerProposalData(customerId);
        this.fetchCustomerList();
        this.setState({ showImportSuccessModal: true });
      })
      .catch(() => {
        this.setState({ showDeleteErrorModal: true });
      });
  };

  renderUserStatusDropdown = (proposalId, salesStatus, customerId, proposalStatus) => {
    const changeSalesStatus = newSalesStatus => {
      changeProposalSalesStatus({ proposalId, statusId: newSalesStatus }).catch(() => { });
      this.setState(prevProps => {
        const { customersProposalData } = prevProps;
        const proposals = customersProposalData[customerId];
        const proposal = proposals?.find(p => p.id === proposalId);
        proposal.salesStatus = newSalesStatus;
        proposal.userState = this.renderUserStatusDropdown(proposalId, newSalesStatus, customerId, proposalStatus);
        customersProposalData[customerId] = proposals;
        return { customersProposalData };
      });
    };

    return <DropdownStatus salesStatus={salesStatus} onChange={changeSalesStatus} />;
  };

  renderProposalActions = props => {
    if (!props?.original) {
      return null;
    }
    const {
      proposalStatus,
      salesAgreementUrl,
      jobCostReportUrl,
      customerId,
      id: proposalId,
      salesAgreementLoadedManually,
    } = props.original;
    if (proposalStatus === 0) {
      return null;
    }

    const handleUpload = () => {
      this.hiddenProposalFileInput.current.click();
    };

    const uploadFile = event => {
      const fileUploaded = event.target.files[0];
      this.uploadProposalFile(fileUploaded, proposalId, customerId)
        .then(() => {
          this.hiddenProposalFileInput.current.value = null;
        })
        .catch(e => {
          this.hiddenProposalFileInput.current.value = null;
        });
    };

    return (
      <div className="actions-right">
        <Button
          id={`openProposal${proposalId}`}
          onClick={() => {
            window.open(salesAgreementUrl, '_blank');
          }}
          className="btn-icon btn-round"
          color="success"
          size="sm"
        >
          <i className="fa fa-eye" />
        </Button>
        <UncontrolledTooltip delay={{ show: 300, hide: 0 }} target={`openProposal${proposalId}`}>
          View proposal
        </UncontrolledTooltip>
        {!!jobCostReportUrl && (
          <>
            <Button
              id={`openCostReport${proposalId}`}
              onClick={() => {
                window.open(jobCostReportUrl, '_blank');
              }}
              className="btn-icon btn-round"
              color="info"
              size="sm"
            >
              <i className="fa fa-suitcase" />
            </Button>
            <UncontrolledTooltip delay={{ show: 300, hide: 0 }} target={`openCostReport${proposalId}`}>
              View Cost Report
            </UncontrolledTooltip>
          </>
        )}

        {proposalStatus !== 2 && (
          <>
            <Button
              id={`uploadProposal${proposalId}`}
              onClick={handleUpload}
              className="btn-icon btn-round"
              color="warning"
              size="sm"
            >
              <input
                type="file"
                accept=".pdf"
                ref={this.hiddenProposalFileInput}
                onChange={uploadFile}
                style={{ width: 0, height: 0 }}
              />
              <i className="fa fa-file-import" />
            </Button>
            <UncontrolledTooltip delay={{ show: 300, hide: 0 }} target={`uploadProposal${proposalId}`}>
              Upload signed document
            </UncontrolledTooltip>
          </>
        )}

        {salesAgreementLoadedManually && (
          <>
            <Button
              id={`deleteProposal${proposalId}`}
              onClick={() =>
                this.setState({
                  deleteProposalModal: {
                    show: true,
                    onConfirm: () => {
                      this.handleDeleteProposal(proposalId, customerId);
                      this.setState({
                        deleteProposalModal: {
                          show: false,
                          onConfirm: () => { },
                        },
                      });
                    },
                  },
                })
              }
              className="btn-icon btn-round"
              color="danger"
              size="sm"
            >
              <i className="fa fa-trash" />
            </Button>
            <UncontrolledTooltip delay={{ show: 300, hide: 0 }} target={`deleteProposal${proposalId}`}>
              Delete uploaded document
            </UncontrolledTooltip>
          </>
        )}
      </div>
    );
  };

  renderProposalTypeTable = (proposals, title) => {
    return (
      <>
        <Label style={{ color: colors.blue, fontSize: '1.14em', fontWeight: 700 }}>{title}</Label>
        <ReactTable
          data={proposals}
          showPagination={false}
          defaultPageSize={proposals.length || 3}
          sortable={false}
          filterable={false}
          collapseOnDataChange={false}
          style={{ backgroundColor: colors.opacityLigthBlue }}
          getTbodyProps={() => ({ className: 'tbody-visible' })}
          getTableProps={() => ({ className: 'table-visible' })}
          columns={[
            {
              Header: 'Proposal Name',
              accessor: 'name',
              minWidth: 150,
            },
            {
              Header: 'Created Date',
              accessor: 'formatedDateCreated',
              width: 110,
              resizable: false,
            },
            {
              Header: 'Sent Date',
              accessor: 'formatedDateSent',
              width: 110,
              resizable: false,
            },
            {
              Header: 'Brand',
              accessor: 'dealerName',
            },
            {
              Header: 'Price',
              accessor: 'price',
              width: 120,
            },
            {
              Header: 'Status',
              accessor: 'formatedProposalStatus',
              width: 160,
              resizable: false,
            },
            {
              Header: 'User Status',
              accessor: 'userState',
              width: 170,
              resizable: false,
            },
            {
              Header: 'Actions',
              Cell: props => this.renderProposalActions(props),
              accessor: 'userState',
              width: 110,
              resizable: false,
            },
          ]}
        />
      </>
    );
  };

  renderJobList = rowData => {
    // const { customersJobData } = this.state;
    const customerId = rowData?.original?.id;
    if (!customerId) return null;
    const jobs = rowData?.original?.customerJobs;
    if (!jobs || jobs.length === 0) return null;
    const soldJobs = [];
    const processJobs = [];
    jobs.forEach(j => (j.customerJobStatus === 'Sold' ? soldJobs.push(j) : processJobs.push(j)));
    const renderSoldJobs = soldJobs.map(j => (
      <JobCard
        key={j.id}
        job={j}
        sold
        sendEmail={this.sendEmail}
        uploadProposalFile={this.uploadProposalFile}
        customerId={customerId}
        onDeleteProposal={this.onDeleteProposal}
      />
    ));
    const renderProcessJob = processJobs.map(j => (
      <JobCard
        key={j.id}
        job={j}
        sendEmail={this.sendEmail}
        uploadProposalFile={this.uploadProposalFile}
        customerId={customerId}
        onDeleteProposal={this.onDeleteProposal}
      />
    ));

    return (
      <Row className="ml-5" style={{ marginRight: -2 }}>
        <Col>
          {renderProcessJob.length > 0 && (
            <div className="card-block">
              <CardTitle className="title">New Jobs</CardTitle>
              {renderProcessJob}
            </div>
          )}
        </Col>
        <Col>
          {renderSoldJobs.length > 0 && (
            <div className="card-block sold">
              <CardTitle className="title">Sold Jobs</CardTitle>
              {renderSoldJobs}
            </div>
          )}
        </Col>
      </Row>
    );
  };

  render() {
    const {
      showDeactivateModal,
      showDeleteModal,
      data,
      showRejectModal,
      showSuccessModal,
      isLoading,
      billingError,
      billingErrorModalClosed,
      addSalesBillingError,
      previewInvoiceModal,
      showErrorStripeModal,
      pageSize,
      currentPage,
      totalPages,
      showImportModal,
      showSendPaymentModal,
      showImportSuccessModal,
      showImportFailedModal,
      // customersProposalData,
      showUploadErrorModal,
      showDeleteErrorModal,
      deleteProposalModal,
      showNoOneSelectModal,
      salespersons,
      isSalespersonsLoading,
      selectedSalespersonId,
      customerStatusTagName,
      isPaymentDue,
      isPaymentPastDue,
      isInfoDataLoading,
      infoBoardData,
    } = this.state;

    if (!billingErrorModalClosed && !billingError?.length && store.company.billingError?.message?.length) {
      this.setState({ billingError: store.company.billingError.message });
    }

    const isAllCustomersSelected = !data.find(v => !v.isSelected);

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          {showDeactivateModal && this.renderDeactivateModal()}
          {showDeleteModal && this.renderDeleteModal()}
          {showRejectModal && this.renderActivateModal()}
          {addSalesBillingError && this.renderAddCustomerErrorModal()}
          {previewInvoiceModal && this.renderPreviewInvoiceModal()}
          {showImportModal && this.renderImportModal()}
          {showImportSuccessModal && this.renderImportSuccessModal()}
          {showImportFailedModal && this.renderImportFailedModal()}
          {showUploadErrorModal && this.renderUploadFailedModal()}
          {showDeleteErrorModal && this.renderDeleteFailedModal()}
          {showNoOneSelectModal && this.renderNoOneSelectedModal()}
          {deleteProposalModal.show && this.renderProposalDeleteModal()}
          {showSendPaymentModal && this.renderSendPaymentLinkModal()}
          {(store.company.billingError?.message?.length || billingError?.length) &&
            !billingErrorModalClosed &&
            this.renderModal(
              () => window.location.reload(false),
              () => {
                this.setState({ billingErrorModalClosed: true });
                store.company.resetBillingError();
              },
              'Try again',
              'Close',
              'An error occurred while loading billing information.\n\rPlease, try again later'
            )}
          {showSuccessModal && this.renderSuccessModal()}
          {showErrorStripeModal && this.renderErrorStripeModal()}

          <Row>
            <Col xs={12} md={12}>
              <Card>
                <Row className="p-3">
                  <Col xs={6} md={6}>
                    <SalespersonList
                      salespersons={salespersons}
                      isLoading={isSalespersonsLoading}
                      onSalespersonPress={this.onSalespersonPress}
                      selectedId={selectedSalespersonId}
                    />
                  </Col>
                  <Col xs={6} md={6}>
                    <InfoBoard isLoading={isInfoDataLoading} data={infoBoardData} />
                  </Col>
                </Row>

                {/* Filters */}
                <Row style={{ marginLeft: 30 }}>
                  <Button
                    color={customerStatusTagName === '' ? 'info' : ''}
                    className="btn-round"
                    onClick={() => this.onCustomerTagFilter('')}
                  >
                    All
                  </Button>

                  <Button
                    style={{ marginLeft: 20 }}
                    color={customerStatusTagName === CUSTOMER_TAGS.LEADS ? 'info' : ''}
                    className="btn-round"
                    onClick={() => this.onCustomerTagFilter(CUSTOMER_TAGS.LEADS)}
                  >
                    Leads
                  </Button>

                  <Button
                    style={{ marginLeft: 20 }}
                    color={customerStatusTagName === CUSTOMER_TAGS.PROSPECTS ? 'info' : ''}
                    className="btn-round"
                    onClick={() => this.onCustomerTagFilter(CUSTOMER_TAGS.PROSPECTS)}
                  >
                    Prospects
                  </Button>

                  <Button
                    style={{ marginLeft: 20 }}
                    color={customerStatusTagName === CUSTOMER_TAGS.CUSTOMERS ? 'info' : ''}
                    className="btn-round"
                    onClick={() => this.onCustomerTagFilter(CUSTOMER_TAGS.CUSTOMERS)}
                  >
                    Customers
                  </Button>
                  <Button
                    style={{ marginLeft: 20 }}
                    color={isPaymentDue ? 'info' : ''}
                    className="btn-round"
                    onClick={() => this.setState({ isPaymentDue: !isPaymentDue }, () => this.fetchCustomerList())}
                  >
                    <div style={{ display: 'flex' }}>
                      <div style={styles.checkbox}>
                        {isPaymentDue && <i className="now-ui-icons ui-1_check" style={styles.checkboxIcon} />}
                      </div>
                      Payment Due
                    </div>
                  </Button>
                  <Button
                    style={{ marginLeft: 20 }}
                    color={isPaymentPastDue ? 'info' : ''}
                    className="btn-round"
                    onClick={() =>
                      this.setState({ isPaymentPastDue: !isPaymentPastDue }, () => this.fetchCustomerList())
                    }
                  >
                    <div style={{ display: 'flex' }}>
                      <div style={styles.checkbox}>
                        {isPaymentPastDue && <i className="now-ui-icons ui-1_check" style={styles.checkboxIcon} />}
                      </div>
                      Payment Past Due
                    </div>
                  </Button>
                </Row>

                {/* Customers board */}
                <Col xs={12} md={12}>
                  <Card>
                    <Row style={{ justifyContent: 'space-between', marginTop: 10 }}>
                      <Row style={{ marginLeft: 30 }}>
                        <Button
                          color="info"
                          className="btn-round"
                          onClick={() => {
                            const { history } = this.props;
                            const { billingLoading } = store.company;

                            if (billingLoading) {
                              return;
                            }
                            if (billingError?.length) {
                              this.setState({ billingErrorModalClosed: false });
                              return;
                            }
                            history.push('/admin/addcustomer');
                          }}
                        >
                          <i className="now-ui-icons ui-1_simple-add" /> Add new
                        </Button>

                        <Button
                          style={{ marginLeft: 20 }}
                          color="warning"
                          className="btn-round"
                          onClick={this.onAssign}
                        >
                          Assign
                        </Button>
                        <Button style={{ marginLeft: 20 }} color="danger" className="btn-round" onClick={this.onDelete}>
                          Delete
                        </Button>
                      </Row>

                      <Row>
                        {/* <div className="input-border-wrapper">
                      <div className="input-search-icon">a</div>
                      <input className="input-search " placeholder="search..." />
                    </div> */}
                        <Button color="success" className="btn-round" onClick={this.onSendPaymentLink}>
                          Send Payment Link
                        </Button>
                        <div style={{ marginRight: 50, marginLeft: 20 }}>
                          <input
                            type="file"
                            accept=".xlsx, .ods, .csv"
                            ref={this.hiddenFileInput}
                            onChange={this.uploadFile}
                            style={{ width: 0, height: 0 }}
                          />
                          <Button color="info" className="btn-round" onClick={this.onImport}>
                            <i className="fa fa-file-import" /> Import
                          </Button>
                        </div>
                      </Row>
                    </Row>
                    <CardBody>
                      <ReactTable
                        data={data}
                        filterable
                        loading={isLoading}
                        defaultPageSize={pageSize}
                        page={currentPage}
                        pages={totalPages}
                        pageSize={pageSize}
                        manual
                        multiSort={false}
                        onFetchData={this.onTableFetchData}
                        showPaginationTop
                        collapseOnDataChange={false}
                        showPaginationBottom={false}
                        className="-striped -highlight"
                        SubComponent={this.renderJobList}
                        // getTdProps={(state, rowInfo, column, instance) => {
                        //   return {
                        //     onClick: async (e, handleOriginal) => {
                        //       const customerId = rowInfo?.original?.id;
                        //       if (customerId && !customersProposalData[customerId]) {
                        //         await this.fetchCustomerProposalData(customerId);
                        //       }
                        //       if (handleOriginal) {
                        //         handleOriginal();
                        //       }
                        //     },
                        //   };
                        // }}
                        getTrProps={() => {
                          return {
                            style: {
                              marginBottom: 4,
                              marginTop: 4,
                              borderRadius: 12,
                              marginRight: 12,
                              marginLeft: 12,
                              backgroundColor: colors.opacityLightGray,
                            },
                          };
                        }}
                        getTrGroupProps={(state, rowInfo, column, instance) => {
                          return {
                            style: {
                              borderWidth: 0,
                            },
                          };
                        }}
                        columns={[
                          {
                            Header: () => (
                              <div style={{ paddingLeft: '12px' }}>
                                <input
                                  id="checkboxSelectAll"
                                  type="checkbox"
                                  onChange={() => {
                                    this.setState(prev => {
                                      const _data = prev.data;
                                      // eslint-disable-next-line no-return-assign, no-param-reassign
                                      _data.forEach(c => (c.isSelected = !isAllCustomersSelected));
                                      return { data: _data };
                                    });
                                  }}
                                  checked={isAllCustomersSelected}
                                />
                                <UncontrolledTooltip delay={{ show: 300, hide: 0 }} target="checkboxSelectAll">
                                  Select all
                                </UncontrolledTooltip>
                              </div>
                            ),
                            accessor: '',
                            Cell: row => {
                              return (
                                <div>
                                  <input
                                    type="checkbox"
                                    onChange={() => {
                                      this.setState(prev => {
                                        const _data = prev.data;
                                        const consumer = _data.find(c => c.id === row?.value.id);
                                        consumer.isSelected = !consumer.isSelected;
                                        return { data: _data };
                                      });
                                    }}
                                    checked={row?.value.isSelected}
                                  />
                                </div>
                              );
                            },
                            sortable: false,
                            filterable: false,
                            resizable: false,
                            width: 40,
                          },
                          {
                            Header: 'FIRST NAME',
                            accessor: 'firstName',
                            style: { color: colors.darkBlue, fontWeight: 700 },
                          },
                          {
                            Header: 'LAST NAME',
                            accessor: 'lastName',
                            style: { color: colors.darkBlue, fontWeight: 700 },
                          },
                          {
                            Header: 'CITY',
                            accessor: 'city',
                            style: { color: colors.darkBlue, fontWeight: 700 },
                          },
                          {
                            Header: 'STATE',
                            accessor: 'state',
                            style: { color: colors.darkBlue, fontWeight: 700 },
                          },
                          {
                            Header: 'ZIP',
                            accessor: 'zip',
                            minWidth: 90,
                            style: { color: colors.darkBlue, fontWeight: 700 },
                          },
                          {
                            Header: 'CONTACT',
                            accessor: 'customerStatusTagName',
                            minWidth: 120,
                            style: { color: colors.darkBlue, fontWeight: 700 },
                            sortable: false,
                            filterable: false,
                            // Filter: ({ filter, onChange }) => (
                            //   <select
                            //     onChange={event => onChange(event.target.value)}
                            //     style={{ width: '100%' }}
                            //     value={filter ? filter.value : 'all'}
                            //   >
                            //     <option value="">Show All</option>
                            //     {customerProposalStatus.map((s, index) => (
                            //       <option key={s} value={index}>
                            //         {s}
                            //       </option>
                            //     ))}
                            //   </select>
                            // ),
                          },
                          {
                            Header: 'PAYMENT',
                            accessor: 'status1',
                            minWidth: 120,
                            style: { color: colors.darkBlue, fontWeight: 700 },
                            sortable: false,
                            filterable: false,
                            // Filter: ({ filter, onChange }) => (
                            //   <select
                            //     onChange={event => onChange(event.target.value)}
                            //     style={{ width: '100%' }}
                            //     value={filter ? filter.value : 'all'}
                            //   >
                            //     <option value="">Show All</option>
                            //     {customerProposalStatus.map((s, index) => (
                            //       <option key={s} value={index}>
                            //         {s}
                            //       </option>
                            //     ))}
                            //   </select>
                            // ),
                          },
                          {
                            Header: 'SALESMAN',
                            accessor: 'salesmanName',
                            // sortable: false,
                            filterable: false,
                            Cell: row => (
                              <div
                                style={
                                  row.value === 'Unassigned'
                                    ? { fontWeight: 700, color: colors.red }
                                    : { color: colors.darkBlue, fontWeight: 700 }
                                }
                              >
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: 'Actions',
                            accessor: 'actions',
                            sortable: false,
                            filterable: false,
                            resizable: false,
                            maxWidth: 100,
                          },
                        ]}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const Customers = observer(CustomersComponents);

export default Customers;
