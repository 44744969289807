import React, { useEffect } from 'react';
import { Card, Row, Col, Button } from 'reactstrap';
import PanelHeader from 'components/PanelHeader/PanelHeader';
import { observer } from 'mobx-react';
import { MarblButton } from '../../components';
import { SearchFilter } from '../SearchFilter';
import { ProductItem } from '../../components/ProductItem';
import store from '../../store';

const AdminDashboard = observer(() => {
  const { companyAdminStore } = store;
  const {
    activeCompanies,
    activeCompaniesLoading,
    inactiveCompanies,
    inactiveCompaniesLoading,
    takeCount,
  } = companyAdminStore;

  useEffect(() => {
    companyAdminStore.loadData();

    return () => {
      companyAdminStore.resetData();
    };
  }, [companyAdminStore]);

  const onSearch = value => {
    companyAdminStore.loadCompaniesBySearchQuery(value);
  };

  const onChangeOnBoardingFilter = onBoardingStatus => {
    companyAdminStore.loadCompaniesByOnBoardingStatus({ onBoardingStatus });
  };

  const onChangeLocationFilter = locationData => {
    companyAdminStore.loadCompaniesByLocation(locationData);
  };

  const onChangeSignUpDateFilter = signUpDateData => {
    companyAdminStore.loadCompaniesBySignUpDate(signUpDateData);
  };

  const onChangeUsersSortTypeFilter = value => {
    companyAdminStore.loadCompaniesByNumberOfUsers({ sortType: value });
  };

  const onChangeUsersNumberFilter = value => {
    companyAdminStore.loadCompaniesByNumberOfUsers({ userNumber: value });
  };

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col className="col-md-12">
            <Card className="pt-5 content">
              <Row style={{ justifyContent: 'center' }} className="pb-3">
                <MarblButton fixedSize="250px" size="lg" label="global" disabled />
              </Row>
              <Row className="mt-5 px-5 justify-content-between">
                <h3>Active Companies</h3>
                <SearchFilter
                  resetFilter={companyAdminStore.resetCompanies}
                  onSearch={onSearch}
                  changeOnBoardingStatus={onChangeOnBoardingFilter}
                  changeLocation={onChangeLocationFilter}
                  changeSignUpDate={onChangeSignUpDateFilter}
                  changeUsersSortType={onChangeUsersSortTypeFilter}
                  changeUsersNumber={onChangeUsersNumberFilter}
                />
              </Row>
              <Row>
                  <ProductItem items={activeCompanies.companyList} isLoading={activeCompaniesLoading}/>
              </Row>
              {activeCompanies.companyList.length < activeCompanies.totalRecordCount &&
                activeCompanies.totalRecordCount > takeCount && (
                  <Button
                    onClick={companyAdminStore.loadActiveCompanies}
                    disabled={activeCompaniesLoading}
                    className="ml-5 mt-4"
                    outline
                    color="info"
                  >
                    {activeCompaniesLoading ? 'Loading' : 'Show more'}
                  </Button>
                )}
              {activeCompanies.companyList.length > 10 && (
                <Button
                  disabled={companyAdminStore.activeCompaniesLoading}
                  onClick={companyAdminStore.uploadFewerActiveCompanies}
                  className="ml-3 mt-4"
                >
                  Show less
                </Button>
              )}

              <div className="row mt-5 px-5">
                <h3>Inactive Companies</h3>
              </div>
              <Row>
                <ProductItem items={inactiveCompanies.companyList} isLoading={inactiveCompaniesLoading}/>
              </Row>
              {inactiveCompanies.companyList.length < inactiveCompanies.totalRecordCount && (
                <Button
                  onClick={companyAdminStore.loadInActiveCompanies}
                  disabled={inactiveCompaniesLoading}
                  className="ml-5 mt-4"
                  outline
                  color="info"
                >
                  {inactiveCompaniesLoading ? 'Loading' : 'Show more'}
                </Button>
              )}
              {inactiveCompanies.companyList.length > 10 && (
                <Button
                  disabled={companyAdminStore.inactiveCompaniesLoading}
                  onClick={companyAdminStore.uploadFewerInActiveCompanies}
                  className="ml-3 mt-4"
                >
                  Show less
                </Button>
              )}

              {/* <div className="form-row ml-4"> */}
              {/*  <FormGroup style={{ marginTop: '20px' }} className="col-md-6"> */}
              {/*    <h3 className="mt-2 mb-1">Announce to team</h3> */}
              {/*    <Input */}
              {/*      className="p-0" */}
              {/*      type="textarea" */}
              {/*      name="text" */}
              {/*      id="exampleText" */}
              {/*      style={{ height: '60px', backgroundColor: colors.white }} */}
              {/*      value="" */}
              {/*    /> */}
              {/*    <Button type="submit" color="primary" className="pull-right" onClick={() => {}}> */}
              {/*      Send */}
              {/*    </Button> */}
              {/*  </FormGroup> */}
              {/* </div> */}
              {/* <div className="col-md-12"> */}
              {/*  {data.length > 0 ? ( */}
              {/*    <ReactTable */}
              {/*      data={data} */}
              {/*      filterable */}
              {/*      defaultFilterMethod={(filter, row) => { */}
              {/*        const id = filter.pivotId || filter.id; */}
              {/*        return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value) : true; */}
              {/*      }} */}
              {/*      columns={[ */}
              {/*        { */}
              {/*          Header: 'Message', */}
              {/*          accessor: 'message', */}
              {/*          filterable: false, */}
              {/*          // eslint-disable-next-line react/display-name */}
              {/*          Cell: row => ( */}
              {/*            <div */}
              {/*              onClick={() => {}} */}
              {/*              style={styles.clickable} */}
              {/*              role="button" */}
              {/*              tabIndex={0} */}
              {/*              onKeyDown={() => {}} */}
              {/*            > */}
              {/*              {row.original.message} */}
              {/*            </div> */}
              {/*          ), */}
              {/*        }, */}
              {/*        { */}
              {/*          Header: 'Date', */}
              {/*          accessor: 'date', */}
              {/*          filterable: false, */}
              {/*        }, */}
              {/*        { */}
              {/*          Header: 'Action', */}
              {/*          accessor: 'actions', */}
              {/*          sortable: false, */}
              {/*          filterable: false, */}
              {/*        }, */}
              {/*      ]} */}
              {/*      defaultPageSize={5} */}
              {/*      className="-striped -highlight" */}
              {/*    /> */}
              {/*  ) : ( */}
              {/*    <Label>There are no previous messages</Label> */}
              {/*  )} */}
              {/* </div> */}
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
});

export default AdminDashboard;
