/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Form, Input } from 'reactstrap';
import { colors } from 'theme';
import image from '../../../assets/img';

const SelectCompanyForm = ({ goToLoginPage, usersWithCompanies, nextStep }) => {
  const [userDataIndex, setUserDataIndex] = useState(0);
  const onChangeNumberOfUsersSelect = e => {
    setUserDataIndex(e.target.value);
  };
  const onSubmit = () => nextStep(usersWithCompanies[userDataIndex]);
  const renderOptions = usersWithCompanies.map((user, index) => (
    <option key={user.companyId} value={index} style={{ color: colors.black }}>
      {user.companyName}
    </option>
  ));

  return (
    <Form>
      <Card className="card-login card-plain">
        <CardHeader>
          <div className="logo-container">
            <img src={image.logo} alt="now-logo" />
          </div>
          <div className="login-header">Select your company</div>
        </CardHeader>
        <CardBody>
          <Input
            onChange={onChangeNumberOfUsersSelect}
            style={{ borderRadius: '0.25rem' }}
            type="select"
            name="select"
            id="companiesSelect"
          >
            {renderOptions}
          </Input>
        </CardBody>
        <CardFooter>
          <Button onClick={onSubmit} block color="info" size="lg" className="mb-3 btn-round">
            Submit
          </Button>
          <div className="back-button mt-3" onClick={goToLoginPage}>
            Back to Login page
          </div>
        </CardFooter>
      </Card>
    </Form>
  );
};

export default SelectCompanyForm;
