import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from 'reactstrap';
import image from '../../../assets/img';

export const OPTION_TYPES = {
  CREDENTIALS_VIA_EMAIL: 0,
  CREDENTIALS_VIA_TEXT: 1,
  CODE_VIA_EMAIL: 2,
  CODE_VIA_TEXT: 3,
};

class RestoreOptionsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errorsState: {},
      isInProgress: false,
    };
    this.inputRef = null;
  }

  checkAllFields = () => {
    const { email } = this.state;

    const errors = {};

    errors.emailState = !email ? 'has-danger' : '';

    const isValid = email;

    this.setState({
      errorState: !isValid ? 'Email field is required' : '',
      ...errors,
    });

    return isValid;
  };

  handleOnChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  renderErrors = () => {
    const { errorsState } = this.state;
    const errors = Object.keys(errorsState);
    return errors.map(error => <Label style={{ color: 'red' }}>{errorsState[error]}</Label>);
  };

  render() {
    const { isInProgress } = this.state;

    const { goToLoginPage, selectRestoreOption, hasPhoneNumber } = this.props;

    return (
      <Form>
        <Card className="card-login card-plain">
          <CardHeader>
            <div className="logo-container">
              <img src={image.logo} alt="now-logo" />
            </div>
            <div className="login-header">Please select</div>
          </CardHeader>
          <CardBody>
            <Button
              onClick={() => selectRestoreOption(OPTION_TYPES.CREDENTIALS_VIA_EMAIL)}
              disabled={isInProgress}
              block
              color="info"
              size="lg"
              className="mb-3 btn-round"
            >
              Receive credentials via email
            </Button>
            {hasPhoneNumber && (
              <Button
                onClick={() => selectRestoreOption(OPTION_TYPES.CREDENTIALS_VIA_TEXT)}
                disabled={isInProgress}
                block
                color="info"
                size="lg"
                className="mb-3 btn-round"
              >
                Receive credentials via text
              </Button>
            )}
            <Button
              onClick={() => selectRestoreOption(OPTION_TYPES.CODE_VIA_EMAIL)}
              disabled={isInProgress}
              block
              color="info"
              size="lg"
              className="mb-3 btn-round"
            >
              Login with a Code received via email
            </Button>
            {hasPhoneNumber && (
              <Button
                onClick={() => selectRestoreOption(OPTION_TYPES.CODE_VIA_TEXT)}
                disabled={isInProgress}
                block
                color="info"
                size="lg"
                className="mb-3 btn-round"
              >
                Login with a Code received via text
              </Button>
            )}
          </CardBody>
          <CardFooter>
            <div className="back-button mt-3" onClick={goToLoginPage}>
              Back to Login page
            </div>
          </CardFooter>
        </Card>
      </Form>
    );
  }
}

export default RestoreOptionsForm;
