import React from 'react';
import PropTypes from 'prop-types';
import { Input, FormGroup, Label } from 'reactstrap';
import { colors } from '../../theme';

const LabelInput = ({ type, className, labelClassName, disabled, label, autoComplete, placeholder, id, onChange, validationClass, required, ...props }) => {
  return (
    <FormGroup className={className}>
      <Label for={id} style={{ color: colors.blue }}>
        {required && <span style={{ color: colors.red }}>*</span>} {label}
      </Label>
      <Input
        type={type}
        id={id}
        placeholder={placeholder}
        autoComplete={autoComplete}
        onChange={onChange}
        disabled={disabled}
        style={validationClass === 'has-danger' ? { borderColor: colors.red } : { borderColor: colors.blue }}
        {...props}
      />
    </FormGroup>
  );
};

LabelInput.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  validationClass: PropTypes.string,
  required: PropTypes.bool,
};

LabelInput.defaultProps = {
  type: 'text',
  className: '',
  autoComplete: 'on',
  placeholder: '',
  id: '',
  onChange: () => null,
  disabled: false,
  label: '',
  validationClass: '',
  required: false,
};

export default LabelInput;
