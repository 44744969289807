import { makeAutoObservable } from 'mobx';
import addDays from 'date-fns/addDays';
import { CompanyAdminService, Status, Location } from '../lib/services/companyAdminService';
import { editCompanyBySuperUser, toggleNotification } from '../lib/api/companyApi';

const SKIP_COUNT_DEFAULT = 0;

class CompanyAdminStore {
  activeCompanies = {
    companyList: [],
    totalRecordCount: 0,
  };

  inactiveCompanies = {
    companyList: [],
    totalRecordCount: 0,
  };

  activeCompaniesError = null;

  inActiveCompaniesError = null;

  selectedCompanyError = null;

  activeCompaniesLoading = false;

  inactiveCompaniesLoading = false;

  selectedCompanyLoading = false;

  selectedCompany = {};

  skipActiveCount = 0;

  skipInactiveCount = 0;

  takeCount = 10;

  constructor() {
    makeAutoObservable(this, { takeCount: false });
  }

  loadActiveCompanies = async () => {
    this.activeCompaniesLoading = true;
    const { companyList } = this.activeCompanies;
    const resData = await CompanyAdminService.getCompaniesByStatus(
      Status.ACTIVE,
      this.skipActiveCount,
      this.takeCount,
      this.activeCompaniesError
    );
    this.activeCompanies = resData;
    this.activeCompanies.companyList = [...companyList, ...resData.companyList];
    this.skipActiveCount = this.skipActiveCount + this.takeCount;
    this.activeCompaniesLoading = false;
  };

  loadInActiveCompanies = async () => {
    this.inactiveCompaniesLoading = true;
    const companyList = this.inactiveCompanies.companyList.slice();
    const resData = await CompanyAdminService.getCompaniesByStatus(
      Status.INACTIVE,
      this.skipInactiveCount,
      this.takeCount,
      this.inActiveCompaniesError
    );
    this.inactiveCompanies = resData;
    this.inactiveCompanies.companyList = [...companyList, ...resData.companyList];
    this.skipInactiveCount = this.skipInactiveCount + this.takeCount;
    this.inactiveCompaniesLoading = false;
  };

  loadCompaniesBySearchQuery = async value => {
    this.activeCompaniesLoading = true;
    this.inactiveCompaniesLoading = true;
    this.activeCompanies = await CompanyAdminService.getCompaniesBySearchQuery({ status: Status.ACTIVE, value });
    this.inactiveCompanies = await CompanyAdminService.getCompaniesBySearchQuery({ status: Status.INACTIVE, value });
    this.skipActiveCount = SKIP_COUNT_DEFAULT;
    this.skipInactiveCount = SKIP_COUNT_DEFAULT;
    this.activeCompaniesLoading = false;
    this.inactiveCompaniesLoading = false;
  };

  loadCompaniesByOnBoardingStatus = async ({ onBoardingStatus, searchQuery = '' }) => {
    this.activeCompaniesLoading = true;
    this.inactiveCompaniesLoading = true;
    this.activeCompanies = await CompanyAdminService.getCompaniesByOnBoardingStatus({
      companyStatus: Status.ACTIVE,
      onBoardingStatus,
      searchQuery,
    });
    this.inactiveCompanies = await CompanyAdminService.getCompaniesByOnBoardingStatus({
      companyStatus: Status.INACTIVE,
      onBoardingStatus,
      searchQuery,
    });
    this.skipActiveCount = SKIP_COUNT_DEFAULT;
    this.skipInactiveCount = SKIP_COUNT_DEFAULT;
    this.activeCompaniesLoading = false;
    this.inactiveCompaniesLoading = false;
  };

  loadCompaniesByLocation = async data => {
    this.activeCompaniesLoading = true;
    this.inactiveCompaniesLoading = true;
    this.activeCompanies = await CompanyAdminService.getCompaniesByLocation({
      status: Status.ACTIVE,
      city: data[Location.CITY],
      state: data[Location.STATE],
      zip: data[Location.ZIP_CODE],
    });
    this.inactiveCompanies = await CompanyAdminService.getCompaniesByLocation({
      status: Status.INACTIVE,
      city: data[Location.CITY],
      state: data[Location.STATE],
      zip: data[Location.ZIP_CODE],
    });
    this.skipActiveCount = 0;
    this.skipInactiveCount = 0;
    this.activeCompaniesLoading = false;
    this.inactiveCompaniesLoading = false;
  };

  loadCompaniesBySignUpDate = async ({ sortType, startDate, endDate }) => {
    this.activeCompaniesLoading = true;
    this.inactiveCompaniesLoading = true;
    this.activeCompanies = await CompanyAdminService.getCompaniesBySignUpDate({
      status: Status.ACTIVE,
      sortType,
      startDate,
      endDate: addDays(endDate, 1),
    });
    this.inactiveCompanies = await CompanyAdminService.getCompaniesBySignUpDate({
      status: Status.INACTIVE,
      sortType,
      startDate,
      endDate: addDays(endDate, 1),
    });
    this.skipActiveCount = SKIP_COUNT_DEFAULT;
    this.skipInactiveCount = SKIP_COUNT_DEFAULT;
    this.activeCompaniesLoading = false;
    this.inactiveCompaniesLoading = false;
  };

  loadCompaniesByNumberOfUsers = async ({ sortType = '', userNumber = '' }) => {
    this.activeCompaniesLoading = true;
    this.inactiveCompaniesLoading = true;
    this.activeCompanies = await CompanyAdminService.getCompaniesByNumberOfUsers({
      status: Status.ACTIVE,
      sortType,
      userNumber,
    });
    this.inactiveCompanies = await CompanyAdminService.getCompaniesByNumberOfUsers({
      status: Status.INACTIVE,
      sortType,
      userNumber,
    });
    this.skipActiveCount = SKIP_COUNT_DEFAULT;
    this.skipInactiveCount = SKIP_COUNT_DEFAULT;
    this.activeCompaniesLoading = false;
    this.inactiveCompaniesLoading = false;
  };

  loadSelectedCompany = async id => {
    this.selectedCompanyLoading = true;
    const resData = await CompanyAdminService.requestSearchCompany(id, this.selectedCompanyError);
    this.selectedCompany = { ...resData.company };
    this.selectedCompanyLoading = false;
    return this.selectedCompany;
  };

  editSelectedCompany = async data => {
    this.selectedCompanyLoading = true;
    editCompanyBySuperUser(data)
      .then(res => {
        this.selectedCompany = res.company;
        this.selectedCompanyLoading = false;
        if (data?.isTrainingComplete === true) {
          toggleNotification(res.company.id, true);
        }
      })
      .catch(error => {
        this.selectedCompanyLoading = false;
      });
  };

  loadData = () => {
    this.loadActiveCompanies();
    this.loadInActiveCompanies();
  };

  resetData = () => {
    this.skipActiveCount = 0;
    this.skipInactiveCount = 0;
    this.activeCompanies = {
      companyList: [],
      totalRecordCount: 0,
    };

    this.inactiveCompanies = {
      companyList: [],
      totalRecordCount: 0,
    };
  };

  uploadFewerActiveCompanies = async () => {
    const rest = this.activeCompanies.companyList.length - this.takeCount
    if(rest > 10){
      this.skipActiveCount = rest;
      this.activeCompanies.companyList.splice(rest);
    }else {
      this.skipActiveCount = 10;
      this.activeCompanies.companyList.splice(10);
    }

  };

  uploadFewerInActiveCompanies = () => {
    this.skipInactiveCount = this.inactiveCompanies.companyList.length - this.takeCount;
    this.inactiveCompanies.companyList.splice(this.inactiveCompanies.companyList.length - this.takeCount);
  };

  resetCompanies = async () => {
    this.activeCompaniesLoading = true;
    this.inactiveCompaniesLoading = true;
    this.skipActiveCount = SKIP_COUNT_DEFAULT;
    this.skipInactiveCount = SKIP_COUNT_DEFAULT;

    this.activeCompanies = await CompanyAdminService.getCompaniesByStatus(
      Status.ACTIVE,
      SKIP_COUNT_DEFAULT,
      this.takeCount,
      this.activeCompaniesError
    );
    this.inactiveCompanies = await CompanyAdminService.getCompaniesByStatus(
      Status.INACTIVE,
      SKIP_COUNT_DEFAULT,
      this.takeCount,
      this.inActiveCompaniesError
    );

    this.skipActiveCount = this.skipActiveCount + this.takeCount;
    this.skipInactiveCount = this.skipInactiveCount + this.takeCount;

    this.activeCompaniesLoading = false;
    this.inactiveCompaniesLoading = false;
  };
}

export default CompanyAdminStore;
