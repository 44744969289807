import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/now-ui-dashboard.scss?v=1.3.0';
import 'assets/css/demo.css';

import AdminLayout from 'layouts/Admin';
import SuperAdminLayout from 'layouts/SuperAdmin';
import AuthLayout from 'layouts/Auth';
import InfoLayout from 'layouts/Info';
import authService from './lib/services/authService';
// super admin
const hist = createBrowserHistory();

const isAuth = props => {
  if (props.location.pathname === '/admin/register') return true;
  return authService.getUser().token;
};

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route
        path="/info"
        render={props => {
          return <InfoLayout {...props} />;
        }}
      />
      <Route
        path="/super-admin"
        render={props => {
          if (!authService.isSuperUser()) {
            return <Redirect to="/auth/login-page" />;
          }
          return <SuperAdminLayout {...props} />;
        }}
      />
      <Route
        path="/admin"
        render={props => {
          if (authService.isSuperUser()) return <Redirect to="/super-admin/dashboard" />;
          if (!isAuth(props)) return <Redirect to="/auth/login-page" />;
          return <AdminLayout {...props} />;
        }}
      />
      <Route
        path="/auth"
        render={props => {
          return <AuthLayout {...props} />;
        }}
      />
      <Redirect to="/admin/dashboard" />
    </Switch>
  </Router>,
  document.getElementById('root')
);
