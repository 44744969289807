import authService from 'lib/services/authService';

import { getJSON, postJSON, putJSON } from './ajax';

export function getCustomerJobList(customerId) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  const resp = getJSON(`/api/customer-job/by-customer-id/list`, REQUEST_OPTIONS, { customerId });
  return resp;
}

export default { getCustomerJobList };
