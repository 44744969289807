import authService from 'lib/services/authService';
import { deleteJSON, getJSON, postJSON, putJSON } from './ajax';

export function getGoalsList(salesmanId) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return getJSON(`/api/goal/list?SalesmanId=${salesmanId}`, REQUEST_OPTIONS);
}

export function createGoal(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return postJSON('/api/goal', data, REQUEST_OPTIONS);
}

export function editGoal(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return putJSON('/api/goal', data, REQUEST_OPTIONS);
}

export function deleteGoal(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return deleteJSON('/api/goal', data, REQUEST_OPTIONS);
}
