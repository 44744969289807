import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PerfectScrollbar from 'perfect-scrollbar';
import { observer } from 'mobx-react';
import NotificationAlert from 'react-notification-alert';
import AdminHeader from 'components/Navbars/AdminHeader';
import Footer from 'components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';
import { drawerRoutes, unauthorizedRoutes, routes as webRoutes } from 'routes';
import authService from 'lib/services/authService';
import Settings from 'views/Settings/Settings';
import { getUser } from 'lib/api/userApi';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getNotification, toggleNotification } from 'lib/api/companyApi';

import { logout } from '../lib/api/authApi';
import store from '../store';
// import FixedPlugin from 'components/FixedPlugin/FixedPlugin';

let ps;

const renderModal = (onConfirm, onCancel, confirmBtnText) => (
  <SweetAlert
    warning
    style={{ display: 'block', marginTop: '-15%', fontSize: '0.8571em' }}
    title="Are you sure?"
    onConfirm={onConfirm}
    onCancel={onCancel}
    confirmBtnBsStyle="info"
    cancelBtnBsStyle="danger"
    confirmBtnText={confirmBtnText}
    cancelBtnText="Cancel"
    showCancel
  />
);

class Admin extends React.Component {
  constructor(props) {
    super(props);
    // sidebarMini: true,
    this.state = {
      backgroundColor: 'blue',
      showlogoutModal: false,
      isAuthorized: !!authService.getUser().token,
      showNotification: false,
    };

    this.notificationAlert = React.createRef();

    this.mainPanel = React.createRef();
  }

  componentDidMount() {
    const { isAuthorized } = this.state;

    if (isAuthorized) {
      this.fetchUserDetails();
      const checkNotification = location => {
        const isAdminPath = location || this.props.location.pathname.substring(0, 6) === '/admin';
        if (isAdminPath) {
          const notificationShown = localStorage.getItem('notificationShown');
          if (!notificationShown) {
            getNotification().then(({ notification }) => {
              if (notification) {
                this.setState({ showNotification: true }, () => {
                  toggleNotification(null, false);
                  localStorage.setItem('notificationShown', true);
                });
                store.company.fetchCompany();
              }
            });
          }
        }
      };
      this.props.history.listen(checkNotification);
      checkNotification();
    }

    // if (navigator.platform.indexOf('Win') > -1) {
    //   document.documentElement.className += ' perfect-scrollbar-on';
    //   document.documentElement.classList.remove('perfect-scrollbar-off');
    //   ps = new PerfectScrollbar(this.mainPanel.current);
    // }
  }

  componentDidUpdate(e) {
    if (e.history.action === 'PUSH') {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.mainPanel.current.scrollTop = 0;
    }
  }

  // componentWillUnmount() {
  // if (navigator.platform.indexOf('Win') > -1) {
  //   ps.destroy();
  //   document.documentElement.className += ' perfect-scrollbar-off';
  //   document.documentElement.classList.remove('perfect-scrollbar-on');
  // }
  // }

  renderNotification = () => (
    <SweetAlert
      warning
      confirmBtnStyle={{ fontSize: '1em' }}
      style={{ display: 'block', marginTop: '-15%', fontSize: '0.714em' }}
      title="Onboarding training has been completed. Now you are able to activate a Salesperson account"
      onConfirm={() => this.setState({ showNotification: false })}
      confirmBtnBsStyle="info"
      confirmBtnText="OK"
    />
  );

  renderLogoutModal = () =>
    renderModal(
      () => {
        logout();
        authService.deleteUser();
        localStorage.removeItem('notificationShown');
        window.location.reload();
      },
      () => this.setState({ showlogoutModal: false }),
      'Yes'
    );

  fetchUserDetails = () => {
    return getUser().then(({ companyAdmin, salesman }) => {
      const user = authService.getUser();
      authService.setUser({ token: user.token, roles: user.roles, username: user.username, ...companyAdmin, salesman });
    });
  };

  minimizeSidebar = () => {
    if (document.body.classList.contains('sidebar-mini')) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle('sidebar-mini');
  };
  // handleColorClick = color => {
  //   this.setState({ backgroundColor: color });
  // };

  getRoutes = routes => {
    return routes.map(prop => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === '/admin') {
        return <Route path={prop.layout + prop.path} component={prop.component} key={prop.path} />;
      }
      return null;
    });
  };

  getActiveRoute = routes => {
    const activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i += 1) {
      if (routes[i].collapse) {
        const collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (window.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return activeRoute;
  };

  render() {
    const { showlogoutModal, backgroundColor, isAuthorized, showNotification } = this.state; // sidebarMini,

    return (
      <div className="wrapper">
        {showlogoutModal && this.renderLogoutModal()}
        {showNotification && this.renderNotification()}
        <NotificationAlert ref={this.notificationAlert} />
        <Sidebar
          {...this.props}
          routes={isAuthorized ? drawerRoutes : unauthorizedRoutes}
          minimizeSidebar={this.minimizeSidebar}
          backgroundColor={backgroundColor}
          logout={() => this.setState({ showlogoutModal: true })}
        />
        <div className="main-panel" ref={this.mainPanel}>
          <AdminHeader {...this.props} brandText={this.getActiveRoute([...webRoutes, ...drawerRoutes])} />
          <Switch>
            {this.getRoutes(drawerRoutes)}
            {this.getRoutes(webRoutes)}
            {/* for unauthorized, there is no registration page yet */}
            <Route key="register" path="/admin/register" render={props => <Settings {...props} isRegister />} />
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          {// we don't want the Footer to be rendered on full screen maps page
          window.location.href.indexOf('full-screen-maps') !== -1 ? null : <Footer fluid />}
        </div>
        {/* <FixedPlugin */}
        {/*  handleMiniClick={this.minimizeSidebar} */}
        {/*  sidebarMini={sidebarMini} */}
        {/*  bgColor={backgroundColor} */}
        {/*  handleColorClick={this.handleColorClick} */}
        {/* /> */}
      </div>
    );
  }
}

export default observer(Admin);
