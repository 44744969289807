import React, { useState } from 'react';

// reactstrap components
import { Input, Label } from 'reactstrap';

import { colors } from 'theme';
import config from '../../config';
import CollapsibleRow from './CollapsibleRow';

const BrandCard = ({
  name,
  index,
  imageUrl,
  indoorUnits,
  dealer,
  selectItem,
  onUnitClick,
  onMultiplierChange: _onMultiplierChange,
}) => {
  const id = dealer?.id || '';
  const [multiplier, setMultiplier] = useState(dealer.multiplier || 0);
  if (!dealer) {
    return null;
  }

  const { enabled } = dealer;

  const onItemClick = () => {
    selectItem(index);
  };
  const saveMultiplier = () => {
    if (multiplier <= 0 || multiplier > 1) {
      setMultiplier(0.1);
      _onMultiplierChange(0.1, index);
    } else {
      _onMultiplierChange(multiplier, index);
    }
  };

  const onMultiplierChange = value => {
    if (value <= 1 && value >= 0) {
      setMultiplier(value);
    }
  };

  const renderRow = item => {
    const rowKey = item.modelType || item.series || item.unitId || item.indoorUnitName || 'name';
    const rowLabel = item.modelType || item.series || item.indoorUnitName || 'name';

    const checkSelected = _unit => {
      if (_unit.units) {
        return !_unit.units.every(_subUnit => {
          return !checkSelected(_subUnit);
        });
      }
      return _unit.isSelected;
    };

    const isItemSelected = checkSelected(item);

    if (item.units?.length > 0) {
      return (
        <CollapsibleRow
          key={rowKey}
          isSelected={isItemSelected}
          label={rowLabel}
          itemEnabled={enabled}
          checkboxClick={() => onUnitClick(item)}
          content={item.units.map(_unit => {
            return renderRow(_unit);
          })}
        />
      );
    }
    return (
      <div key={rowKey} className="collapsible-row" style={{ paddingTop: 5, passingBottom: 5 }}>
        <Input
          type="checkbox"
          style={{ marginTop: 2, marginLeft: 0 }}
          checked={isItemSelected}
          onChange={() => onUnitClick(item)}
        />
        <Label style={{ color: enabled ? colors.blue : colors.gray, fontSize: '1em', paddingLeft: 20 }}>
          {rowLabel}
        </Label>
      </div>
    );
  };
  const _indoorUnits = indoorUnits?.find(iUnits => iUnits.dealerId === id);
  const _units = _indoorUnits ? _indoorUnits.units.map(unit => renderRow(unit)) : null;
  return (
    <div key={id} style={{ minWidth: '450px', width: '450px', marginLeft: '15px' }}>
      <div
        className="form-control"
        style={{
          borderColor: enabled ? colors.blue : colors.gray,
          padding: 10,
          paddingLeft: 40,
          paddingRight: 20,
          cursor: 'default',
          overflow: 'hidden',
        }}
        disabled={!enabled}
      >
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <div style={{ display: 'flex', flex: 1 }}>
              <input
                type="checkbox"
                style={{ marginTop: -5, marginRight: 5 }}
                checked={enabled}
                onChange={onItemClick}
              />
              <Label
                style={{ color: enabled ? colors.blue : colors.gray, fontSize: '1.14em', flex: 1, fontWeight: 700 }}
              >
                {name}
              </Label>
            </div>
            <div>
              <div className="row" style={{ alignItems: 'center', marginLeft: '20px' }}>
                <Label
                  style={{
                    color: enabled ? colors.blue : colors.gray,
                    fontSize: '1em',
                    fontWeight: 600,
                    marginRight: '15px',
                    marginTop: '5px',
                  }}
                >
                  Price Multiplier:
                </Label>
                <Input
                  type="number"
                  className="form-control blue-border outline-none"
                  disabled={!enabled}
                  style={{ width: '80px' }}
                  onChange={event => onMultiplierChange(event.target.value)}
                  value={multiplier}
                  onBlur={saveMultiplier}
                />
              </div>
              <Label
                style={{
                  color: enabled ? colors.blue : colors.gray,
                  fontSize: '0.8571em',
                  fontWeight: 400,
                  // marginRight: '15px',
                  marginTop: '5px',
                }}
              >
                <span style={{ color: colors.red }}>*</span>% of retail value that you pay for units for this brand,
                stated as a decimal between 0 and 1 (contact your distributor if you don't know it)
              </Label>
            </div>
          </div>
          <div style={{ width: '33%' }}>
            <img style={{ width: '100%', borderRadius: 0 }} src={config.imagesUrl + imageUrl} alt="..." />
          </div>
        </div>
        {!!_units && (
          <div
            style={{
              marginLeft: -15,
              padding: 10,
              cursor: 'default',
              overflow: 'hidden',
              borderRadius: '30px',
            }}
          >
            <Label style={{ color: enabled ? colors.blue : colors.gray, fontSize: '1.14em', flex: 1, fontWeight: 700 }}>
              Types & series
            </Label>
            {_units}
          </div>
        )}
      </div>
    </div>
  );
};

export default BrandCard;
