import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, Label, Spinner, Input } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import PanelHeader from 'components/PanelHeader/PanelHeader';
import { colors } from 'theme';
import { observer } from 'mobx-react';
import { LabelInput, LoadingContainer } from 'components';
import AnnouncementItem from './AnnouncementItem';
import image from '../../assets/img';
import {
  createAnnouncement,
  getAnnouncementList,
  deleteAnnouncement,
  updateAnnouncement,
} from '../../lib/api/AnnouncementApi';

const styles = {
  marblShapeContainer: {
    // position: 'absolute',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: '100%',
  },
  marblShape: { alignItems: 'center', flexDirection: 'column', width: '150px', height: 'auto' },
  container: { backgroundColor: colors.lightBlue, paddingLeft: '3%', paddingRight: '3%' },
  clickable: { overflow: 'hidden', cursor: 'pointer' },
};

const AnnouncementsPage = () => {
  const [webPortalText, setWebPortalText] = useState('');
  const [webAnnouncementList, setWebAnnouncementList] = useState([]);
  const [appAnnouncementList, setAppAnnouncementList] = useState([]);
  const [noteList, setNoteList] = useState([]);
  const [appText, setAppText] = useState('');
  const [notesText, setNotesText] = useState('');
  const [isWebPortalSaved, setIsWebPortalSaved] = useState(false);
  const [isAppSaved, setIsAppSaved] = useState(false);
  const [isNotesSaved, setIsNotesSaved] = useState(false);
  const [deleteItemModal, setDeleteItemModal] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [itemId, setItemId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [editText, setEditText] = useState('');
  const [editItemType, setEditItemType] = useState(0);

  const getList = async () => {
    try {
      const response = await getAnnouncementList();
      setWebAnnouncementList(
        response.announcements.WebPortal?.sort((a, b) =>
          new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime() ? 1 : -1
        ) || []
      );
      setAppAnnouncementList(
        response.announcements.App?.sort((a, b) =>
          new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime() ? 1 : -1
        ) || []
      );
      setNoteList(
        response.announcements.Notes?.sort((a, b) =>
          new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime() ? 1 : -1
        ) || []
      );
    } catch (error) {
      console.log('error: ', error);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const save = async type => {
    try {
      switch (type) {
        case 0:
          if (!webPortalText) {
            return;
          }
          await createAnnouncement({ type, text: webPortalText });
          setIsWebPortalSaved(true);
          setWebPortalText('');
          break;
        case 1:
          if (!appText) {
            return;
          }
          await createAnnouncement({ type, text: appText });
          setIsAppSaved(true);
          setAppText('');
          break;
        case 2:
          if (!notesText) {
            return;
          }
          await createAnnouncement({ type, text: notesText });
          setIsNotesSaved(true);
          setNotesText('');
          break;

        default:
          break;
      }
      getList();
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const deleteItem = () => {
    setIsLoading(true);
    deleteAnnouncement(itemId)
      .then(() => {
        getList().then(() => {
          setIsLoading(false);
          setDeleteItemModal('');
          setItemId('');
        });
      })
      .catch(e => setIsLoading(false));
  };

  const editItem = () => {
    if (!editText) {
      return;
    }
    setIsLoading(true);
    updateAnnouncement({ id: itemId, newText: editText, newType: editItemType })
      .then(() =>
        getList().then(() => {
          setIsLoading(false);
          setShowEditModal(false);
          setItemId('');
          setEditText('');
        })
      )
      .catch(e => setIsLoading(false));
  };

  const onDelete = (_itemId, itemType) => {
    setItemId(_itemId);
    setDeleteItemModal(itemType);
  };

  const onEdit = (_itemId, text, type) => {
    setEditText(text);
    setItemId(_itemId);
    setEditItemType(type);
    setShowEditModal(true);
  };

  const renderAnnouncementList = list => {
    return (
      <div>
        {list.map(item => (
          <AnnouncementItem
            key={item.id}
            item={item}
            onDelete={() => onDelete(item.id, item.type === 2 ? 'note' : 'announcement')}
            onEdit={() => onEdit(item.id, item.text, item.type)}
          />
        ))}
      </div>
    );
  };

  const renderDeleteModal = () => (
    <SweetAlert
      warning
      confirmBtnStyle={{ fontSize: '1em' }}
      style={{ display: 'block', marginTop: '-15%', fontSize: '0.714em' }}
      title={`Delete this ${deleteItemModal}`}
      onConfirm={() => deleteItem()}
      confirmBtnBsStyle="info"
      confirmBtnText="Ok"
      cancelBtnText="Cancel"
      onCancel={() => setDeleteItemModal('')}
      showCancel={!isLoading}
      showConfirm={!isLoading}
    >
      {isLoading && <Spinner color="primary" style={{ marginBottom: 20 }} />}
    </SweetAlert>
  );

  const renderEditModal = () => (
    <SweetAlert
      confirmBtnStyle={{ fontSize: '1em' }}
      style={{ display: 'block', marginTop: '-15%', fontSize: '0.7142em' }}
      title="Edit text"
      onConfirm={() => editItem()}
      confirmBtnBsStyle="info"
      confirmBtnText="Ok"
      cancelBtnText="Cancel"
      onCancel={() => setShowEditModal(false)}
      showCancel={!isLoading}
      showConfirm={!isLoading}
    >
      <Input value={editText} type="textarea" onChange={e => setEditText(e.target.value)} />
      {isLoading && <Spinner color="primary" style={{ marginBottom: 20 }} />}
    </SweetAlert>
  );

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        {!!deleteItemModal && renderDeleteModal()}
        {showEditModal && renderEditModal()}
        <Row>
          <Col xs={12} md={12} style={{ fontSize: '1.1428em' }}>
            <Card className="card-stats card-raised">
              <div style={styles.marblShapeContainer}>
                <div style={styles.marblShape}>
                  <img src={image.marblShape} alt="" />
                  <h3 className="text-center" style={{ color: colors.blue }}>
                    marbl
                  </h3>
                </div>
              </div>

              <Row className="pl-5 pb-5 pr-5">
                <Col className="pl-1" sm={12} md={3}>
                  <div className="form-row">
                    <div className="col-md-12 has-label">
                      <LabelInput
                        className="col-md-12 has-label"
                        label="Web Portal Announcements"
                        id="webPortalAnnouncements"
                        placeholder="Your announcement..."
                        type="textarea"
                        onChange={e => setWebPortalText(e.target.value)}
                        value={webPortalText}
                      />
                      <Button
                        onClick={() => save(0)}
                        style={{ marginRight: '20px' }}
                        type="submit"
                        color="info"
                        className="pull-right"
                      >
                        Submit
                      </Button>
                      {isWebPortalSaved && (
                        <p
                          style={{ fontSize: '0.714em', color: colors.green, marginRight: '15px', marginTop: '15px' }}
                          className="pull-right"
                        >
                          Success
                        </p>
                      )}
                    </div>
                    <div className="col-md-12 has-label">
                      <LabelInput
                        className="col-md-12 has-label"
                        label="App Announcements"
                        id="appAnnouncements"
                        placeholder="Your announcement..."
                        type="textarea"
                        onChange={e => setAppText(e.target.value)}
                        value={appText}
                      />
                      <Button
                        onClick={() => save(1)}
                        style={{ marginRight: '20px' }}
                        type="submit"
                        color="info"
                        className="pull-right"
                      >
                        Submit
                      </Button>
                      {isAppSaved && (
                        <p
                          style={{ fontSize: '0.714em', color: colors.green, marginRight: '15px', marginTop: '15px' }}
                          className="pull-right"
                        >
                          Success
                        </p>
                      )}
                    </div>
                    <div className="col-md-12 has-label">
                      <LabelInput
                        className="col-md-12 has-label"
                        label="Billing Page Notes"
                        id="billingNote"
                        placeholder="Billing notes..."
                        type="textarea"
                        onChange={e => setNotesText(e.target.value)}
                        value={notesText}
                      />
                      <Button
                        onClick={() => save(2)}
                        style={{ marginRight: '20px' }}
                        type="submit"
                        color="info"
                        className="pull-right"
                      >
                        Submit
                      </Button>
                      {isNotesSaved && (
                        <p
                          style={{ fontSize: '0.714em', color: colors.green, marginRight: '15px', marginTop: '15px' }}
                          className="pull-right"
                        >
                          Success
                        </p>
                      )}
                    </div>
                  </div>
                </Col>
                <Col className="pl-1" sm={12} md={3}>
                  <Label className="mb-3 ml-2">
                    <span style={{ color: colors.blue }}>Web Portal Announcements History</span>
                  </Label>
                  {renderAnnouncementList(webAnnouncementList)}
                </Col>
                <Col className="pl-1" sm={12} md={3}>
                  <Label className="mb-3 ml-2">
                    <span style={{ color: colors.blue }}>App Announcements History</span>
                  </Label>
                  {renderAnnouncementList(appAnnouncementList)}
                </Col>
                <Col className="pl-1" sm={12} md={3}>
                  <Label className="mb-3 ml-2">
                    <span style={{ color: colors.blue }}>Billing Page Notes</span>
                  </Label>
                  {renderAnnouncementList(noteList)}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default observer(AnnouncementsPage);

AnnouncementsPage.defaultProps = {
  isRegister: false,
};
