import React from 'react';

import { Row, Col, Button, Modal, ModalBody, ModalFooter, Input, Alert } from 'reactstrap';
import ReactTable from 'react-table';
import SweetAlert from 'react-bootstrap-sweetalert';
import NumberFormat from 'react-number-format';
import shallowEqual from 'lib/shallowEqual';
import colors from '../../theme/Color';
import { checkZeroFormat } from './helpers';

import {
  createFinancialPlan,
  deleteFinancialPlan,
  editFinancialPlan,
  getFinancialPlans,
  getCashTerms,
  editCashTerms,
} from '../../lib/api/proposalApi';
import { NUM_DEC_REG_EX } from '../../utils/regularExpressions';

const CASH_TERMS_INITIAL = {
  depositUponAgreement: 25.0,
  paymentTowardsEquipmentAndMaterials: 25.0,
  paymentUponRoughCompletion: 25.0,
  finalPaymentUponProjectCompletion: 25.0,
};

const CASH_TERMS_ERRORS_INITIAL = {
  depositUponAgreement: '',
  paymentTowardsEquipmentAndMaterials: '',
  paymentUponRoughCompletion: '',
  finalPaymentUponProjectCompletion: '',
};

class Step3 extends React.Component {
  styles = {
    modalInputs: {
      borderWidth: 1,
      borderRadius: 15,
      borderColor: colors.blue,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      showFinishModal: false,
      popupErrorMessage: '',
      tableErrorMessage: '',
      cashTermsErrorMessage: '',
      formIsSaved: false,
      data: [],
      cashTerms: { ...CASH_TERMS_INITIAL },
      initialState: { ...CASH_TERMS_INITIAL },
      errors: { ...CASH_TERMS_ERRORS_INITIAL },
      isNotHundredPercent: false,
      actionKeyId: null,
      showDeleteModal: false,
      showAddEditModal: false,
      isAddFlow: true,
      isInvalidForm: false,
    };

    this.fetchFinancialPlans();
    this.fetchCashTerms();
  }

  fetchFinancialPlans = () => {
    return getFinancialPlans().then(({ financialPlanItems }) => {
      this.setState({
        isLoading: false,
        data: financialPlanItems.map(item => {
          return {
            id: item.id,
            nroMonths: item.months,
            description: item.description,
            apr: checkZeroFormat(item.apr),
            url: item.financingUrl,
            actions: this.renderActions(item.id),
          };
        }),
      });
    });
  };

  fetchCashTerms = () => {
    return getCashTerms().then(({ cashTerms }) => {
      this.setState(prevState => ({
        cashTerms: { ...prevState.cashTerms, ...cashTerms },
        initialState: { ...cashTerms },
      }));
    });
  };

  delete = () => {
    const { actionKeyId, isLoading } = this.state;
    if (isLoading) {
      return;
    }

    this.setState({ isLoading: true, tableErrorMessage: '' });

    deleteFinancialPlan({ financialPlanItemId: actionKeyId })
      .then(() => {
        this.fetchFinancialPlans();
        this.setState({ showDeleteModal: false });
      })
      .catch(({ message }) => {
        this.setState({ showDeleteModal: false, isLoading: false, tableErrorMessage: message });
      });
  };

  addData = (nroMonths, description, apr, url = '', toggleModal) => {
    const isInvalidForm = !nroMonths || !description || apr === '' || typeof apr === 'undefined' || !url;

    this.setState({ isInvalidForm });
    if (isInvalidForm) {
      return;
    }

    const newItem = {
      months: nroMonths,
      apr,
      description,
      financingUrl: url,
    };

    this.setState({ isLoading: true });

    createFinancialPlan(newItem)
      .then(() => {
        this.fetchFinancialPlans();
        toggleModal();
      })
      .catch(({ message }) => {
        this.setState({ isLoading: false, popupErrorMessage: message });
      });
  };

  editData = (nroMonths, description, apr, url = '', toggleModal, edit) => {
    const isInvalidForm = !nroMonths || !description || apr < 0 || !url;

    this.setState({ isInvalidForm });

    if (isInvalidForm) {
      return;
    }

    const newItem = {
      financialPlanItemId: edit.id,
      months: nroMonths,
      apr,
      description,
      financingUrl: url,
    };

    this.setState({ isLoading: true });

    editFinancialPlan(newItem)
      .then(() => {
        this.fetchFinancialPlans();
        toggleModal();
      })
      .catch(({ message }) => {
        this.setState({ isLoading: false, popupErrorMessage: message });
      });
  };

  editCashTerms = () => {
    const { cashTerms } = this.state;
    editCashTerms(cashTerms)
      .then(() => {
        this.setState({ formIsSaved: true });
      })
      .catch(({ message }) => {
        this.setState({ cashTermsErrorMessage: message });
      });
  };

  renderActions = key => (
    // we've added some custom button actions
    <div className="actions-right">
      {/* use this button to add a edit kind of action */}
      <Button
        onClick={() => {
          const { data } = this.state;
          const obj = data.find(o => o.id === key);
          this.setState({ showAddEditModal: true, tempItemEdit: obj, isAddFlow: false });
        }}
        className="btn-icon btn-round"
        color="warning"
        size="sm"
      >
        <i className="fa fa-edit" />
      </Button>
      {/* use this button to remove the data row */}
      <Button
        onClick={() => this.setState({ showDeleteModal: true, actionKeyId: key })}
        className="btn-icon btn-round"
        color="danger"
        size="sm"
      >
        <i className="fa fa-times" />
      </Button>
    </div>
  );

  onChange = ({ target }) => {
    const { name, value } = target;

    this.setState(prevState => ({
      cashTerms: { ...prevState.cashTerms, [name]: parseFloat(value.replace(NUM_DEC_REG_EX, '')) },
      errors: { ...prevState.errors, [name]: '' },
    }));
  };

  onBlur = ({ target }) => {
    const { name, value } = target;
    this.setState(prevState => ({
      cashTerms: { ...prevState.cashTerms, [name]: checkZeroFormat(value) },
    }));
  };

  renderInput = (title, name, placeholder) => {
    const { errors } = this.state;
    const { cashTerms } = this.state;

    const allowedNumber = ({ floatValue }) => {
      if (!floatValue) {
        return true;
      }
      return floatValue >= 0 && floatValue <= 100;
    };
    return (
      <Row style={{ flex: 1 }}>
        <Col style={{ flex: 3, fontSize: '0.8571em' }}>{title}</Col>
        <Col style={{ flex: 1 }}>
          <NumberFormat
            className="form-control-sm form-control"
            thousandSeparator
            suffix="%"
            decimalSeparator="."
            decimalScale={2}
            name={name}
            onChange={this.onChange}
            onBlur={this.onBlur}
            isAllowed={allowedNumber}
            value={checkZeroFormat(cashTerms[name])}
            style={errors[name] ? { borderColor: colors.red } : {}}
            placeholder={placeholder}
          />
        </Col>
      </Row>
    );
  };

  toggleAddEditModal = () => {
    this.setState(prevState => ({
      showAddEditModal: !prevState.showAddEditModal,
      isAddFlow: true,
      isInvalidForm: false,
      tempItemEdit: {},
    }));
  };

  renderFinishModal = () =>
    this.renderModal(() => this.setState({ showFinishModal: false }), null, 'Ok', null, 'Information has been saved');

  renderDeleteModal = () =>
    this.renderModal(
      () => this.delete(),
      () => this.setState({ showDeleteModal: false }),
      'Yes, delete it',
      'Cancel',
      'Are you sure?'
    );

  isValidated = () => {
    const { cashTerms, initialState } = this.state;
    const errors = {};
    let isValid = true;
    let totalPercent = 0;
    Object.keys(CASH_TERMS_INITIAL).forEach(key => {
      errors[key] = cashTerms[key] ? '' : 'has-danger';
      totalPercent += parseFloat(cashTerms[key] || 0);
      if (!cashTerms[key]) {
        isValid = false;
      }
    });

    const isNotHundredPercent = totalPercent !== 100;

    isValid = !isNotHundredPercent ? isValid : false;

    this.setState({ errors, isNotHundredPercent });
    this.setState({ formIsSaved: false });

    if (isValid && !shallowEqual(cashTerms, initialState)) {
      this.editCashTerms();
    }

    if (isValid && shallowEqual(cashTerms, initialState)) {
      this.setState({ formIsSaved: true });
    }

    if (isValid) {
      this.setState({ showFinishModal: true });
    }

    return isValid;
  };

  renderModal = (onConfirm, onCancel, confirmBtnText, cancelBtnText, title) => {
    const propsModal = {
      title,
      onConfirm,
    };

    if (onCancel) {
      propsModal.showCancel = true;
      propsModal.onCancel = onCancel;
      propsModal.cancelBtnBsStyle = 'danger';
      propsModal.cancelBtnText = cancelBtnText;
    }

    return (
      <SweetAlert
        warning
        style={{ display: 'block', marginTop: '-15%', fontSize: '0.8571em' }}
        onConfirm={onConfirm}
        confirmBtnBsStyle="info"
        confirmBtnText={confirmBtnText}
        {...propsModal}
      />
    );
  };

  renderAddEditModal = (active, toggleModal, edit = {}) => {
    const { isAddFlow, isInvalidForm, isLoading, popupErrorMessage } = this.state;

    const handleSubmit = event => {
      event.preventDefault();
      const { nroMonths, description, apr, url } = event.target;
      if (isAddFlow) {
        this.addData(parseInt(nroMonths.value, 10), description.value, parseFloat(apr.value), url.value, toggleModal);
      } else {
        this.editData(
          parseInt(nroMonths.value, 10),
          description.value,
          parseFloat(apr.value),
          url.value,
          toggleModal,
          edit
        );
      }
    };

    const onChange = ({ target }) => {
      const { name } = target;
      let { value } = target;

      if (name === 'nroMonths') {
        value = value.replace(NUM_DEC_REG_EX, '');
      }

      this.setState(prevState => ({ tempItemEdit: { ...prevState.tempItemEdit, [name]: value } }));
    };

    const onNumberChange = ({ target }) => {
      const { name, value } = target;
      this.setState(prevState => ({
        tempItemEdit: { ...prevState.tempItemEdit, [name]: value.replace(NUM_DEC_REG_EX, '') },
      }));
    };

    const onBlur = ({ target }) => {
      const { name, value } = target;
      this.setState(prevState => ({
        tempItemEdit: { ...prevState.tempItemEdit, [name]: checkZeroFormat(value) },
      }));
    };

    const allowedNumber = ({ floatValue }) => {
      if (!floatValue) {
        return true;
      }
      return floatValue >= 0 && floatValue <= 100;
    };

    const { modalInputs } = this.styles;

    return (
      <Modal isOpen={active} toggle={toggleModal} size="lg">
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <Row>
              <Col style={{ flex: 1 }}>
                # of months
                <Input
                  name="nroMonths"
                  className="form-control-sm"
                  value={edit.nroMonths || ''}
                  style={
                    isInvalidForm && !edit.nroMonths ? { ...modalInputs, borderColor: colors.red } : { ...modalInputs }
                  }
                  type="text"
                  placeholder="36"
                  onChange={onChange}
                />
              </Col>
              <Col style={{ flex: 1 }}>
                APR
                <NumberFormat
                  className="form-control-sm form-control"
                  thousandSeparator
                  suffix="%"
                  decimalSeparator="."
                  decimalScale={2}
                  name="apr"
                  onChange={onNumberChange}
                  isAllowed={allowedNumber}
                  onBlur={onBlur}
                  value={edit.apr}
                  style={
                    isInvalidForm && (edit.apr === '' || typeof edit.apr === 'undefined')
                      ? { ...modalInputs, borderColor: colors.red }
                      : { ...modalInputs }
                  }
                  placeholder="4.99%"
                />
              </Col>
              <Col style={{ flex: 1 }}>
                Description
                <Input
                  name="description"
                  value={edit.description}
                  style={
                    isInvalidForm && !edit.description
                      ? { ...modalInputs, borderColor: colors.red }
                      : { ...modalInputs }
                  }
                  type="textarea"
                  placeholder="Financing description..."
                  onChange={onChange}
                />
              </Col>
              <Col style={{ flex: 1 }}>
                Financing url
                <Input
                  name="url"
                  value={edit.url || ''}
                  style={isInvalidForm && !edit.url ? { ...modalInputs, borderColor: colors.red } : { ...modalInputs }}
                  type="text"
                  className="form-control-sm"
                  placeholder="www.financing-site.com"
                  onChange={onChange}
                />
              </Col>
            </Row>
            {popupErrorMessage && <Alert color="danger">{popupErrorMessage}</Alert>}
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              className="btn-round"
              onClick={() => {
                edit && this.setState({ tempItemEdit: {} });
                toggleModal();
              }}
            >
              Close
            </Button>
            <Button disabled={isLoading} type="submit" className="btn-round" color="info">
              Save
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  };

  getPageSize = () => {
    const { data } = this.state;
    if (!data.length) {
      return 5;
    }
    return data.length <= 5 ? data.length : 5;
  };

  render() {
    const {
      showFinishModal,
      data,
      showDeleteModal,
      showAddEditModal,
      tempItemEdit,
      isNotHundredPercent,
      tableErrorMessage,
      cashTermsErrorMessage,
      isLoading,
    } = this.state;
    return (
      <form>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Col style={{ maxWidth: '80%' }}>
            <h6 className="info-text">Cash Terms</h6>
            {this.renderInput('Deposit upon agreement', 'depositUponAgreement', '-%')}
            {this.renderInput('Payment towards equipment & materials', 'paymentTowardsEquipmentAndMaterials', '-%')}
            {this.renderInput('Payment upon rough completion', 'paymentUponRoughCompletion', '-%')}
            {this.renderInput('Final payment upon project completion', 'finalPaymentUponProjectCompletion', '-%')}
            {isNotHundredPercent && <p style={{ color: colors.red, textAlign: 'right' }}>100% is required</p>}
            {cashTermsErrorMessage && <p style={{ color: colors.red, textAlign: 'right' }}>{cashTermsErrorMessage}</p>}
            <br></br>
            <div>
              {showDeleteModal && this.renderDeleteModal()}
              {showFinishModal && this.renderFinishModal()}
              {showAddEditModal && this.renderAddEditModal(showAddEditModal, this.toggleAddEditModal, tempItemEdit)}
              <ReactTable
                data={data}
                filterable
                loading={isLoading}
                defaultFilterMethod={(filter, row) => {
                  const id = filter.pivotId || filter.id;
                  return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value) : true;
                }}
                columns={[
                  {
                    Header: '# OF MONTHS',
                    accessor: 'nroMonths',
                  },
                  {
                    Header: 'APR',
                    accessor: 'apr',
                  },
                  {
                    Header: 'DESCRIPTION',
                    accessor: 'description',
                  },
                  {
                    Header: 'FINANCING URL',
                    accessor: 'url',
                  },
                  {
                    Header: 'ACTIONS',
                    accessor: 'actions',
                    sortable: false,
                    filterable: false,
                  },
                ]}
                pageSize={this.getPageSize()}
                showPaginationBottom={data.length > 5}
                showPaginationTop={false}
                className="-striped -highlight"
              />
              <Button onClick={this.toggleAddEditModal} className="btn-round" color="info">
                Add New
              </Button>
              {tableErrorMessage && <Alert color="danger">{tableErrorMessage}</Alert>}
            </div>
          </Col>
        </div>
      </form>
    );
  }
}

export default Step3;
