/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import { Card, CardBody, CardTitle, Row, Col, Table } from 'reactstrap';

const InfoBoard = ({ isLoading = true, data }) => {
  const placeholder = isLoading || !data ? '...' : null;
  return (
    <Card>
      <CardBody>
        <Row style={{ marginBottom: '20px' }}>
          <Col>
            <Row>
              <Col>
                <CardTitle className="title blue large">Total Sales</CardTitle>
              </Col>
            </Row>

            <Row>
              <Col>
                <Table borderless>
                  <tbody className="blue-text">
                    <tr>
                      <td>Year to Date</td>
                      <td className="green-text text-right">{placeholder || `$${data.yearToDateSales}`}</td>
                    </tr>
                    <tr>
                      <td>Month to Date</td>
                      <td className="green-text text-right">{placeholder || `$${data.monthToDateSales}`}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>

          <Col>
            <Row>
              <Col>
                <CardTitle className="title blue large">Billing and Payments</CardTitle>
              </Col>
            </Row>

            <Table borderless>
              <tbody className="blue-text">
                <tr>
                  <td>Total Pending Transactions</td>
                  <td className="green-text text-right">{placeholder || `$${data.totalPendingTransactions}`}</td>
                </tr>
                <tr>
                  <td>Past Due Payments</td>
                  <td className="green-text text-right">{placeholder || `$${data.pastDuePayments}`}</td>
                </tr>
                <tr>
                  <td>Estimated Potential Volume</td>
                  <td className="green-text text-right">{placeholder || `$${data.estimatedPotentialValue}`}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>

        <Row>
          <Col>
            <CardTitle className="title blue large">Sales Performance</CardTitle>
          </Col>
        </Row>

        <Table borderless style={{ marginTop: -47 }}>
          <thead className="blue-text">
            <tr style={{ height: 50 }}>
              <td></td>
              <td className="text-center">Last Month</td>
              <td className="text-center">This Month</td>
              <td className="text-center">All Time</td>
            </tr>
          </thead>
          <tbody className="blue-text">
            <tr>
              <td>Average Ticket Price per Customer</td>
              <td className="green-text text-center">
                {placeholder || `$${data.lastMonthAverageTicketPricePerCustomer}`}
              </td>
              <td className="green-text text-center">
                {placeholder || `$${data.thisMonthAverageTicketPricePerCustomer}`}
              </td>
              <td className="green-text text-center">
                {placeholder || `$${data.allTimeAverageTicketPricePerCustomer}`}
              </td>
            </tr>
            <tr>
              <td>Closing Ratio</td>
              <td className="green-text text-center">{placeholder || `${data.lastMonthClosingRatio}%`}</td>
              <td className="green-text text-center">{placeholder || `${data.thisMonthClosingRatio}%`}</td>
              <td className="green-text text-center">{placeholder || `${data.allTimeClosingRatio}%`}</td>
            </tr>
            <tr>
              <td>Buying Percentage</td>
              <td className="green-text text-center">{placeholder || `${data.lastMonthBuyingPercentage}%`}</td>
              <td className="green-text text-center">{placeholder || `${data.thisMonthBuyingPercentage}%`}</td>
              <td className="green-text text-center">{placeholder || `${data.allTimeBuyingPercentage}%`}</td>
            </tr>
            <tr>
              <td>Selling Percentage</td>
              <td className="green-text text-center">{placeholder || `${data.lastMonthSellingPercentage}%`}</td>
              <td className="green-text text-center">{placeholder || `${data.thisMonthSellingPercentage}%`}</td>
              <td className="green-text text-center">{placeholder || `${data.allTimeSellingPercentage}%`}</td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default InfoBoard;
