import React, { useEffect } from 'react';

// reactstrap components
import { Card, CardBody, Row, Col } from 'reactstrap';

// core components
import PanelHeader from 'components/PanelHeader/PanelHeader';

import { CardTitle } from 'components';
import { colors } from 'theme';
import Stripe from 'components/icons/Stripe';
import { getStripeAccount, getStripeAccountLink } from '../../lib/api/subscriptionApi';
import store from '../../store';
import PlatformCard from './PlatformCard';

const styles = {
  card: { paddingLeft: '20px', paddingBottom: '20px' },
  title: { color: colors.blue, fontSize: '1.2857em', fontWeight: '600', marginTop: '20px' },
};

class IntegrationsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { stripeAccountId: '', isLoading: false, isStripeConnected: false };
  }

  componentDidMount() {
    const { data: company, isStripeConnected } = store.company;
    this.setState({ isStripeConnected });
    try {
      if (!company?.id?.length) {
        this.setState({ isLoading: true });
        store.company.fetchCompany(null, {
          done: () => {
            this.setState({
              stripeAccountId: store.company?.data?.stripeAccountId,
              isLoading: false,
              isStripeConnected: store.company?.isStripeConnected,
            });
          },
        });
      } else {
        this.setState({ stripeAccountId: company.stripeAccountId });
      }
    } catch (e) {
      this.setState({ isLoading: false });
    }
  }

  createStripeConnect = () => {
    this.setState({ isLoading: true });
    getStripeAccount()
      .then(result => {
        getStripeAccountLink()
          .then(resp => {
            if (resp.url) {
              window.open(resp.url, '_self');
              this.setState({ isLoading: false });
            }
          })
          .catch(e => {
            this.setState({ isLoading: false });
          });
      })
      .catch(e => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { stripeAccountId, isLoading, isStripeConnected } = this.state;
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card className="card-stats card-raised" style={styles.card}>
                <CardBody>
                  <CardTitle style={styles.title}>Platform</CardTitle>
                  <Row>
                    <Col>
                      <PlatformCard
                        name="Stripe Connect"
                        subtitle={
                          isStripeConnected
                            ? `Stripe Account ID: ${stripeAccountId}`
                            : 'You can accept payments in the Marbl App if you create and connect a Stripe Account!'
                        }
                        icon={<Stripe />}
                        isLoading={isLoading}
                        buttonText={isStripeConnected ? 'Connected' : 'Connect'}
                        onButtonClick={isStripeConnected ? null : () => this.createStripeConnect()}
                      />
                    </Col>
                    <Col></Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default IntegrationsPage;
