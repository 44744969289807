import React, { Component } from 'react';
// reactstrap components
import { Card, CardBody, Row, Col } from 'reactstrap';

// core components
import PanelHeader from 'components/PanelHeader/PanelHeader';
import Wizard from './Wizard';

class Pricebook extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { showDeleteModal } = this.state;
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          {showDeleteModal && this.renderDeleteModal()}
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardBody>
                  <Wizard />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Pricebook;
