import * as React from 'react';
import { Row, Col, Card, Button, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import PanelHeader from 'components/PanelHeader/PanelHeader';
import authService from 'lib/services/authService';
import { colors } from 'theme';
import { observer } from 'mobx-react';
import image from '../../assets/img';
import store from '../../store';
import config from '../../config';
import { getSubscriptionMeneagmentData, getBillingInfoPage } from '../../lib/api/billingApi';

const styles = {
  marblShapeContainer: {
    position: 'absolute',
    right: '10px',
  },
  marblShape: { alignItems: 'center', flexDirection: 'column', width: '150px', height: 'auto' },
  container: { backgroundColor: colors.lightBlue, paddingLeft: '3%', paddingRight: '3%' },
  clickable: { overflow: 'hidden', cursor: 'pointer' },
  button: { marginLeft: '15px', width: '220px' },
};

class BillingPage extends React.Component {
  constructor(props) {
    super(props);
    const { email } = authService.getUser();
    this.isRegister = props.location?.state?.isRegister || false;
    this.state = {
      subscriptionType: null,
      subscriptionStripeUrl: '',
      productDescription: '',
      productName: '',
      seats: '',
      email,
    };
  }

  async componentDidMount() {
    const { data: company, billingInfo, fetchBillingInfo, fetchCompany } = store.company;
    try {
      try {
        const billingInfoResponse = await Promise.all([getBillingInfoPage(), getSubscriptionMeneagmentData()]);
        if (billingInfoResponse[1]?.subscriptionEmbedded) {
          this.setState({ subscriptionStripeUrl: billingInfoResponse[1].subscriptionEmbedded });
        }
        if (billingInfoResponse[0]?.billingInfoForBillingPage) {
          this.setState({ ...billingInfoResponse[0].billingInfoForBillingPage });
        }
      } catch (e) {
        this.setState({ subscriptionStripeUrl: '' });
      }

      await getSubscriptionMeneagmentData()
        .then(response => {
          if (response?.subscriptionEmbedded) {
            this.setState({ subscriptionStripeUrl: response.subscriptionEmbedded });
          }
        })
        .catch(e => this.setState({ subscriptionStripeUrl: '' }));
      if (!company?.id?.length) {
        fetchCompany(null, {
          done: () => {
            this.setState({
              subscriptionType: store.company.data.subscriptionType,
            });
          },
        });
      }
      fetchBillingInfo(null, {
        failed: store.company.resetBillingError,
        done: () => {
          this.setState({
            subscriptionType: store.company.data.subscriptionType,
          });
        },
      });
    } catch (e) {
      this.setState({ subscriptionStripeUrl: '' });
    }
  }

  render() {
    const { subscriptionType, subscriptionStripeUrl, email, productDescription, productName, seats } = this.state;
    const { salesmanList } = store.company;
    if (subscriptionType === null) {
      return (
        <>
          <PanelHeader size="sm" />
          <div className="content">
            <Row>
              <Col xs={12} md={12}>
                <Card className="card-stats card-raised" style={{ height: 200 }}>
                  <CardBody>
                    <div style={styles.marblShapeContainer}>
                      <div style={styles.marblShape}>
                        <img src={image.marblShape} alt="" />
                        <h3 className="text-center" style={{ color: colors.blue }}>
                          marbl
                        </h3>
                      </div>
                    </div>
                  </CardBody>
                  <span className="billing-label ml-5 w-100">Loading...</span>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
    if (!salesmanList || salesmanList?.length === 0) {
      return (
        <>
          <PanelHeader size="sm" />
          <div className="content">
            <Row>
              <Col xs={12} md={12}>
                <Card className="card-stats card-raised" style={{ minHeight: 200 }}>
                  <CardBody>
                    <div style={styles.marblShapeContainer}>
                      <div style={styles.marblShape}>
                        <img src={image.marblShape} alt="" />
                        <h3 className="text-center" style={{ color: colors.blue }}>
                          marbl
                        </h3>
                      </div>
                    </div>
                  </CardBody>
                  <span className="billing-label ml-5 w-100" style={{ color: colors.blue }}>
                    You need at least one salesperson
                  </span>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card className="card-stats card-raised" style={{ minHeight: 200 }}>
                {subscriptionStripeUrl && (
                  <CardBody>
                    <div style={styles.marblShapeContainer}>
                      <div style={styles.marblShape}>
                        <img src={image.marblShape} alt="" />
                        <h3 className="text-center" style={{ color: colors.blue }}>
                          marbl
                        </h3>
                      </div>
                    </div>
                  </CardBody>
                )}
                <Row className="pl-5 pb-5 pr-5">
                  <Col className="pl-1" sm={12} md={12} style={{ paddingTop: '15px' }}>
                    {subscriptionStripeUrl ? (
                      <>
                        <Row>
                          <div className="billing-title bold large blue ml-3 mb-1">
                            Current Plan: <span className=" billing-label">{productName}</span>
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <span className="billing-label ml-3 w-100" style={{ color: colors.blue, maxWidth: '600px' }}>
                            {productDescription}
                          </span>
                        </Row>
                        <Row>
                          <h5 className="billing-title bold large blue ml-3 mb-1">
                            Seats: <span className=" billing-label">{seats}</span>
                          </h5>
                        </Row>
                        <Row className="mb-3">
                          <span className="billing-label ml-3 w-100" style={{ color: colors.blue }}>
                            Your current number of users, which can be managed in the{' '}
                            <Link
                              to="/admin/workforce"
                              style={{ color: colors.blue, textDecoration: 'underline', fontWeight: '600' }}
                            >
                              "Workforce"
                            </Link>{' '}
                            tab.
                          </span>
                        </Row>
                        <Row className="mt-5">
                          <h5 className="billing-title bold large blue ml-3 mb-1">Update plans or payment method</h5>
                        </Row>
                        <Row>
                          <Button
                            className="btn btn-info"
                            onClick={() => window.open(subscriptionStripeUrl, '_self')}
                            style={styles.button}
                          >
                            Manage in Stripe <i className="fa fa-external-link-alt ml-1"></i>
                          </Button>
                        </Row>
                      </>
                    ) : (
                      <stripe-pricing-table
                        pricing-table-id={
                          this.isRegister ? config.stripeRegistrationPricingTableId : config.stripePricingTableId
                        }
                        publishable-key={config.stripeKey}
                        customer-email={email}
                      ></stripe-pricing-table>
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default observer(BillingPage);

BillingPage.defaultProps = {
  isRegister: false,
};
