/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Card, CardBody, CardFooter, CardHeader, Form } from 'reactstrap';
import image from '../../../assets/img';

const CredentialsForm = ({ goToLoginPage }) => {
  return (
    <Form>
      <Card className="card-login card-plain">
        <CardHeader>
          <div className="logo-container">
            <img src={image.logo} alt="now-logo" />
          </div>
          <div className="login-header">Thank you!</div>
        </CardHeader>
        <CardBody>
          <div className="login-info mt-2">Credentials and password recovery link have been sent to you</div>
        </CardBody>
        <CardFooter>
          <div className="back-button mt-3" onClick={goToLoginPage}>
            Back to Login page
          </div>
        </CardFooter>
      </Card>
    </Form>
  );
};

export default CredentialsForm;
