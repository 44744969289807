/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useMemo, useState } from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, Row, Col, UncontrolledTooltip, Button, Collapse } from 'reactstrap';
import moment from 'moment';

const hiddenProposalFileInput = React.createRef();

const ProposalCard = ({ proposal, sold, uploadProposalFile, customerId, onDeleteProposal, sendEmail }) => {
  const {
    name,
    totalProjectInvestmentWithDiscount,
    totalProjectInvestment,
    isSold,
    salesAgreementUrl,
    jobCostReportUrl,
    signedAtTime,
    unsignedAtTime,
    createdAt,
    proposalStatus,
    customerJobPayments,
    id,
  } = proposal;

  const [salesAgreementSelected, setSalesAgreementSelected] = useState(false);
  const [jobCostReportSelected, setJobCostReportSelected] = useState(false);
  const [isPaymentInfoOpen, setIsPaymentInfoOpen] = useState(false);

  const signDate = useMemo(() => (signedAtTime ? new Date(signedAtTime) : null), [signedAtTime]);

  const signString = useMemo(
    () =>
      !!signDate && new Date(unsignedAtTime || 0) < signDate.getTime() ? moment(signDate).format('MM/DD/YYYY') : null,
    [signDate, unsignedAtTime]
  );

  const isDanger = sold && !isSold;

  const handleUpload = () => {
    hiddenProposalFileInput.current.click();
  };

  const uploadFile = event => {
    const fileUploaded = event.target.files[0];
    uploadProposalFile(fileUploaded, id, customerId)
      .then(() => {
        hiddenProposalFileInput.current.value = null;
      })
      .catch(e => {
        hiddenProposalFileInput.current.value = null;
      });
  };

  const onSendEmail = () => {
    sendEmail({
      proposalId: id,
      sendSalesAgreement: salesAgreementSelected,
      sendJobCostReport: jobCostReportSelected,
    });
  };

  const renderActions = () => {
    return (
      <div>
        {/* {!!salesAgreement?.simplifiedUrl && (
          <>
            <Button
              id={`openProposal${id}`}
              onClick={() => {
                window.open(salesAgreement?.simplifiedUrl, '_blank');
              }}
              className="btn-icon btn-round"
              color="success"
              size="sm"
            >
              <i className="fa fa-eye" />
            </Button>
            <UncontrolledTooltip delay={{ show: 300, hide: 0 }} target={`openProposal${id}`}>
              View proposal
            </UncontrolledTooltip>
          </>
        )}
        {!!jobCostReport?.simplifiedUrl && (
          <>
            <Button
              id={`openCostReport${id}`}
              onClick={() => {
                window.open(jobCostReport.simplifiedUrl, '_blank');
              }}
              className="btn-icon btn-round"
              color="info"
              size="sm"
            >
              <i className="fa fa-suitcase" />
            </Button>
            <UncontrolledTooltip delay={{ show: 300, hide: 0 }} target={`openCostReport${id}`}>
              View Cost Report
            </UncontrolledTooltip>
          </>
        )} */}

        {proposalStatus !== 2 && (
          <>
            <Button
              id={`uploadProposal${id}`}
              onClick={handleUpload}
              className="btn-icon btn-round"
              color="warning"
              size="sm"
            >
              <input
                type="file"
                accept=".pdf"
                ref={hiddenProposalFileInput}
                onChange={uploadFile}
                style={{ width: 0, height: 0 }}
              />
              <i className="fa fa-file-import" />
            </Button>
            <UncontrolledTooltip delay={{ show: 300, hide: 0 }} target={`uploadProposal${id}`}>
              Upload signed document
            </UncontrolledTooltip>
          </>
        )}

        {/* {salesAgreement?.manualUploaded && (
          <>
            <Button
              id={`deleteProposal${id}`}
              onClick={() => onDeleteProposal(id, customerId)}
              className="btn-icon btn-round ml-3"
              color="danger"
              size="sm"
            >
              <i className="fa fa-trash" />
            </Button>
            <UncontrolledTooltip delay={{ show: 300, hide: 0 }} target={`deleteProposal${id}`}>
              Delete uploaded document
            </UncontrolledTooltip>
          </>
        )} */}
      </div>
    );
  };

  const renderPaymentItem = ({ amount, paymentMethod, isPaid, paidAt, paymentDueDate, id: _id }) => (
    <Card key={_id} className={`card-block${isPaid ? ' sold' : ''}`}>
      <CardBody>
        <Row>
          <Col>
            <div className="card-subtitle">Amount: ${amount}</div>
            <div className="card-subtitle">Payment Method: {paymentMethod}</div>
          </Col>
          <Col className="right">
            <div className="card-subtitle">
              {isPaid
                ? `Paid: ${moment(paidAt).format('MM/DD/YYYY')}`
                : `Due: ${moment(paymentDueDate).format('MM/DD/YYYY')}`}{' '}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );

  return (
    <div className="card-proposal-position">
      <Card className={`card-proposal${isDanger ? ' danger' : ''}`}>
        <CardBody>
          <Row>
            <Col>
              <Row>
                <Col md="7">
                  <CardTitle className="card-subtitle">{name || 'Unnamed'}</CardTitle>
                </Col>
                <Col md="5">
                  <CardSubtitle className="card-subtitle right">
                    $
                    {Number(
                      totalProjectInvestmentWithDiscount > -1
                        ? totalProjectInvestmentWithDiscount
                        : totalProjectInvestment
                    ).toFixed(2)}
                  </CardSubtitle>
                </Col>
              </Row>
              <Row>
                <Col>
                  <CardSubtitle className="card-subtitle">{proposalStatus}</CardSubtitle>
                  <CardSubtitle className="card-subtitle">
                    Created at: {moment(createdAt).format('MM/DD/YYYY')}
                  </CardSubtitle>
                  {!!signString && <CardSubtitle className="card-subtitle">Signed at: {signString}</CardSubtitle>}
                  {renderActions()}
                </Col>
                {(salesAgreementUrl || jobCostReportUrl) && (
                  <Col>
                    <div style={{ position: 'absolute', bottom: 0, right: 15 }}>
                      {!!jobCostReportUrl && (
                        <div className="card-subtitle right">
                          <a
                            className="card-subtitle"
                            href={jobCostReportUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'underline', fontWeight: '600', marginLeft: 8 }}
                          >
                            Job Cost Report
                          </a>
                        </div>
                      )}
                      {!!salesAgreementUrl && (
                        <div className="card-subtitle">
                          <input
                            type="checkbox"
                            onChange={() => setSalesAgreementSelected(!salesAgreementSelected)}
                            checked={salesAgreementSelected}
                          />
                          <a
                            className="card-subtitle"
                            href={salesAgreementUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'underline', fontWeight: '600', marginLeft: 8 }}
                          >
                            Sales Agreement
                          </a>
                        </div>
                      )}
                      <Button
                        color="info"
                        className={`btn-round${jobCostReportSelected || salesAgreementSelected ? '' : ' disabled'}`}
                        onClick={onSendEmail}
                        block
                      >
                        <i className="now-ui-icons ui-1_send" /> Email
                      </Button>
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          {customerJobPayments?.length > 0 && (
            <div>
              <Button color="info" block onClick={() => setIsPaymentInfoOpen(!isPaymentInfoOpen)}>
                <div>{isPaymentInfoOpen ? 'Hide' : 'Show'} Payment Info</div>
              </Button>
              {/* <div className="card-subtitle text-center"></div> */}
              <Collapse isOpen={isPaymentInfoOpen}>{customerJobPayments.map(p => renderPaymentItem(p))}</Collapse>
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default ProposalCard;
