import React from 'react';
import { Container } from 'reactstrap';
// used for making the prop types of this component
import PropTypes from 'prop-types';
import {colors} from "../../theme";

// eslint-disable-next-line react/prefer-stateless-function
class Footer extends React.Component {
  render() {
    const { default: def, fluid } = this.props;
    return (
      <footer className={`footer${def ? ' footer-default' : ''}`}>
        <Container fluid={fluid}>
          <div className="float-left ml-4" style={{ color: colors.blue, fontSize: '0.984em', fontWeight: 400, fontFamily: 'Nunito Sans, sans-serif' }}>
            © {1900 + new Date().getYear()}&nbsp;<span>Marbl Platform</span>
          </div>
        </Container>
      </footer>
    );
  }
}

Footer.defaultProps = {
  default: false,
  fluid: false,
};

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
