import authService from 'lib/services/authService';

import { getJSON } from './ajax';

export function getSalesMetrics() {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  const resp = getJSON(`/api/sales-metrics/portal`, REQUEST_OPTIONS);
  return resp;
}

export default { getSalesMetrics };
