import React from 'react';
import { Container, Col } from 'reactstrap';

import image from 'assets/img';

const PaymentFailedPage = () => (
  <>
    <div className="short-info-page">
      <Container>
        <Col className="ml-auto mr-auto">
          <div>
            <p>Payment Failed!</p>
          </div>
        </Col>
      </Container>
    </div>
    <div className="full-page-background" style={{ backgroundImage: `url(${image.background})` }} />
  </>
);

export default PaymentFailedPage;
