import { postJSON } from './ajax';
import authService from '../services/authService';

export function login(data) {
  return postJSON('/api/authenticate', data);
}

export function sendEmailCode(data) {
  return postJSON('/api/password/forgotten', data);
}

export function getUsersByEmail(data) {
  return postJSON('/api/usersByEmail', data);
}

export function sendLoginСredentials(data) {
  return postJSON('/api/sendPasswordChangeCode', data);
}

export function sendLoginCode(data) {
  return postJSON('/api/sendLoginCode', data);
}

export function authenticateByCode(data) {
  return postJSON('/api/authenticateByCode', data);
}

export function validateCode(data) {
  return postJSON('/api/email/confirm', data);
}

export function resetPassword(data) {
  return postJSON('/api/password', data);
}

export function confirmByLink(data) {
  return postJSON('/api/email/confirmByLink', data);
}

export function logout() {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return postJSON('/api/logout', {}, REQUEST_OPTIONS);
}
