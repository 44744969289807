export default function shallowEqual(objA, objB) {
  let isEqual = true;

  if (!objA || !objB) {
    isEqual = false;
  }

  const aKeys = Object.keys(objA);
  const bKeys = Object.keys(objB);

  if (bKeys.length !== aKeys.length) {
    isEqual = false;
  }

  aKeys.forEach(key => {
    if (objA[key] !== objB[key] || !Object.prototype.hasOwnProperty.call(objB, key)) {
      isEqual = false;
    }
  });

  return isEqual;
}
