import authService from 'lib/services/authService';

import { postJSON, getJSON, putJSON, deleteJSON, submitMultipartForm } from './ajax';

export function editPriceBuilder(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return putJSON('/api/pricebuilder', data, REQUEST_OPTIONS);
}

export function getPriceBuilder() {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return getJSON('/api/pricebuilder', REQUEST_OPTIONS);
}

export function createPriceBookAdditionalItem(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return postJSON('/api/additionalitem', data, REQUEST_OPTIONS);
}

export function editPriceBookAdditionalItem(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return putJSON('/api/additionalitem', data, REQUEST_OPTIONS);
}

export function getPriceBookAdditionalItem() {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return getJSON('/api/additionalitem', REQUEST_OPTIONS);
}

export function deletePriceBookAdditionalItem(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return deleteJSON('/api/additionalitem', data, REQUEST_OPTIONS);
}

export function createFinancialPlan(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return postJSON('/api/financialplanitem', data, REQUEST_OPTIONS);
}

export function editFinancialPlan(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return putJSON('/api/financialplanitem', data, REQUEST_OPTIONS);
}

export function getFinancialPlans() {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return getJSON('/api/financialplanitem', REQUEST_OPTIONS);
}

export function deleteFinancialPlan(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return deleteJSON('/api/financialplanitem', data, REQUEST_OPTIONS);
}

export function editCashTerms(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return putJSON('/api/cashterms', data, REQUEST_OPTIONS);
}

export function getCashTerms() {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return getJSON('/api/cashterms', REQUEST_OPTIONS);
}

export function getCustomerProposals(
  customerId,
  skip = 0,
  take = 10000,
  orderBy = [
    {
      Field: 'CreatedAt',
      Order: 0,
    },
  ],
  operands = []
) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };

  const DATA = {
    customerId,
    filter: {
      where: {
        operatorType: 1,
        operands,
        field: null,
        filterType: 0,
        value: null,
      },
      orderBy,
      skip,
      take,
    },
  };
  return postJSON('/api/proposal/list', DATA, REQUEST_OPTIONS);
}

export function changeProposalSalesStatus(DATA) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };

  return postJSON('/api/proposal/status', DATA, REQUEST_OPTIONS);
}

export function uploadSalesAgreement(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
    method: 'POST',
  };

  return submitMultipartForm('/api/salesagreement/upload', data, REQUEST_OPTIONS);
}

export function sendProposalEmail(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };

  return postJSON('/api/salesagreement/send', data, REQUEST_OPTIONS);
}

export function deleteSalesAgreement(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };

  return deleteJSON('/api/salesagreement?', data, REQUEST_OPTIONS);
}
