import React, { useEffect } from 'react';
import { Card, Row, Col } from 'reactstrap';
import PanelHeader from 'components/PanelHeader/PanelHeader';
import { CardTitle, MarblButton } from 'components';

import { observer } from 'mobx-react';
import moment from 'moment';
import store from '../../../store';
import config from '../../../config';
import colors from '../../../theme/Color';
import { LoadingContainer } from '../../../components/LoadingContainer';

const CompanyPage = props => {
  const { companyAdminStore } = store;
  const { selectedCompany, selectedCompanyLoading } = companyAdminStore;

  useEffect(() => {
    companyAdminStore.loadSelectedCompany(props.match.params.id)
  }, []);

  const setCompanyParams = field => {
    const value = companyAdminStore.selectedCompany[field];
    companyAdminStore.editSelectedCompany({
      companyId: companyAdminStore.selectedCompany.id,
      [field]: !value,
    });
  };

  return (
    <>
      <PanelHeader size="sm" />
      {selectedCompanyLoading ? (
        <LoadingContainer />
      ) : (
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card className="card-stats card-raised">
                <Row
                  className="pt-5"
                  style={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <div
                    className="bools"
                    style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      fontSize: '0.8571em',
                      backgroundColor: selectedCompany.isActiveOnUserCount ? colors.green : colors.red,
                    }}
                  />
                  <MarblButton
                    fixedSize="180px"
                    size="lg"
                    imageClasses={`${selectedCompany.logoUrl && 'avatar-custom  avatar-shadow'}`}
                    label={selectedCompany.name}
                    url={(selectedCompany.logoUrl && `${config.imagesUrl}${selectedCompany.logoUrl}`) || null}
                    disabled
                  />

                  <div style={{ width: '250px', color: colors.gray }}>
                    <div className="p-2 m-0">
                      <Row style={{ borderTop: '1px solid #dee2e6' }}>
                        <div className="col-3 pr-1 pl-0 text-right">Id: </div>
                        <div className="col-9" style={{ fontSize: '0.8571em', color: colors.black }}>
                            {selectedCompany.shortId}
                        </div>
                      </Row>
                    </div>
                  </div>
                  <div style={{ width: '250px', color: colors.gray }}>
                    <div className="p-2 m-0">
                      <Row style={{ borderTop: '1px solid #dee2e6' }}>
                        <span className="col-3 pr-1 pl-0 text-right">Address: </span>
                        <span className="col-9" style={{ fontSize: '0.8571em', color: colors.black }}>
                            {selectedCompany.line1}, {selectedCompany.city}, {selectedCompany.state}, {selectedCompany.zip}
                        </span>
                      </Row>
                    </div>
                    <div className="p-2 m-0">
                      <Row style={{ borderTop: '1px solid #dee2e6' }}>
                        <span className="col-3 pr-1 pl-0 text-right">Phone: </span>
                        <span className="col-9" style={{ fontSize: '0.8571em', color: colors.black }}>
                          {selectedCompany.phone1 || 'is not specified'}
                        </span>
                      </Row>
                    </div>
                  </div>
                </Row>
                <Row className="p-5">
                  <Col className="pl-5" sm={12} md={6} style={{ color: colors.gray }}>
                    {/* <CardTitle style={{ color: colors.blue, fontSize: '1.1428em', fontWeight: '500' }}>Metriks</CardTitle> */}
                    {/* <Row className="pl-4 pt-1 pb-2"> */}
                    {/*  <Col className="p-0" style={{ flex: 0, alignSelf: 'center', flexBasis: '40px', fontSize: 14 }}> */}
                    {/*    YTD: */}
                    {/*  </Col> */}
                    {/*  <Col style={{ flex: 1 }}>$$$$0</Col> */}
                    {/* </Row> */}
                    {/* <Row className="pl-4 pt-1 pb-2"> */}
                    {/*  <Col className="p-0" style={{ flex: 0, alignSelf: 'center', flexBasis: '40px', fontSize: 14 }}> */}
                    {/*    MTD: */}
                    {/*  </Col> */}
                    {/*  <Col style={{ flex: 1 }}>$$$$0</Col> */}
                    {/* </Row> */}
                    {/* <Row className="pl-4 pt-1 mb-3 "> */}
                    {/*  <Col className="p-0" style={{ flex: 0, alignSelf: 'center', flexBasis: '40px', fontSize: 14 }}> */}
                    {/*    Avg: */}
                    {/*  </Col> */}
                    {/*  <Col style={{ flex: 1 }}>$$$$0</Col> */}
                    {/* </Row> */}
                    <CardTitle
                      style={{
                        color: colors.blue,
                        fontSize: '1.1428em',
                        fontWeight: '500',
                      }}
                    >
                      Company Information
                    </CardTitle>

                    <Row className="pl-4 pt-1 pb-2">
                      <Col
                        className="p-0"
                        style={{
                          flex: 0,
                          alignSelf: 'center',
                          flexBasis: '140px',
                          fontSize: '1em',
                        }}
                      >
                        Number of Users:
                      </Col>
                      <Col style={{ flex: 1, fontSize: '0.8571em', color: colors.black }}>
                        {selectedCompany.activeUsersCount}
                      </Col>
                    </Row>
                    <Row className="pl-4 pt-1 mb-3">
                      <Col
                        className="p-0"
                        style={{
                          flex: 0,
                          alignSelf: 'center',
                          flexBasis: '140px',
                          fontSize: '1em',
                        }}
                      >
                        Sign-Up :
                      </Col>
                      <Col style={{ flex: 1, fontSize: '0.8571em', color: colors.black }}>
                        {moment(selectedCompany.createdAt)
                          .utc()
                          .format('MM-DD-YYYY')}
                      </Col>
                    </Row>
                    <CardTitle
                      style={{
                        color: colors.blue,
                        fontSize: '1.2857em',
                        fontWeight: '500',
                      }}
                    >
                      Permissions
                    </CardTitle>
                    {selectedCompanyLoading ? (
                      <div>Loading...</div>
                    ) : (
                      <>
                        <Row className="pl-4 pt-1 mb-3 ">
                          <Col
                            className="p-0"
                            style={{
                              flex: 0,
                              alignSelf: 'center',
                              flexBasis: '160px',
                              fontSize: '1em',
                            }}
                          >
                            Training Completed:
                          </Col>
                          <Col style={{ flex: 1 }}>
                            <input
                              disabled={selectedCompany.isTrainingComplete}
                              checked={selectedCompany.isTrainingComplete}
                              onClick={() => setCompanyParams('isTrainingComplete')}
                              name="traini checked=active"
                              type="checkbox"
                            />
                          </Col>
                        </Row>
                        <Row className="pl-4 pt-1 mb-3 ">
                          <Col
                            className="p-0"
                            style={{
                              flex: 0,
                              alignSelf: 'center',
                              flexBasis: '160px',
                              fontSize: '1em',
                            }}
                          >
                            Deactivate Account:
                          </Col>
                          <Col style={{ flex: 1 }}>
                            <input
                              checked={!selectedCompany.isActive}
                              onClick={() => setCompanyParams('isActive')}
                              name="traini checked=active"
                              type="checkbox"
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </Col>
                  <Col className="mb-4" sm={12} md={6}>
                    {/* <Card className="card-chart"> */}
                    {/*  <CardHeader> */}
                    {/*    <h5 className="card-category">Active Users</h5> */}
                    {/*    <CardTitle tag="h2">34,252</CardTitle> */}
                    {/*    <UncontrolledDropdown> */}
                    {/*      <DropdownToggle className="btn-round btn-icon" color="default" outline> */}
                    {/*        <i className="now-ui-icons arrows-1_minimal-down" /> */}
                    {/*      </DropdownToggle> */}
                    {/*      <DropdownMenu right> */}
                    {/*        <DropdownItem>MTD</DropdownItem> */}
                    {/*        <DropdownItem>YTD</DropdownItem> */}
                    {/*        <DropdownItem>AVG</DropdownItem> */}
                    {/*      </DropdownMenu> */}
                    {/*    </UncontrolledDropdown> */}
                    {/*  </CardHeader> */}
                    {/*  <CardBody> */}
                    {/*    <div className="chart-area"> */}
                    {/*      /!* dashboardActiveUsersChart -red *!/ */}
                    {/*      /!* chartsLine1  - red *!/ */}
                    {/*      /!* chartsLine2  - green *!/ */}
                    {/*      /!* dashboardActiveCountriesCard - blue *!/ */}
                    {/*      <Line data={dashboardActiveCountriesCard.data} options={dashboardActiveUsersChart.options} /> */}
                    {/*    </div> */}
                    {/*  </CardBody> */}
                    {/*  <CardFooter> */}
                    {/*    <div className="stats"> */}
                    {/*      <i className="now-ui-icons arrows-1_refresh-69" /> */}
                    {/*      Just Updated */}
                    {/*    </div> */}
                    {/*  </CardFooter> */}
                    {/* </Card> */}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default observer(CompanyPage);
