import React from 'react';
import { CardTitle as RCardTitle } from 'reactstrap';
import { colors } from 'theme';

const CardTitle = ({ children, ...props }) => {
  return (
    <RCardTitle style={{ color: colors.blue, fontWeight: 700 }} {...props}>
      {children}
    </RCardTitle>
  );
};

export default CardTitle;
