import React from 'react';
import PropTypes from 'prop-types';
// reactstrap components
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Container } from 'reactstrap';

class AdminHeader extends React.Component {
  state = {
    isOpen: false,
    dropdownOpen: false,
    color: 'transparent',
  };

  sidebarToggle = React.createRef();

  componentDidMount() {
    window.addEventListener('resize', this.updateColor.bind(this));
  }

  componentDidUpdate(e) {
    if (window.innerWidth < 993 && e.history.location.pathname !== e.location.pathname && document.documentElement.className.indexOf('nav-open') !== -1) {
      document.documentElement.classList.toggle('nav-open');
      this.sidebarToggle.current.classList.toggle('toggled');
    }
  }

  toggle = () => {
    const { isOpen } = this.state;
    if (isOpen) {
      this.setState({ color: 'transparent' });
    } else {
      this.setState({ color: 'white' });
    }
    this.setState(prev => ({ isOpen: !prev.isOpen }));
  };

  dropdownToggle = () => {
    this.setState(prev => ({ dropdownOpen: !prev.dropdownOpen }));
  };

  openSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
    this.sidebarToggle.current.classList.toggle('toggled');
  };

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    const { isOpen } = this.state;
    if (window.innerWidth < 993 && isOpen) {
      this.setState({ color: 'white' });
    } else {
      this.setState({ color: 'transparent' });
    }
  };

  render() {
    const { color, isOpen } = this.state;
    const { brandText } = this.props;
    return (
      // add or remove classes depending if we are on full-screen-maps page or not
      <Navbar
        color={window.location.href.indexOf('full-screen-maps') !== -1 ? 'white' : color}
        light
        expand="md"
        className={window.location.href.indexOf('full-screen-maps') !== -1 ? 'navbar-absolute ' : `navbar-absolute ${color === 'transparent' ? 'navbar-transparent ' : ''}`}
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button type="button" ref={this.sidebarToggle} className="navbar-toggler" onClick={() => this.openSidebar()}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="/">{brandText}</NavbarBrand>
          </div>
          <NavbarToggler onClick={this.toggle}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar className="justify-content-end" />
        </Container>
      </Navbar>
    );
  }
}

AdminHeader.defaultProps = {
  brandText: 'Default Brand Text',
};

AdminHeader.propTypes = {
  // string for the page name
  brandText: PropTypes.string,
};

export default AdminHeader;
