import React from 'react';
import { observer } from 'mobx-react';

// reactstrap components
import { Card, CardBody, Row, Col, Button, FormGroup, Input, Label, Alert, Collapse } from 'reactstrap';

// core components
import PanelHeader from 'components/PanelHeader/PanelHeader';
import ImageUpload from 'components/CustomUpload/ImageUpload';
import { LabelInput, CardTitle, StateDropdown, Label as LabelWrapper } from 'components';

import { colors } from 'theme';
import { NUMBER_REG_EX, EMAIL_REG_EX } from 'utils/regularExpressions';
import { getUser } from 'lib/api/userApi';
import authService from 'lib/services/authService';
import {
  checkCompanyAdminAccountId,
  editCompanyDetails,
  editCompanyContract,
  editCompanyPhoto,
  editCompanyLogo,
} from 'lib/api/companyApi';
import { stateList as STATE_LIST } from 'variables/general';
import { parseJwt, validURL } from '../../utils/helpers';
import { changePassword } from '../../lib/api/userApi';
import config from '../../config';
import store from '../../store';
import { postJSON } from '../../lib/api/ajax';

export async function updateSettings(isRegister, data, userData, uploadData) {
  if (isRegister) {
    const adminCheck = await checkCompanyAdminAccountId(userData.userName);

    if (adminCheck.isUnique) {
      // const resp = await createCompanyDetails(data);
      const resp = await postJSON(
        '/api/v2/registration',
        {
          registartionV2DtoModel: {
            ...userData,
            ...data,
            avatarFile: uploadData?.avatarFile ? uploadData.avatarFile : null,
          },
        },
        { method: 'POST' }
      );
      const { token, salesmanPassword } = resp;
      const { roles } = parseJwt(resp.token);
      authService.setUser({
        username: userData.userName,
        token,
        roles,
      });

      const { companyAdmin, salesman } = await getUser();

      authService.setUser({
        token,
        roles,
        username: userData.userName,
        email: userData.email,
        ...companyAdmin,
        salesman,
      });
      let alertString = salesmanPassword
        ? `Salesman password: ${salesmanPassword}`
        : 'Registration completed successfully';

      alertString += `\nUse this email for Stripe: ${userData.email}`;
      alert(alertString);
    } else {
      throw new Error('Username is not unique');
    }
    return;
  }
  const listPromise = [editCompanyDetails({ ...data, companyId: userData.companyId })];
  if (userData.password) {
    listPromise.push(changePassword(userData.password));
  }
  if (uploadData.contractSecondPageFile) {
    listPromise.push(editCompanyContract({ contractFile: uploadData.contractSecondPageFile }));
  }

  if (uploadData.logoFile) {
    listPromise.push(editCompanyLogo({ logoFile: uploadData.logoFile }));
  }
  if (uploadData.photoFile) {
    listPromise.push(editCompanyPhoto({ photoFile: uploadData.photoFile }));
  }
  // await editUser(userData).then(async ({ salesman }) => {
  //   if (uploadData.avatarFile) {
  //     listPromise.push(
  //       editSalespersonAvatar({ avatarFile: uploadData.avatarFile, salesmanId: salesman ? salesman.id : '' })
  //     );
  //     listPromise.push(getUser());
  //   }
  // });
  await Promise.all(listPromise);
}

class Settings extends React.Component {
  constructor(props) {
    super(props);

    const stateList = store.settings.stateList?.length > 0 ? store.settings.stateList : STATE_LIST;

    this.state = {
      isRegister: props.isRegister,
      isLoading: false,
      companyId: null,
      errorMessage: '',
      redHint: false,
      company: {},
      username: '',
      salesForm: false,
      salesOptions: {
        avatarFileError: '',
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        emailError: '',
        nameError: '',
        usernameError: '',
        salesmanFirstNameError: '',
        phone: '',
        phoneError: '',
      },
      upload: {
        contractSecondPageFileName: '',
        contractSecondPageFile: null,
        contractSecondPageFileState: '',
        contractSecondPageUrl: '',
        logoFile: null,
        logoFileUrl: '',
        photoFile: null,
        photoFileUrl: '',
        avatarFile: null,
        avatarUrl: '',
      },
      customerFacing: {
        companyName: '',
        companyAddress: '',
        companyCity: '',
        companyState: '',
        companyZip: '',
        companyWebsite: '',
        companyWebsiteState: '',
      },
      webportal: {
        username: '',
        usernameState: '',
        password: '',
        confirmPassword: '',
        passwordState: '',
        adminEmail: '',
        adminEmailState: '',
        contractEmail: '',
        contractEmailState: '',
        inviteAdminEmail: '',
        inviteAdminEmailState: '',
        SMTPDomain: '',
        SMTPDomainState: '',
      },
      numbers: {
        companyPhone: '',
        companyPhoneState: '',
        proposalLifespanDays: 30,
        proposalLifespanDaysState: '',
        overdueProposalLifespanMonth: 3,
        overdueProposalLifespanMonthState: '',
        SMTPPort: '',
        SMTPPortState: '',
        salesAgreementLifespanDays: 30,
        salesAgreementLifespanDaysState: '',
      },
      proposal: {
        installationDescription: '',
        installationDescriptionState: '',
        warrantyInformation: '',
        warrantyInformationState: '',
      },
      useCustomSmtp: false,
      enableSSL: false,
      isEmailInfoOpen: false,
      stateList,
    };

    this.file = React.createRef();
  }

  componentDidMount() {
    const { states, fetchStates } = store.settings;
    if (!states?.length) {
      fetchStates(null, {
        done: () => {
          this.setState({ stateList: store.settings.stateList });
        },
      });
    }
    // eslint-disable-next-line react/destructuring-assignment
    if (!this.props.isRegister) {
      const { data: company, fetchCompany } = store.company;
      if (!company?.id?.length) {
        fetchCompany(null, { done: this.fetchSettings });
        return;
      }
      this.fetchSettings();
    }
  }

  // // // to understand the bellow functions please take a look at the end of the document as well
  // this handles the onFocus event over the normal / visible Inputs (reactstrap components)
  // that will trigger the on click of the non-visible inputs (normal html component)
  handleFileInput = (e, type) => {
    this[type].current.click(e);
  };

  // this is the function triggered for the file type input
  // it will store inside the components state the names of the files and the files
  // after that, if you want to save the files you need to add a special on submit function for that
  // we haven't since we do not need to save the files - everything in our product is only front-end
  addFile = (e, type, fieldName) => {
    let fileNames = '';
    const { files } = e.target;
    const isMultiSelect = type === 'multipleFile';
    for (let i = 0; i < e.target.files.length; i += 1) {
      fileNames += e.target.files[i].name;
      if (isMultiSelect && i !== e.target.files.length - 1) {
        fileNames += ', ';
      }
    }

    if (!isMultiSelect && files[0].size > 10485760) {
      this.setState(prevState => ({
        upload: { ...prevState.upload, [`${fieldName}State`]: 'File size is larger than allowed' },
      }));
      return;
    }

    const upload = {
      [`${fieldName}Name`]: fileNames,
      [fieldName]: isMultiSelect ? files : files[0],
    };
    this.setState(prevState => ({ upload: { ...prevState.upload, ...upload } }));
  };

  passTest = value => {
    let passChecks = 0;
    passChecks += /[a-z]/.test(value) ? 1 : 0;
    passChecks += /[A-Z]/.test(value) ? 1 : 0;
    passChecks += /\d/.test(value) ? 1 : 0;
    passChecks += /[^\w\d\s]/.test(value) ? 1 : 0;
    return passChecks > 3;
  };

  validatePassword = () => {
    const {
      webportal: { password, confirmPassword },
      isRegister,
    } = this.state;

    if (isRegister ? password && confirmPassword : password !== '' && password === confirmPassword) {
      if (password.length < 8) {
        this.setState({ redHint: true });
        return 'has-danger';
      }
      if (!this.passTest(password) || /[\s]/.test(password)) {
        this.setState({ redHint: true });
        return 'has-danger';
      }
      if (password !== confirmPassword) {
        return 'has-danger';
      }
      return '';
    }
    if (!isRegister && password === '' && confirmPassword === '') {
      return '';
    }

    return 'has-danger';
  };

  validateFields = () => {
    const { customerFacing, webportal, numbers, useCustomSmtp } = this.state;

    customerFacing.companyNameState = customerFacing.companyName ? '' : 'has-danger';
    customerFacing.companyAddressState = customerFacing.companyAddress ? '' : 'has-danger';
    customerFacing.companyCityState = customerFacing.companyCity ? '' : 'has-danger';
    customerFacing.companyStateState = customerFacing.companyState ? '' : 'has-danger';
    customerFacing.companyZipState = customerFacing.companyZip ? '' : 'has-danger';
    webportal.SMTPDomainState = '';
    numbers.SMTPPortState = '';
    numbers.companyPhoneState = numbers.companyPhone && NUMBER_REG_EX.test(numbers.companyPhone) ? '' : 'has-danger';
    webportal.usernameState = webportal.username ? '' : 'has-danger';
    webportal.passwordState = this.validatePassword();
    webportal.adminEmailState = webportal.adminEmail && EMAIL_REG_EX.test(webportal.adminEmail) ? '' : 'has-danger';
    if (useCustomSmtp) {
      webportal.SMTPDomainState = webportal.SMTPDomain ? '' : 'has-danger';
      numbers.SMTPPortState = numbers.SMTPPort ? '' : 'has-danger';
    }

    if (validURL(customerFacing.companyWebsite)) {
      customerFacing.companyWebsiteState = customerFacing.companyWebsite ? '' : 'has-danger';
    } else {
      customerFacing.companyWebsiteState = 'has-danger';
    }

    this.setState({
      customerFacing,
      webportal,
      numbers,
    });

    if (
      customerFacing.companyNameState === 'has-danger' ||
      customerFacing.companyAddressState === 'has-danger' ||
      customerFacing.companyCityState === 'has-danger' ||
      customerFacing.companyStateState === 'has-danger' ||
      customerFacing.companyZipState === 'has-danger' ||
      customerFacing.companyWebsiteState === 'has-danger' ||
      numbers.companyPhoneState === 'has-danger' ||
      numbers.proposalLifespanDays === 'has-danger' ||
      numbers.overdueProposalLifespanMonth === 'has-danger' ||
      numbers.SMTPPortState === 'has-danger' ||
      webportal.usernameState === 'has-danger' ||
      webportal.passwordState === 'has-danger' ||
      webportal.adminEmailState === 'has-danger' ||
      webportal.contractEmailState === 'has-danger' ||
      webportal.SMTPDomainState === 'has-danger'
    ) {
      return false;
    }

    return true;
  };

  fetchSettings = async () => {
    const { data: company } = store.company;
    const { username, email, salesman } = authService.getUser();
    this.setState(prevState => ({
      company,
      proposal: {
        ...prevState.proposal,
        installationDescription: company.installationDescription,
        warrantyInformation: company.warrantyInformation,
      },
      customerFacing: {
        ...prevState.customerFacing,
        companyName: company.name,
        companyAddress: company.line1,
        companyCity: company.city,
        companyState: company.state,
        companyZip: company.zip,
        companyWebsite: company.contactWebsite,
      },
      webportal: {
        ...prevState.webportal,
        contractEmail: company.contactEmail,
        username,
        adminEmail: email,
        SMTPDomain: company.smtpOptions?.smtpServer || '',
      },
      numbers: {
        ...prevState.numbers,
        companyPhone: company.phone1,
        proposalLifespanDays: company.proposalLifespanDays,
        overdueProposalLifespanMonth: company.overdueProposalLifespanMonth,
        SMTPPort: company.smtpOptions?.port || '',
        salesAgreementLifespanDays: company.salesAgreementLifespanDays || 30,
      },
      upload: {
        ...prevState.upload,
        contractSecondPageUrl: company.contractSecondPageUrl,
        logoFileUrl: company.logoUrl,
        photoFileUrl: company.photoUrl,
        avatarUrl: salesman?.avatarUrl,
      },
      salesForm: !!salesman,
      salesOptions: {
        ...prevState.salesOptions,
        firstName: salesman?.firstName,
        phone: salesman?.phone,
      },
      useCustomSmtp: company.useCustomSmtp || false,
      enableSSL: company.smtpOptions?.useSsl || false,
    }));
  };

  createOrEditCompanyDetails = () => {
    const {
      upload,
      customerFacing,
      webportal,
      numbers,
      proposal,
      isRegister,
      company,
      salesForm,
      salesOptions,
      useCustomSmtp,
      enableSSL,
    } = this.state;
    const {
      proposalLifespanDays,
      overdueProposalLifespanMonth,
      companyPhone,
      SMTPPort,
      salesAgreementLifespanDays,
    } = numbers;
    const { contractEmail, username, password, adminEmail, SMTPDomain } = webportal;
    const { installationDescription, warrantyInformation } = proposal;
    const { contractSecondPageUrl, logoFileUrl, photoFileUrl } = upload;

    const data = {
      name: customerFacing.companyName,
      displayName: customerFacing.companyName,
      line1: customerFacing.companyAddress,
      city: customerFacing.companyCity,
      state: customerFacing.companyState,
      zip: customerFacing.companyZip,
      contactEmail: contractEmail,
      contactWebsite: customerFacing.companyWebsite,
      phone1: companyPhone,
      proposalLifespanDays,
      overdueProposalLifespanMonth,
      installationDescription,
      warrantyInformation,
      logoUrl: logoFileUrl,
      photoUrl: photoFileUrl,
      contractSecondPageUrl,
      avatarUrl: salesOptions.avatarUrl,
      useCustomSmtp,
      salesAgreementLifespanDays,
      smtpOptions: useCustomSmtp
        ? {
          smtpServer: SMTPDomain,
          port: SMTPPort,
          useSsl: enableSSL,
        }
        : null,
    };

    const userData = {
      companyId: company.id,
      userName: username,
      email: adminEmail,
      password,
      isSalesmanToo: salesForm,
      // salesmanName: salesOptions.name,
      salesmanPhone: salesOptions.phone,
      salesmanFirstName: salesOptions.firstName,
      salesmanLastName: salesOptions.lastName,
      salesmanUserName: salesForm ? username : salesOptions.username,
      salesmanEmail: salesForm ? adminEmail : salesOptions.email,
    };

    this.setState({
      isLoading: true,
      errorMessage: '',
    });
    return updateSettings(isRegister, data, userData, upload)
      .then(() => {
        const { history } = this.props;
        return isRegister
          ? history.push({ pathname: '/admin/billing-Info', state: { isRegister } })
          : window.location.reload();
      })
      .catch(error => {
        this.setState({
          isLoading: false,
          errorMessage: error.responseText || error.toString() || '',
        });
      });
  };

  onSubmit = event => {
    event.preventDefault();
    if (!this.validateFields()) {
      this.setState({ errorMessage: 'Check required fields' });
      return false;
    }
    this.createOrEditCompanyDetails();
    return true;
  };

  doubleLabelInput = (id, value, className, firstLabel, secondLabel) => (
    <FormGroup className={`col-md-4 has-label ${className}`}>
      <Label style={{ color: colors.blue }} for={id}>
        {firstLabel}
      </Label>
      <Row>
        <Col xs={12} sm={9}>
          <FormGroup>
            <Input
              type="text"
              id={id}
              value={value}
              onChange={e => this.typeNumber(e, id)}
              style={className === 'has-danger' ? {} : { borderColor: colors.blue }}
            />
          </FormGroup>
        </Col>
        <Label style={{ color: colors.blue }} sm={3} className="label-on-right">
          {secondLabel}
        </Label>
      </Row>
    </FormGroup>
  );

  typeImage = name => value => {
    this.setState(prevState => ({
      upload: { ...prevState.upload, [name]: value, [`${name}Url`]: '' },
    }));
  };

  typePassword = ({ target: { value } }) => {
    const { webportal } = this.state;
    webportal.password = value;
    webportal.passwordState = value === webportal.confirmPassword ? 'has-success' : 'has-danger';
    this.setState({ webportal, redHint: !this.passTest(value) });
  };

  typeConfirmPassword = ({ target: { value } }) => {
    const { webportal } = this.state;
    webportal.confirmPassword = value;
    webportal.passwordState = webportal.password === value ? 'has-success' : 'has-danger';
    this.setState({ webportal });
  };

  mailContactsBlur = () => {
    let stateClass = false;
    const { webportal } = this.state;
    const web = { ...webportal };
    const value = web.contractEmail;
    if (value.includes(',')) {
      let parts = value.split(',');
      parts = parts.filter(mail => mail);
      parts = parts.map(mail => mail.trim());
      stateClass = parts.every(mail => EMAIL_REG_EX.test(mail));
      web.contractEmail = parts.length > 0 && parts.join(', ');
    } else {
      stateClass = EMAIL_REG_EX.test(value);
    }
    web.contractEmailState = stateClass ? 'has-success' : 'has-danger';
    this.setState({ webportal: web });
  };

  typeEmail(e, field) {
    const { webportal } = this.state;
    webportal[field] = e.target.value;
    if (EMAIL_REG_EX.test(e.target.value)) {
      webportal[`${field}State`] = 'has-success';
    } else {
      webportal[`${field}State`] = 'has-danger';
    }
    this.setState({ webportal });
  }

  typeEmailContacts({ target: { value } }) {
    const { webportal } = this.state;
    const web = { ...webportal };
    web.contractEmailState = 'has-success';
    web.contractEmail = value;
    this.setState({ webportal: web });
  }

  typeUrl(e) {
    const { customerFacing } = this.state;
    customerFacing.companyWebsite = e.target.value;
    if (validURL(customerFacing.companyWebsite)) {
      customerFacing.companyWebsiteState = 'has-success';
    } else {
      customerFacing.companyWebsiteState = 'has-danger';
    }
    this.setState({ customerFacing });
  }

  typeNumber(e, field) {
    const { numbers } = this.state;
    numbers[field] = e.target.value;
    if (NUMBER_REG_EX.test(numbers[field])) {
      numbers[`${field}State`] = 'has-success';
    } else {
      numbers[`${field}State`] = numbers[field] ? 'has-danger' : '';
    }
    this.setState({ numbers });
  }

  typeInput(e, field, groupName, required) {
    const group = this.state[groupName];
    group[field] = e.target.value;
    if (group[field]) {
      group[`${field}State`] = 'has-success';
    } else {
      group[`${field}State`] = required ? 'has-danger' : '';
    }
    this.setState({ group });
  }

  selectState(stateIndex, field, groupName) {
    const { stateList } = this.state;
    const group = this.state[groupName];
    group[field] = stateList[stateIndex];
    this.setState({ group });
  }

  changeSaleOptForm({ target: { value } }, field) {
    const { salesOptions } = this.state;
    const salesOpt = { ...salesOptions };
    if (field === 'phone') {
      salesOpt.phoneError = NUMBER_REG_EX.test(value) ? 'has-success' : 'has-danger';
      salesOpt.phone = value;
      this.setState({ salesOptions: salesOpt });
      return;
    }
    if (field === 'lastName') {
      salesOpt.lastName = value;
      this.setState({ salesOptions: salesOpt });
      return;
    }
    if (field === 'email') {
      salesOpt.emailError = EMAIL_REG_EX.test(value) ? 'has-success' : 'has-danger';
      salesOpt.email = value;
      this.setState({ salesOptions: salesOpt });
      return;
    }
    salesOpt[`${field}Error`] = value ? 'has-success' : 'has-danger';
    salesOpt[field] = value;
    this.setState({ salesOptions: salesOpt });
  }

  render() {
    const {
      upload,
      customerFacing,
      webportal,
      numbers,
      company,
      proposal,
      isRegister,
      isLoading,
      errorMessage,
      redHint,
      salesForm,
      salesOptions,
      useCustomSmtp,
      isEmailInfoOpen,
      enableSSL,
      stateList,
    } = this.state;
    const {
      contractSecondPageFileName,
      contractSecondPageUrl,
      contractSecondPageFileState,
      logoFileUrl,
      logoFile,
      photoFileUrl,
      photoFile,
    } = upload;
    const {
      companyNameState,
      companyAddressState,
      companyWebsiteState,
      companyCityState,
      companyZipState,
    } = customerFacing;
    const {
      username,
      usernameState,
      adminEmail,
      adminEmailState,
      contractEmailState,
      inviteAdminEmailState,
      passwordState,
      SMTPDomain,
      SMTPDomainState,
    } = webportal;
    const {
      companyPhoneState,
      proposalLifespanDays,
      salesAgreementLifespanDays,
      salesAgreementLifespanDaysState,
      proposalLifespanDaysState,
      overdueProposalLifespanMonth,
      overdueProposalLifespanMonthState,
      SMTPPort,
      SMTPPortState,
    } = numbers;
    const { installationDescription, warrantyInformation } = proposal;

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card className="card-stats card-raised">
                <form onSubmit={this.onSubmit}>
                  <CardBody style={{ padding: '2vw' }}>
                    {!isRegister && [
                      <CardTitle key="1vw">Upload Logo</CardTitle>,
                      <ImageUpload
                        key="2vw"
                        imagePreviewUrl={logoFileUrl ? `${config.imagesUrl}${logoFileUrl}` : ''}
                        file={logoFile}
                        ext={['png', 'jpg']}
                        maxSize={5}
                        onChange={this.typeImage('logoFile')}
                      />,
                      <CardTitle key="3vw">Upload Company Photo</CardTitle>,
                      <ImageUpload
                        key="4vw"
                        imagePreviewUrl={photoFileUrl ? `${config.imagesUrl}${photoFileUrl}` : ''}
                        ext={['png', 'jpg']}
                        maxSize={5}
                        file={photoFile}
                        onChange={this.typeImage('photoFile')}
                      />,
                      <div key="5vw" className="form-row">
                        <FormGroup className="col-md-6">
                          <CardTitle>Upload Contract Second Page as PDF</CardTitle>
                          <FormGroup className="form-file-upload form-file-simple">
                            <Input
                              type="text"
                              className="inputFileVisible"
                              placeholder="Choose your file..."
                              onClick={e => this.handleFileInput(e, 'file')}
                              defaultValue={contractSecondPageFileName}
                            />
                            <input
                              type="file"
                              className="inputFileHidden"
                              style={{ zIndex: -1 }}
                              ref={this.file}
                              onChange={e => this.addFile(e, 'file', 'contractSecondPageFile')}
                            />
                            {contractSecondPageUrl && (
                              <a
                                style={{ color: colors.blue }}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${config.imagesUrl}${contractSecondPageUrl}`}
                              >
                                Download contract file
                              </a>
                            )}

                            {contractSecondPageFileState && (
                              <p style={{ color: colors.red }}>{contractSecondPageFileState}</p>
                            )}
                          </FormGroup>
                        </FormGroup>
                      </div>,
                    ]}
                    <br />
                    <CardTitle>
                      {isRegister ? 'Step 1. Enter your Company Information' : 'Your Company Information'}
                    </CardTitle>
                    {company && company.shortId && (
                      <Label className="mb-3 ml-2">
                        <span style={{ color: colors.blue }}>
                          Company ID: <span style={{ color: colors.black }}> {company.shortId} </span>
                        </span>
                      </Label>
                    )}
                    <div className="form-row">
                      <LabelInput
                        className={`col-md-6 has-label ${companyNameState}`}
                        label="Company Name"
                        id="companyName"
                        placeholder="Company Name"
                        validationClass={companyNameState}
                        value={customerFacing.companyName}
                        onChange={e => this.typeInput(e, 'companyName', 'customerFacing', true)}
                        required
                      />
                    </div>
                    <div className="form-row">
                      <LabelInput
                        className={`col-md-5 has-label ${companyAddressState}`}
                        label="Company Address"
                        id="companyAddress"
                        placeholder="1234 Main St"
                        autoComplete="off"
                        value={customerFacing.companyAddress}
                        onChange={e => this.typeInput(e, 'companyAddress', 'customerFacing', true)}
                        validationClass={companyAddressState}
                        required
                      />
                      <LabelInput
                        className={`col-md-2 has-label ${companyCityState}`}
                        label="Company City"
                        id="companyCity"
                        placeholder=""
                        autoComplete="off"
                        value={customerFacing.companyCity}
                        onChange={e => this.typeInput(e, 'companyCity', 'customerFacing', true)}
                        validationClass={companyCityState}
                        required
                      />
                      <LabelInput
                        className={`col-md-2 has-label ${customerFacing.companyZipState}`}
                        label="Company ZIP"
                        id="companyZip"
                        placeholder=""
                        autoComplete="off"
                        value={customerFacing.companyZip}
                        onChange={e => this.typeInput(e, 'companyZip', 'customerFacing', true)}
                        validationClass={companyZipState}
                        required
                      />
                      <LabelWrapper
                        className={`col-md-2 has-label ${customerFacing.companyState}`}
                        label="State"
                        id="state"
                        required
                      >
                        <StateDropdown
                          state={customerFacing.companyState || 'Select state'}
                          stateList={stateList}
                          toggleStyle={{ maxWidth: '300px', marginTop: '-2px' }}
                          onChange={index => this.selectState(index, 'companyState', 'customerFacing')}
                        />
                      </LabelWrapper>
                    </div>
                    <div className="form-row">
                      <LabelInput
                        className={`col-md-6 has-label ${companyPhoneState}`}
                        label="Company Phone"
                        id="companyPhone"
                        value={numbers.companyPhone}
                        onChange={e => this.typeNumber(e, 'companyPhone')}
                        validationClass={companyPhoneState}
                        required
                      />
                      <LabelInput
                        className={`col-md-6 has-label ${companyWebsiteState}`}
                        label="Company Website"
                        id="companyWebsite"
                        placeholder="www.website.com"
                        autoComplete="off"
                        value={customerFacing.companyWebsite}
                        onChange={e => this.typeUrl(e)}
                        validationClass={companyWebsiteState}
                        required
                      />
                    </div>

                    <br />

                    <CardTitle>
                      {isRegister
                        ? 'Step 2. Create your Administrator Account (Used Only for Account Management)'
                        : 'Your Administrator Account (Used Only for Account Management)'}
                    </CardTitle>
                    <div className="form-row">
                      <LabelInput
                        className={`col-md-4 has-label ${usernameState}`}
                        label="Username"
                        id="username"
                        placeholder=""
                        onChange={e => this.typeInput(e, 'username', 'webportal', true)}
                        value={username}
                        validationClass={usernameState}
                        required
                        disabled={!isRegister}
                      />
                      <div className="custom-col-container col-md-8 ">
                        <LabelInput
                          className={`custom-col-pass left-col col-md-4 has-label ${passwordState}`}
                          label="Password"
                          id="password"
                          placeholder="Password"
                          autoComplete="new-password"
                          type="password"
                          onChange={this.typePassword}
                          validationClass={passwordState}
                          required
                        />
                        <LabelInput
                          className={`custom-col-pass col-md-4 has-label ${passwordState}`}
                          label="Confirm Password"
                          id="confirmPassword"
                          placeholder="Confirm Password"
                          autoComplete="off"
                          type="password"
                          onChange={this.typeConfirmPassword}
                          validationClass={passwordState}
                          required
                        />
                        <p style={{ fontSize: '0.7143em', color: redHint ? colors.red : colors.gray }}>
                          <span>
                            Password must contain at least 8 characters, have upper case and lower case letters, number,
                            special character
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="form-row">
                      <LabelInput
                        className={`col-md-6 has-label ${adminEmailState}`}
                        label="Admin Email Address"
                        id="adminEmail"
                        autoComplete="off"
                        onChange={e => this.typeEmail(e, 'adminEmail')}
                        value={adminEmail}
                        type="email"
                        validationClass={adminEmailState}
                        required
                      />
                      <div className={`col-md-6 has-label ${contractEmailState}`}>
                        <LabelInput
                          // className={`col-md-6 has-label ${contractEmailState}`}
                          label="Contract Email Recipients"
                          id="contractEmailState"
                          autoComplete="off"
                          value={webportal.contractEmail}
                          onChange={e => this.typeEmailContacts(e)}
                          onBlur={this.mailContactsBlur}
                          type="text"
                          required
                        />
                        <p style={{ fontSize: '0.7143em', color: colors.gray }}>
                          <span>When you send a proposal through Marbl, this email will always be copied</span>
                        </p>
                      </div>
                    </div>
                    {isRegister && (
                      <>
                        <CardTitle>Step 3. Create your first Salesperson Account (Used for App)</CardTitle>
                        <Row className="pl-3 mt-3">
                          <Label style={{ color: colors.blue }}>
                            I will also use Administrator Account credentials as a Salesperson:
                          </Label>
                          <Col style={{ flex: 1, marginLeft: '0.7143em' }}>
                            <Input
                              type="checkbox"
                              checked={salesForm}
                              onClick={e => this.setState(prev => ({ salesForm: !prev.salesForm }))}
                            />
                          </Col>
                        </Row>
                        <Row className="pl-3 mt-3">
                          <Col
                            className="mt-3"
                            md={2}
                            style={{
                              paddingRight: '50px',
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <ImageUpload
                              className="pull-right mr-3"
                              imagePreviewUrl={upload.avatarUrl ? `${config.imagesUrl}${upload.avatarUrl}` : ''}
                              file={upload.avatarFile}
                              ext={['png', 'jpg']}
                              maxSize={5}
                              onChange={this.typeImage('avatarFile')}
                            />
                            <br />
                          </Col>
                          <Col md={4}>
                            <div className="form-row">
                              <LabelInput
                                className={`w-100 has-label ${salesOptions.firstNameError}`}
                                label="Salesman First Name"
                                id="firstName"
                                onChange={e => this.changeSaleOptForm(e, 'firstName')}
                                value={salesOptions.firstName}
                                validationClass={salesOptions.firstNameError}
                                required
                              />
                            </div>
                            <div className="form-row">
                              <LabelInput
                                className="w-100 has-label"
                                label="Salesman Last Name"
                                id="lastName"
                                onChange={e => this.changeSaleOptForm(e, 'lastName')}
                                value={salesOptions.lastName}
                              />
                            </div>
                            <div className="form-row">
                              <LabelInput
                                className={`w-100 has-label ${salesOptions.usernameError}`}
                                label="Username"
                                id="username"
                                onChange={e => this.changeSaleOptForm(e, 'username')}
                                value={salesForm ? username : salesOptions.username}
                                validationClass={salesOptions.usernameError}
                                disabled={salesForm}
                                required
                              />
                            </div>
                            <div className="form-row">
                              <LabelInput
                                className={`w-100 has-label ${salesOptions.phoneError}`}
                                label="Phone Number"
                                id="phone"
                                onChange={e => this.changeSaleOptForm(e, 'phone')}
                                validationClass={salesOptions.phoneError}
                                value={salesOptions.phone}
                                required
                              />
                            </div>
                            <div className="form-row">
                              <LabelInput
                                className={`w-100 has-label ${salesOptions.emailError}`}
                                label="Email"
                                id="email"
                                disabled={salesForm}
                                onChange={e => this.changeSaleOptForm(e, 'email')}
                                validationClass={salesOptions.emailError}
                                value={salesForm ? adminEmail : salesOptions.email}
                                required
                              />
                            </div>
                          </Col>
                        </Row>
                        <br />
                      </>
                    )}
                    <div className="col-md-8 form-group">
                      <div
                        className="form-control"
                        style={{
                          borderColor: useCustomSmtp ? colors.blue : colors.gray,
                          padding: 10,
                          paddingLeft: 40,
                          paddingRight: 20,
                          cursor: 'default',
                          overflow: 'hidden',
                        }}
                        disabled={!useCustomSmtp}
                      >
                        <div style={{ display: 'flex' }}>
                          <Input
                            type="radio"
                            color={colors.blue}
                            style={{ marginTop: 2, color: colors.blue }}
                            checked={useCustomSmtp}
                            onClick={() => {
                              this.setState({ useCustomSmtp: true });
                            }}
                          />
                          <Label
                            style={{
                              color: useCustomSmtp ? colors.blue : colors.gray,
                              fontSize: '1.14em',
                              flex: 1,
                              fontWeight: 700,
                            }}
                          >
                            Connect your email server to send proposals directly from your salesperson's email address
                          </Label>
                        </div>
                        <p style={{ fontSize: '0.7143em', color: colors.red }}>
                          <span>All salespeople should be on the same domain</span>
                        </p>
                        <Button
                          onClick={() => this.setState({ isEmailInfoOpen: !isEmailInfoOpen })}
                          className="btn-icon btn-round"
                          color="info"
                          size="sm"
                          style={{ backgroundColor: 'transparent', marginTop: '2px', marginRight: '5px' }}
                        >
                          <i className="fa fa-question" style={{ color: colors.blue }} />
                        </Button>
                        <Label
                          style={{
                            color: useCustomSmtp ? colors.blue : colors.gray,
                            fontSize: '1.14em',
                            flex: 1,
                            fontWeight: 700,
                          }}
                        >
                          What you'll need:
                        </Label>

                        <>
                          <Collapse isOpen={isEmailInfoOpen}>
                            <div className="form-control" style={{ backgroundColor: colors.white }}>
                              <Label
                                style={{
                                  color: colors.blue,
                                  fontSize: '1em',
                                  flex: 1,
                                  fontWeight: 700,
                                }}
                              >
                                Company admin smtp setup
                              </Label>
                              <p
                                style={{
                                  color: colors.gray,
                                  fontSize: '0.8571em',
                                  flex: 1,
                                  fontWeight: 500,
                                }}
                              >
                                To use google smtp use next options:
                                <br />
                                Smtp server: smtp.gmail.com
                                <br />
                                Smtp port: 587
                                <br />
                                Use SSL: yes
                              </p>
                              <Label
                                style={{
                                  color: colors.blue,
                                  fontSize: '1em',
                                  flex: 1,
                                  fontWeight: 700,
                                }}
                              >
                                Salesman setup
                              </Label>
                              <p
                                style={{
                                  color: colors.gray,
                                  fontSize: '0.8571em',
                                  flex: 1,
                                  fontWeight: 500,
                                }}
                              >
                                To use gmail account to send email user needs to do next steps:
                                <br />
                                <li>
                                  Go to your (<a href="https://myaccount.google.com/">Google Account</a>).
                                </li>
                                <li>On the left navigation panel, click “Security”.</li>
                                <li>Go to “Signing in to Google”</li>
                                <li>Enable 2-step verification</li>
                                <li>Go to the “App passwords” in “Signing in to Google” section</li>
                                <li>Select “Other” app and enter name (for example “Marbl app”)</li>
                                <li>Click “Generate”</li>
                                <li>Copy 16-symbol password and save it (You can no longer view this password)</li>
                                <li>Go to the app and enter this 16-symbol password</li>
                              </p>
                            </div>
                            <br />
                          </Collapse>
                        </>
                        <LabelInput
                          className="has-label col-md-10"
                          label="SMTP Server Domain"
                          id="SMTPDomain"
                          autoComplete="off"
                          onChange={e => this.typeInput(e, 'SMTPDomain', 'webportal')}
                          value={SMTPDomain}
                          validationClass={SMTPDomainState}
                          disabled={!useCustomSmtp}
                        />
                        <LabelInput
                          className="has-label col-md-4"
                          label="SMTP Server Port"
                          id="SMTPPort"
                          autoComplete="off"
                          onChange={e => this.typeNumber(e, 'SMTPPort')}
                          value={SMTPPort}
                          validationClass={SMTPPortState}
                          disabled={!useCustomSmtp}
                        />
                        <div className="has-label col-md-4" style={{ marginLeft: '20px' }}>
                          <Input
                            type="checkbox"
                            style={{ marginTop: 2 }}
                            checked={enableSSL}
                            disabled={!useCustomSmtp}
                            onClick={() => this.setState({ enableSSL: !enableSSL })}
                          />
                          <p style={{ fontSize: '1em', color: colors.blue }}>
                            <span>SSL SMTP security</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <Label className="col-md-4" style={{ color: colors.blue, marginBottom: '0.7143em' }}>
                      Or
                    </Label>
                    <div className="col-md-8 form-group">
                      <div
                        className="form-control"
                        style={{
                          borderColor: !useCustomSmtp ? colors.blue : colors.gray,
                          padding: 10,
                          paddingLeft: 40,
                          paddingRight: 20,
                          cursor: 'default',
                          overflow: 'hidden',
                        }}
                        disabled={useCustomSmtp}
                      >
                        <div style={{ display: 'flex' }}>
                          <Input
                            type="radio"
                            color={colors.blue}
                            style={{ marginTop: 2, color: colors.blue }}
                            checked={!useCustomSmtp}
                            onClick={() => {
                              this.setState({ useCustomSmtp: false });
                            }}
                          />
                          <Label
                            style={{
                              color: !useCustomSmtp ? colors.blue : colors.gray,
                              fontSize: '1.14em',
                              flex: 1,
                              fontWeight: 700,
                            }}
                          >
                            Let Marbl's no-reply email send proposals
                          </Label>
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      {this.doubleLabelInput(
                        'overdueProposalLifespanMonth',
                        overdueProposalLifespanMonth,
                        overdueProposalLifespanMonthState,
                        'Past Pipeline Proposal Lifespan',
                        'month(s)'
                      )}
                      {this.doubleLabelInput(
                        'proposalLifespanDays',
                        proposalLifespanDays,
                        proposalLifespanDaysState,
                        'Current Pipeline Proposal Lifespan',
                        'day(s)'
                      )}
                      {this.doubleLabelInput(
                        'salesAgreementLifespanDays',
                        salesAgreementLifespanDays,
                        salesAgreementLifespanDaysState,
                        'Offer is good for',
                        'day(s)'
                      )}
                    </div>
                    <div className="form-row">
                      <LabelInput
                        onChange={e => this.typeInput(e, 'installationDescription', 'proposal')}
                        className="col-md-6 has-label"
                        label="Installation Description"
                        id="InstallationDesc"
                        autoComplete="off"
                        value={installationDescription}
                        type="textarea"
                      />
                      <LabelInput
                        onChange={e => this.typeInput(e, 'warrantyInformation', 'proposal')}
                        className="col-md-6 has-label "
                        label="Warranty Information"
                        id="warrantyInfo"
                        autoComplete="off"
                        value={warrantyInformation}
                        type="textarea"
                      />
                    </div>
                    {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                    <Button disabled={isLoading} type="submit" color="info" className="pull-right">
                      {isRegister ? 'Subscribe' : 'Save'}
                    </Button>
                  </CardBody>
                </form>
              </Card>
              <Card className="card-stats card-raised" style={{ backgroundColor: colors.lightBlue }}>
                <CardBody style={{ padding: '2vw' }}>
                  <CardTitle>Invite an admin</CardTitle>
                  <div className="form-row">
                    <LabelInput
                      className={`col-md-6 has-label ${inviteAdminEmailState}`}
                      label="New Admin Email Address"
                      id="inviteAdminEmail"
                      autoComplete="off"
                      onChange={e => this.typeEmail(e, 'inviteAdminEmail')}
                      type="email"
                    />
                    <div
                      className="col-md-1"
                      style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginLeft: 20 }}
                    >
                      <Button type="submit" color="info" className="pull-right">
                        Invite
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default observer(Settings);

Settings.defaultProps = {
  isRegister: false,
};
