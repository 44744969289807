import React, { useState } from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, Row, Col, Collapse } from 'reactstrap';
import Salesperson from './Salesperson';

const SalespersonList = ({ salespersons, isLoading = false, onSalespersonPress, selectedId = '' }) => {
  return (
    <Card style={{ height: 326 }}>
      <CardBody style={{ height: '100%' }}>
        <Row>
          <Col>
            <CardTitle className="title blue large">Filter by Salesperson</CardTitle>
          </Col>
        </Row>
        <Row style={{ height: '100%' }}>
          {salespersons?.length > 0 && (
            <div className="grid scroll-x--rows-2" style={{ paddingLeft: 10, flex: 1 }}>
              {salespersons.map(salesperson => (
                <Salesperson
                  key={salesperson.id}
                  salesperson={salesperson}
                  onPress={onSalespersonPress}
                  isSelected={selectedId === salesperson.id}
                />
              ))}
            </div>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export default SalespersonList;
