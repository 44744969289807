import { Spinner } from 'reactstrap';
import React from 'react';

const loadingContainerStyles = {
  width: '100%',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

// eslint-disable-next-line import/prefer-default-export
export const LoadingContainer = () => {
  return (
    <div style={loadingContainerStyles}>
      <Spinner color="primary" />
    </div>
  );
};
