import CompanyStore from './company.store';
import CompanyAdminStore from './companyAdmin.store';
import SettingsStore from './settings.store';

class Store {
  constructor() {
    this.company = new CompanyStore(this);
    this.companyAdminStore = new CompanyAdminStore(this);
    this.settings = new SettingsStore(this);
  }
}

export default new Store();
