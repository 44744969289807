import React from 'react';
import PropTypes from 'prop-types';
import image from '../../assets/img';
import { colors } from '../../theme';

const styles = {
  container: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
  button: {
    outline: 'none',
    borderRadius: '100%',
    borderWidth: 0,
    backgroundColor: colors.transparent,
    overflow: 'hidden',
    padding: 0,
  },
  label: { color: colors.blue, textAlign: 'center', fontSize: '1.0714em' },
};

const MarblButton = ({
  onClick,
  size,
  fixedSize,
  style,
  disabled,
  label,
  labelPosition,
  labelStyle,
  isOneLine,
  imageClasses,
  url,
}) => {
  let buttonSize = '10vw';
  if (size === 'sm') buttonSize = '5vw';
  if (size === 'lg') buttonSize = '15vw';
  if (fixedSize) buttonSize = fixedSize;

  const MAX_TEXT_LENGTH = 30;

  const truncateText = text => {
    if(!text){
      return ''
    }
    if (text.length > MAX_TEXT_LENGTH) {
      return text.substring(0, MAX_TEXT_LENGTH).concat(`...`);
    }

    return text;
  };

  return (
    <div style={styles.container}>
      <button
        type="button"
        style={{ ...styles.button, width: buttonSize, height: buttonSize, ...style }}
        disabled={disabled}
        onClick={onClick}
      >
        <img className={imageClasses} src={url || image.marblButton} alt="" style={{ objectFit: 'contain' }} />
      </button>
      <p className="mt-1 mb-1" style={{ ...styles.label, ...labelStyle }}>
        {truncateText(label)}
      </p>
    </div>
  );
};

MarblButton.propTypes = {
  size: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  labelStyle: PropTypes.object,
  label: PropTypes.string,
  labelPosition: PropTypes.string,
  isOneLine: PropTypes.bool,
};

MarblButton.defaultProps = {
  size: 'md',
  onClick: () => null,
  disabled: false,
  style: {},
  labelStyle: {},
  label: '',
  labelPosition: 'bottom',
  isOneLine: false,
};

export default MarblButton;
