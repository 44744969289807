import React from 'react';
import { Row, Label, Col, Card, CardBody, Button, Alert, Input } from 'reactstrap';
import PanelHeader from 'components/PanelHeader/PanelHeader';
import ReactTable from 'react-table';
import { colors } from 'theme';
import { observer } from 'mobx-react';
import {
  editCompanyDealers,
  editCompanyGroupedIndoorUnits,
  editCompanyAverageCostAncillaryPart,
  editCompanyDefaultLengthAdjustable,
} from 'lib/api/companyApi';
import cloneDeep from 'lodash/cloneDeep';
import { compareStrings } from 'utils/helpers';
import BrandCard from './BrandCard';
import store from '../../store';

const styles = {
  labelBold: {
    color: colors.blue,
    fontSize: '1em',
    fontWeight: 600,
    marginRight: '15px',
    marginTop: '5px',
  },
  label: {
    color: colors.blue,
    fontSize: '1em',
    marginRight: '15px',
    marginTop: '5px',
  },
  card: { backgroundColor: colors.lightBlue, padding: '5%' },
};

class DealersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dealers: [],
      indoorUnits: [],
      adjustableAncillaryParts: [],
      averageCost: 0,
      averageCostChanged: false,
      errorMessage: '',
      isLoading: false,
      isSaved: false,
      searchText: '',
    };
  }

  componentDidMount() {
    const { data: company, indoorUnits, averageAncillaryCost, adjustableAncillaryParts } = store.company;
    if (!company?.id?.length) {
      this.fetchDataDealer();
    } else {
      this.setState({
        dealers: company?.dealers ? cloneDeep(company?.dealers) : [],
        indoorUnits: indoorUnits ? cloneDeep(indoorUnits) : [],
        averageCost: cloneDeep(averageAncillaryCost) || [],
        adjustableAncillaryParts:
          cloneDeep(
            adjustableAncillaryParts.sort((a, b) => compareStrings(a.ancillaryPartName, b.ancillaryPartName))
          ) || [],
      });
    }
  }

  fetchDataDealer = () => {
    store.company.fetchCompany(null, {
      done: () => {
        this.setState({
          dealers: cloneDeep(store.company?.data?.dealers) || [],
          indoorUnits: cloneDeep(store.company?.indoorUnits) || [],
          averageCost: cloneDeep(store.company?.averageAncillaryCost) || [],
          adjustableAncillaryParts:
            cloneDeep(
              store.company?.adjustableAncillaryParts.sort((a, b) =>
                compareStrings(a.ancillaryPartName, b.ancillaryPartName)
              )
            ) || [],
        });
      },
    });
  };

  validate = () => {
    const { dealers } = this.state;
    if (!!dealers && dealers.length !== 0 && dealers.filter(({ enabled }) => enabled).length === 0) {
      this.setState({ errorMessage: 'Select at least one dealer' });
      return false;
    }

    return true;
  };

  selectItem = index => {
    const { dealers } = this.state;
    const _dealers = cloneDeep(dealers);
    const dealer = _dealers[index];
    dealer.enabled = !dealer.enabled;
    // eslint-disable-next-line react/no-access-state-in-setstate, react/destructuring-assignment
    this.setState({ dealers: _dealers, errorMessage: '' });
  };

  checkSelected = _unit => {
    if (_unit.units) {
      return !_unit.units.every(_subUnit => {
        return !this.checkSelected(_subUnit);
      });
    }
    return _unit.isSelected;
  };

  onUnitClick = _unit => {
    const isItemSelected = this.checkSelected(_unit);

    const setSelection = (_item, _value) => {
      // eslint-disable-next-line no-param-reassign
      _item.isSelected = _value;
      const { indoorUnits } = this.state;
      this.setState({ indoorUnits });
    };
    const setUnitsSelection = (_item, _value) => {
      if (_item.units) {
        _item.units.forEach(_u => setUnitsSelection(_u, _value));
      } else {
        setSelection(_item, _value);
      }
    };
    if (_unit.units) {
      _unit.units.forEach(_u => setUnitsSelection(_u, !isItemSelected));
    } else {
      setSelection(_unit, !_unit.isSelected);
    }
  };

  saveMultiplier = (multiplier, index) => {
    const { dealers } = this.state;
    const _dealers = cloneDeep(dealers);
    const dealer = _dealers[index];
    dealer.multiplier = multiplier;
    this.setState({ dealers: _dealers, errorMessage: '' });
  };

  onSearchChange = search => {
    this.setState({ searchText: search });
  };

  saveAverageCost = async () => {
    const { averageCost, averageCostChanged } = this.state;
    const averageCostData = { averageCost };
    if (averageCostChanged) {
      try {
        const result = await editCompanyAverageCostAncillaryPart(averageCostData);
        if (result) {
          store.company.updateAverageAncillaryCost(averageCost);
          this.setState({ averageCostChanged: false });
        }
      } catch (e) {
        this.setState({ errorMessage: e.message || 'Something went wrong', isLoading: false });
      }
    }
  };

  saveAncillaryPart = async itemId => {
    const { adjustableAncillaryParts } = this.state;
    const part = adjustableAncillaryParts.find(p => p.id === itemId);
    if (part?.changed) {
      const data = { ancillaryPartCompanyCostId: part.id, defaultQuantity: part.defaultLength };
      try {
        const result = await editCompanyDefaultLengthAdjustable(data);
        if (result) {
          store.company.updateAdjAncillaryPart(data);
        }
      } catch (e) {
        this.setState({ errorMessage: e.message || 'Something went wrong', isLoading: false });
      }
    }
  };

  onSaveAll = async () => {
    const { dealers, indoorUnits } = this.state;
    this.setState({ isLoading: true, errorMessage: '', isSaved: false });
    if (!this.validate()) {
      this.setState({ errorMessage: 'Select at least one dealer', isLoading: false });
      return;
    }
    await new Promise(r => setTimeout(r, 100));
    try {
      const dealersData = {
        dealers: dealers
          .filter(({ enabled }) => enabled)
          .map(({ id, multiplier }) => ({
            id,
            multiplier: multiplier || 0,
          })),
      };

      const excludedUnits = [];

      const selectedIndoorUnitsFilter = indoorUnit => {
        if (indoorUnit.units) {
          indoorUnit.units.forEach(u => selectedIndoorUnitsFilter(u));
        } else if (!indoorUnit.isSelected) {
          excludedUnits.push(indoorUnit.unitId);
        }
      };

      indoorUnits.forEach(indoorUnit => selectedIndoorUnitsFilter(indoorUnit));

      const dealerSeriesData =
        indoorUnits?.length > 0
          ? {
              excludedUnits,
            }
          : null;

      const listPromise = [editCompanyDealers(dealersData), editCompanyGroupedIndoorUnits(dealerSeriesData)];
      await Promise.all(listPromise);
      this.setState({ errorMessage: '', isLoading: false, isSaved: true });
      this.fetchDataDealer();
    } catch (e) {
      this.setState({ errorMessage: e.message || 'Something went wrong', isLoading: false });
    }
  };

  onAdjustableAncillaryPartsChange = (event, itemId) => {
    const { adjustableAncillaryParts } = this.state;
    const { value } = event.target;
    const partIndex = adjustableAncillaryParts.findIndex(p => p.id === itemId);
    if (partIndex > -1) {
      const newPart = { ...adjustableAncillaryParts[partIndex], defaultLength: value, changed: true };
      const _adjustableAncillaryParts = [...adjustableAncillaryParts];
      _adjustableAncillaryParts[partIndex] = newPart;
      this.setState({ adjustableAncillaryParts: _adjustableAncillaryParts });
    }
  };

  renderAdjustableRow = (item, isLast) => (
    <Row key={item.id} className="mt-2 align-items-center">
      <Col style={{ flex: 2 }}>
        <Label style={styles.labelBold}>{item.ancillaryPartName}</Label>
      </Col>
      <Col style={{ flex: 1 }}>
        <Input
          type="number"
          className="form-control blue-border outline-none"
          style={{ width: '80px', marginLeft: 'auto', marginRight: 0 }}
          onChange={event => this.onAdjustableAncillaryPartsChange(event, item.id)}
          value={item.defaultLength}
          onBlur={() => this.saveAncillaryPart(item.id)}
        />
      </Col>
    </Row>
  );

  render() {
    const {
      dealers,
      indoorUnits,
      errorMessage,
      isLoading,
      isSaved,
      searchText,
      averageCost,
      adjustableAncillaryParts,
    } = this.state;
    const _dealers = dealers.filter(dealer => dealer?.name?.toLowerCase().includes(searchText.toLowerCase()));
    const renderDealeres = _dealers.map((dealer, index) => {
      return (
        <BrandCard
          key={dealer.id}
          index={index}
          name={dealer.name}
          imageUrl={dealer.imageUrl}
          dealer={dealer}
          indoorUnits={indoorUnits}
          selectItem={this.selectItem}
          onUnitClick={this.onUnitClick}
          onMultiplierChange={this.saveMultiplier}
        />
      );
    });

    const adjustableAncillaryPartRows = adjustableAncillaryParts?.map((item, index) =>
      this.renderAdjustableRow(item, index !== adjustableAncillaryParts.length - 1)
    );
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardBody style={{ padding: '4vw' }}>
                  <Row>
                    <Label style={{ color: colors.blue, fontSize: '1.14em', fontWeight: 700 }}>
                      Dealer Type / Series Selection
                    </Label>
                  </Row>
                  <Row>
                    <Label style={{ color: colors.red, fontWeight: 600 }}>
                      In order to build systems other than Mitsubishi, you should be using app version 6.1 or later
                    </Label>
                  </Row>

                  <Row>
                    {dealers?.length > 0 && (
                      <Col>
                        <Row>
                          <Label style={{ color: colors.blue, flexGrow: 1 }}>
                            <span style={{ color: colors.red }}>*</span> Please select the brand(s) and series that you
                            would like to appear in the app
                          </Label>
                          <Input
                            value={searchText}
                            onChange={event => this.onSearchChange(event.target.value)}
                            type="text"
                            name="search"
                            id="search"
                            placeholder="Search"
                            className="form-control blue-border pull-right"
                            style={{ width: '200px' }}
                          />
                        </Row>
                        <Row>
                          <div style={{ overflowX: 'auto', display: 'flex', padding: '10px' }}>{renderDealeres}</div>
                        </Row>
                      </Col>
                    )}
                  </Row>
                  {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                  {isSaved && (
                    <Alert color="success" style={{ marginTop: '10px' }}>
                      Saved!
                    </Alert>
                  )}
                  <Button
                    disabled={isLoading}
                    type="submit"
                    onClick={this.onSaveAll}
                    color="info"
                    className="pull-right"
                  >
                    Save
                  </Button>
                </CardBody>
              </Card>
              {(store.company.ancillaryParts?.length > 0 || adjustableAncillaryParts?.length > 0) && (
                <Card>
                  <CardBody style={{ padding: '4vw' }}>
                    <Row>
                      <Label style={{ color: colors.blue, fontSize: '1.14em', fontWeight: 700 }}>Ancillary Parts</Label>
                    </Row>

                    <Row>
                      <Label style={{ color: colors.red, fontWeight: 600 }}>
                        Marbl automatically adds ancillary parts based on the equipment you select.
                      </Label>
                    </Row>

                    <Row>
                      <Label style={{ color: colors.blue, flexGrow: 1 }}>
                        <span style={{ color: colors.red }}>*</span> Please adjust your per/foot cost along with the
                        desired amount of standard lineset for each indoor unit type. You may adjust these in-app.
                      </Label>
                    </Row>

                    <Row>
                      {adjustableAncillaryParts?.length > 0 && (
                        <Col style={{ flex: 2 }}>
                          <Row>
                            <Label style={{ color: colors.blue, fontSize: '1.14em', fontWeight: 700 }}>
                              Adjustable
                            </Label>
                          </Row>
                          <div
                            className="form-control"
                            style={{
                              borderColor: colors.blue,
                              padding: 10,
                              paddingLeft: 20,
                              paddingRight: 20,
                              cursor: 'default',
                              overflow: 'hidden',
                            }}
                          >
                            <Row className="mt-2 align-items-center">
                              <Col style={{ flex: 2 }}>
                                <Label style={styles.labelBold}>Average Cost per Linear Foot</Label>
                              </Col>
                              <Col style={{ flex: 1 }}>
                                <Input
                                  type="number"
                                  className="form-control blue-border outline-none"
                                  style={{ width: '80px', marginLeft: 'auto', marginRight: 0 }}
                                  onChange={event =>
                                    this.setState({ averageCost: event.target.value, averageCostChanged: true })
                                  }
                                  value={averageCost}
                                  onBlur={() => this.saveAverageCost()}
                                />
                              </Col>
                            </Row>

                            <Row className="mt-2 align-items-center">
                              <Col style={{ flex: 2 }}>
                                <Label style={styles.label}>Application</Label>
                              </Col>
                              <Col style={{ flex: 1 }} className="text-right">
                                <Label style={styles.label}>Length</Label>
                              </Col>
                            </Row>

                            {adjustableAncillaryPartRows}
                          </div>
                        </Col>
                      )}
                      {store.company.ancillaryParts?.length > 0 && (
                        <Col style={{ flex: 5 }}>
                          <Row>
                            <Label style={{ color: colors.blue, fontSize: '1.14em', fontWeight: 700 }}>Fixed</Label>
                          </Row>

                          <div
                            className="form-control"
                            style={{
                              borderColor: colors.blue,
                              padding: 10,
                              paddingLeft: 20,
                              paddingRight: 20,
                              cursor: 'default',
                              overflow: 'hidden',
                            }}
                          >
                            <ReactTable
                              data={store.company.ancillaryParts}
                              loading={isLoading}
                              columns={[
                                {
                                  Header: 'TYPE',
                                  accessor: 'unitType',
                                  width: 300,
                                  style: styles.labelBold,
                                },
                                {
                                  Header: 'DESCRIPTION',
                                  accessor: 'partsDescription',
                                  style: styles.label,
                                },
                                {
                                  Header: 'PARTS MATERIALS COST',
                                  accessor: 'partsMaterialsCost',
                                  style: styles.labelBold,
                                  width: 200,
                                  Cell: row => (
                                    <div style={{ textAlign: 'end' }}>${row.original.partsMaterialsCost}</div>
                                  ),
                                },
                              ]}
                              showPaginationBottom={false}
                              className="-striped -highlight"
                            />
                          </div>
                        </Col>
                      )}
                    </Row>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default observer(DealersPage);

DealersPage.defaultProps = {
  isRegister: false,
};
