import React, { Component } from 'react';
import { changeSalespersonPassword } from 'lib/api/salespersonApi';
import { Button, Col, Input, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { colors } from 'theme';

export default class ResetPasswordModal extends Component {
  state = {
    isLoading: false,
    errorMessage: '',
    isInvalidForm: false,
    newPassword: '',
    confirmPassword: '',
  };

  validate = (newPassword, confirmPassword) => {
    const isInvalidForm = !newPassword || !confirmPassword || newPassword !== confirmPassword;
    this.setState({ isInvalidForm });
    return !isInvalidForm;
  };

  handleSubmit = event => {
    event.preventDefault();

    const { newPassword, confirmPassword } = this.state;
    const { salesmanId, toggleModal } = this.props;

    if (!this.validate(newPassword, confirmPassword)) {
      return false;
    }

    this.setState({ isLoading: true, errorMessage: '' });

    return changeSalespersonPassword({ newPassword, salesmanId })
      .then(() => {
        toggleModal();

        this.setState({
          isInvalidForm: true,
          isLoading: false,
        });
      })
      .catch(({ message }) => {
        this.setState({
          isLoading: false,
          errorMessage: message,
        });
      });
  };

  onChange = name => ({ target }) => {
    const { value } = target;
    this.setState({ [name]: value });
  };

  render() {
    const { isInvalidForm, newPassword, confirmPassword, isLoading, errorMessage } = this.state;
    const { toggleModal } = this.props;

    return (
      <Modal isOpen toggle={toggleModal} size="md">
        <form onSubmit={this.handleSubmit}>
          <ModalBody>
            <Row>
              <Col style={{ flex: 1 }}>
                New password
                <Input
                  name="newPassword"
                  type="password"
                  value={newPassword}
                  onChange={this.onChange('newPassword')}
                  style={{ borderColor: isInvalidForm ? colors.red : '' }}
                  placeholder="New password"
                />
              </Col>
            </Row>
            <Row>
              <Col style={{ flex: 1 }}>
                Confirm password
                <Input
                  name="confirmPassword"
                  type="password"
                  value={confirmPassword}
                  onChange={this.onChange('confirmPassword')}
                  style={{ borderColor: isInvalidForm ? colors.red : '' }}
                  placeholder="Confirm password"
                />
              </Col>
            </Row>
            <div className="pt-2">
              {!errorMessage && (
                <p style={{ fontSize: '0.714em', color: colors.blue }}>
                  <span>
                    Password must contain at least 8 characters, have upper case and lower case letters, number, special
                    character
                  </span>
                </p>
              )}
              {isInvalidForm && <p style={{ color: colors.red, fontSize: '0.8571em' }}>Check your password</p>}
              {errorMessage && <p style={{ color: colors.red, fontSize: '0.8571em' }}>{errorMessage}</p>}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" className="btn-round" onClick={toggleModal}>
              Cancel
            </Button>
            <Button disabled={isLoading} type="submit" className="btn-round" color="info">
              Reset
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

ResetPasswordModal.defaultProps = {
  toggleModal: () => {},
};
