/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState } from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, Row, Col, Collapse } from 'reactstrap';
import { colors } from 'theme';
import config from 'config';
import avatar from 'assets/img/default-avatar.png';

const Salesperson = ({ salesperson: { id, name, username, avatarUrl }, isSelected, onPress }) => {
  return (
    <Card
      key={id}
      className="btn btn-sm"
      style={{
        backgroundColor: isSelected ? colors.opacityLigthBlue : colors.opacityLightGray,
        width: '300px',
        height: '110px',
        cursor: 'pointer',
        margin: 0,
        marginRight: '20px',
        padding: 0,
        textAlign: 'left',
      }}
      onClick={() => onPress(isSelected ? '' : id)}
    >
      <CardBody>
        <Row>
          <Col style={{ flex: 0 }}>
            <div className="img-avatar">
              {avatarUrl ? (
                <img className="img-avatar" alt="Avatar" src={`${config.imagesUrl}${avatarUrl}`} />
              ) : (
                <img className="img-avatar" alt="Avatar" src={avatar} />
              )}
            </div>
          </Col>
          <Col style={{ flex: 1 }}>
            <Row className="pt-3 mb-1">
              <Col>
                <CardSubtitle style={{ overflowWrap: 'break-word', wordWrap: 'break-word', wordBreak: 'break-word' }}>
                  {name}
                </CardSubtitle>
              </Col>
            </Row>
            <Row>
              <Col>
                <CardSubtitle style={{ overflowWrap: 'break-word', wordWrap: 'break-word', wordBreak: 'break-word' }}>
                  {username}
                </CardSubtitle>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Salesperson;
