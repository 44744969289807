import React, { useState } from 'react';
import { colors } from 'theme';

import { Input, Label, Collapse } from 'reactstrap';

const CollapsibleRow = props => {
  const { label, content, itemEnabled, isSelected, checkboxClick } = props;
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ paddingTop: 10, passingBottom: 10, paddingLeft: 20 }}>
      <div className="collapsible-row">
        <Input type="checkbox" style={{ marginTop: 2 }} checked={isSelected} onChange={checkboxClick} />
        <Label style={{ color: itemEnabled ? colors.blue : colors.gray, fontSize: '1em', fontWeight: 600 }}>
          {label}
        </Label>
        <button className="collapsible-row button" type="button" onClick={onClick}>
          <i className={`fas fa-chevron-down ${isOpen ? 'rotate-center-edonec down' : 'rotate-center-edonec up'}`} />
        </button>
      </div>
      <Collapse isOpen={isOpen}>{content}</Collapse>
    </div>
  );
};

export default CollapsibleRow;
