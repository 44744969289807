import { NUM_DEC_REG_EX } from '../../utils/regularExpressions';

export function toUSFormat(number) {
  const [num, part] = number.toString().split('.');
  return `${num.replace(/(\d)(?=(\d{3})+$)/g, '$1,')}.${part || '00'}`;
}

export function checkZeroFormat(value) {
  if (typeof value === 'undefined' || value === '' || Number.isNaN(value)) return '';
  const newValue = String(value).replace(NUM_DEC_REG_EX, '');
  return Number(newValue).toFixed(2);
}
