const environments = {
  development: 0,
  production: 1,
  preproduction: 2,
};
const currentEnvironment = environments.production;

let apiURL = '';
let imagesUrl = '';
let stripeKey = '';
let stripePricingTableId = '';
let stripeRegistrationPricingTableId = '';

switch (currentEnvironment) {
  case environments.development:
    stripeKey =
      'pk_test_51HrNO9JaJi2UebxuAqmpkd1uIXPz8YJ7bcM0GssPGdun7nbVg1isNV2PIkcexXZUaXj2Z1aD80AyCJDe3iqHlYcd00xQztwe4R';
    stripePricingTableId = 'prctbl_1OHPBFJaJi2UebxuGJfdYX6v';
    stripeRegistrationPricingTableId = 'prctbl_1OkTykJaJi2UebxunsYAroeg';
    apiURL = 'https://haproxy.backend.test.whisperq.ru';
    imagesUrl = 'https://chiltechfilestorage.blob.core.windows.net';
    break;
  case environments.preproduction:
    stripeKey =
      'pk_test_51HrNO9JaJi2UebxuAqmpkd1uIXPz8YJ7bcM0GssPGdun7nbVg1isNV2PIkcexXZUaXj2Z1aD80AyCJDe3iqHlYcd00xQztwe4R';
    stripePricingTableId = 'prctbl_1OZuWTJaJi2UebxuXigQQhlq';
    stripeRegistrationPricingTableId = 'prctbl_1OkTykJaJi2UebxunsYAroeg';
    apiURL = 'https://haproxy.backend.test.whisperq.ru';
    imagesUrl = 'https://chiltechfilestorage.blob.core.windows.net';
    break;
  case environments.production:
    stripeKey =
      'pk_live_51HrNO9JaJi2Uebxu1V3mBJi6YUSTiDaoSJki2YmSDm1hVmyuvj3h0aoTyDcRY9iUZtJmiUn60DzmjIQeJFLXXDJv00HOVQ0okH';
    stripePricingTableId = 'prctbl_1OoTCYJaJi2UebxuSKXzpBGD';
    stripeRegistrationPricingTableId = 'prctbl_1OoTEHJaJi2Uebxu1dxogFsI';
    apiURL = 'https://www.api.marblplatform.com';
    imagesUrl = 'https://chiltechfilestorageprod.blob.core.windows.net';
    break;
  default:
    stripeKey = '';
    stripePricingTableId = '';
    stripeRegistrationPricingTableId = '';
    imagesUrl = 'https://chiltechfilestorage.blob.core.windows.net';
    apiURL = 'https://haproxy.backend.test.whisperq.ru';
}

export default {
  apiURL,
  imagesUrl,
  stripeKey,
  stripePricingTableId,
  stripeRegistrationPricingTableId,
  isProduction: currentEnvironment === environments.production,
};
