import authService from 'lib/services/authService';

import { getJSON, postJSON, putJSON, submitMultipartForm } from './ajax';

export function checkCompanyAdminAccountId(accountId) {
  return postJSON('/api/checkuniqueness', { accountId });
}

export function getCompanyDetails() {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return getJSON('/api/company', REQUEST_OPTIONS);
}

export function getCompanyDealers() {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return getJSON('/api/dealers', REQUEST_OPTIONS);
}

export function getCompanyAncillaryParts() {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return getJSON('/api/ancillary/part/list', REQUEST_OPTIONS);
}

export function getCompanyAdjAncillaryParts() {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return getJSON('/api/ancillarypart/companycost/list', REQUEST_OPTIONS);
}

export function getCompanyUnits() {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return getJSON('/api/company/units', REQUEST_OPTIONS);
}

export function getIsSalesInMetadata(SalesmanId) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return getJSON('/api/salesman/issalesmaninmetadata', REQUEST_OPTIONS, { SalesmanId });
}

export function getNotification() {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return getJSON('/api/company/notification', REQUEST_OPTIONS);
}

export function toggleNotification(companyId, notification = false) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  const payload = companyId ? { notification, companyId } : { notification };
  return putJSON(`/api${companyId ? '/management' : ''}/company/notification`, payload, REQUEST_OPTIONS);
}

export function createCompanyDetails(data) {
  return postJSON('/api/company', data);
}

export function editCompanyDetails(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return putJSON('/api/company', data, REQUEST_OPTIONS);
}

export function editCompanyDealers(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return postJSON('/api/dealers', data, REQUEST_OPTIONS);
}

export function editCompanyGroupedIndoorUnits(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };

  return postJSON('/api/company/units', data, REQUEST_OPTIONS);
}

export function editCompanyContract(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return submitMultipartForm('/api/companycontractsecondpage', data, REQUEST_OPTIONS);
}
export function editCompanyPhoto(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return submitMultipartForm('/api/companyphoto', data, REQUEST_OPTIONS);
}

export function editCompanyLogo(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return submitMultipartForm('/api/companylogo', data, REQUEST_OPTIONS);
}

export function editCompanyBySuperUser(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return putJSON('/api/management/company', data, REQUEST_OPTIONS);
}

export function editCompanyDefaultLengthAdjustable(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };

  return putJSON('/api/ancillarypart/companycost/change-default-length-adjustable', data, REQUEST_OPTIONS);
}

export function editCompanyAverageCostAncillaryPart(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };

  return putJSON('/api/ancillarypart/companycost/set-average-cost-per-linear-foot-adjustable', data, REQUEST_OPTIONS);
}