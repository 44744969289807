import Dashboard from 'views/Dashboard/Dashboard';
import AdminDashboard from 'views/Dashboard/AdminDashboard';
import Buttons from 'views/Components/Buttons';
import GridSystem from 'views/Components/GridSystem';
import Panels from 'views/Components/Panels';
import SweetAlert from 'views/Components/SweetAlertPage';
import Notifications from 'views/Components/Notifications';
import Icons from 'views/Components/Icons';
import Typography from 'views/Components/Typography';
import RegularForms from 'views/Forms/RegularForms';
import ExtendedForms from 'views/Forms/ExtendedForms';
import ValidationForms from 'views/Forms/ValidationForms';
import Wizard from 'views/Forms/Wizard/Wizard';
import RegularTables from 'views/Tables/RegularTables';
import ExtendedTables from 'views/Tables/ExtendedTables';
import ReactTable from 'views/Tables/ReactTable';
import GoogleMaps from 'views/Maps/GoogleMaps';
import FullScreenMap from 'views/Maps/FullScreenMap';
import VectorMap from 'views/Maps/VectorMap';
import Charts from 'views/Charts/Charts';
import Calendar from 'views/Calendar/Calendar';
import Widgets from 'views/Widgets/Widgets';
import UserPage from 'views/Pages/UserPage';
import TimelinePage from 'views/Pages/TimelinePage';
import RTL from 'views/Pages/RTL';
import PricingPage from 'views/Pages/PricingPage';
import LoginPage from 'views/Pages/LoginPage';
import RestorePage from 'views/Pages/RestorePage';
import RegisterPage from 'views/Pages/RegisterPage';
import LockScreenPage from 'views/Pages/LockScreenPage';
import Workforce from 'views/Workforce/Workforce';
import Customers from 'views/Customers/Customers';
import AddEditSalePerson from 'views/Workforce/AddEditSalePerson';
import AssignCustomers from 'views/Customers/AssignCustomers';
import AddEditCustomer from 'views/Customers/AddEditCustomer';
import ViewProposals from 'views/ViewProposals/ViewProposals';
import PaymentSuccessPage from 'views/Pages/PaymentSuccessPage';
import PaymentFailedPage from 'views/Pages/PaymentFailedPage';
import Settings from './views/Settings/Settings';
import Support from './views/Support/Support';
import IntegrationsPage from './views/Integrations/Integrations';
import Pricebook from './views/Pricebook/Pricebook';
import CompanyPage from './views/SuperAdmin/Company/CompanyPage';
import BillingPage from './views/Billing/BillingPage';
import AnnouncementsPage from './views/Announcements/AnnouncementsPage';
import DealersPage from './views/Dealers/DealersPage';

export const routes = [
  {
    path: '/viewproposals',
    component: ViewProposals,
    layout: '/admin',
    name: 'View Proposals',
  },
  {
    path: '/addsaleperson',
    component: AddEditSalePerson,
    layout: '/admin',
    name: 'Add/Edit Saleperson',
  },
  {
    path: '/editsaleperson/:id',
    component: AddEditSalePerson,
    layout: '/admin',
    name: 'Add/Edit Saleperson',
  },
  {
    path: '/addcustomer',
    component: AddEditCustomer,
    layout: '/admin',
    name: 'Add/Edit Customer',
  },
  {
    path: '/editcustomer',
    component: AddEditCustomer,
    layout: '/admin',
    name: 'Add/Edit Customer',
  },
  {
    path: '/assigncustomers',
    component: AssignCustomers,
    layout: '/admin',
    name: 'Assign Customers',
  },
];

export const authRoutes = [
  {
    path: '/login-page',
    component: LoginPage,
    layout: '/auth',
    name: 'Login',
  },
  {
    path: '/restore-page',
    component: RestorePage,
    layout: '/auth',
    name: 'Restore Page',
  },
  {
    path: '/logout',
    layout: '/auth',
    name: 'Login',
  },
];

export const unauthorizedRoutes = [
  {
    path: '/login-page',
    component: LoginPage,
    layout: '/auth',
    name: 'Login',
  },
];

export const infoRoutes = [
  {
    path: '/payment-success',
    component: PaymentSuccessPage,
    layout: '/info',
    name: 'Payment Success',
  },
  {
    path: '/payment-failed',
    component: PaymentFailedPage,
    layout: '/info',
    name: 'Payment Failed',
  },
];

export const superUserRoutes = [
  {
    menu: true,
    path: '/dashboard',
    name: 'Home',
    icon: 'now-ui-icons design_app',
    layout: '/super-admin',
    component: AdminDashboard,
  },
  {
    menu: true,
    path: '/announcements',
    name: 'Announcements',
    icon: 'now-ui-icons design_app',
    layout: '/super-admin',
    component: AnnouncementsPage,
  },
  // {
  //   menu: true,
  //   path: '/subscription-page',
  //   name: 'Subscription',
  //   icon: 'now-ui-icons design_app',
  //   layout: '/super-admin',
  //   component: SubscriptionPage,
  // },
  {
    path: '/company/:id',
    name: 'CompanyPage',
    layout: '/super-admin',
    component: CompanyPage,
  },
  {
    menu: true,
    path: '/logout',
    layout: '/auth',
    name: 'Logout',
  },
];

export const drawerRoutes = [
  {
    path: '/dashboard',
    name: 'Home',
    icon: 'now-ui-icons design_app',
    component: Dashboard,
    layout: '/admin',
  },
  {
    path: '/settings',
    name: 'Settings',
    icon: 'now-ui-icons ui-1_settings-gear-63',
    component: Settings,
    layout: '/admin',
  },
  {
    path: '/customers',
    name: 'Customers',
    icon: 'now-ui-icons users_single-02',
    component: Customers,
    layout: '/admin',
  },
  {
    path: '/workforce',
    name: 'Workforce',
    icon: 'now-ui-icons business_badge',
    component: Workforce,
    layout: '/admin',
  },
  // {
  //   path: '/appointments',
  //   name: 'Appointments',
  //   icon: 'now-ui-icons ui-1_calendar-60',
  //   component: Calendar,
  //   layout: '/admin',
  // },
  // {
  //   path: '/invoicing',
  //   name: 'Invoicing',
  //   icon: 'now-ui-icons business_money-coins',
  //   component: Dashboard,
  //   layout: '/admin',
  // },
  {
    path: '/pricebook',
    name: 'Pricebook',
    icon: 'now-ui-icons education_paper',
    component: Pricebook,
    layout: '/admin',
  },
  {
    path: '/integrations',
    name: 'Integrations',
    icon: 'now-ui-icons objects_planet',
    component: IntegrationsPage,
    layout: '/admin',
  },
  {
    path: '/dealers',
    name: 'Dealers',
    icon: 'now-ui-icons business_briefcase-24',
    component: DealersPage,
    layout: '/admin',
  },
  {
    path: '/billing-Info',
    name: 'Plan and Billing',
    icon: 'now-ui-icons shopping_credit-card',
    component: BillingPage,
    layout: '/admin',
  },
  {
    path: '/support',
    name: 'Support',
    icon: 'now-ui-icons travel_info',
    component: Support,
    layout: '/admin',
  },
  {
    path: '/logout',
    name: 'Logout',
    icon: 'users_circle-08',
    layout: '/auth',
  },
];

export const templates = [
  {
    collapse: true,
    path: '/pages',
    name: 'Pages',
    state: 'openPages',
    icon: 'now-ui-icons design_image',
    views: [
      {
        path: '/timeline-page',
        name: 'Timeline Page',
        mini: 'TP',
        component: TimelinePage,
        layout: '/admin',
      },
      {
        path: '/user-page',
        name: 'User Profile',
        mini: 'UP',
        component: UserPage,
        layout: '/admin',
      },
      {
        path: '/rtl-support',
        name: 'RTL Support',
        mini: 'RS',
        component: RTL,
        layout: '/admin',
      },
      {
        path: '/register-page',
        name: 'Register Page',
        short: 'Register',
        mini: 'RP',
        component: RegisterPage,
        layout: '/auth',
      },
      {
        path: '/login-page',
        name: 'Login Page',
        short: 'Login',
        mini: 'LP',
        component: LoginPage,
        layout: '/auth',
      },
      {
        path: '/restore-page',
        name: 'Restore Page',
        short: 'Restore',
        mini: 'RP',
        component: RestorePage,
        layout: '/auth',
      },
      {
        path: '/pricing-page',
        name: 'Pricing Page',
        short: 'Pricing',
        mini: 'PP',
        component: PricingPage,
        layout: '/auth',
      },
      {
        path: '/lock-screen-page',
        name: 'Lock Screen Page',
        short: 'Lock',
        mini: 'LSP',
        component: LockScreenPage,
        layout: '/auth',
      },
    ],
  },
  {
    collapse: true,
    path: '/components',
    name: 'Components',
    state: 'openComponents',
    icon: 'now-ui-icons education_atom',
    views: [
      {
        path: '/buttons',
        name: 'Buttons',
        mini: 'B',
        component: Buttons,
        layout: '/admin',
      },
      {
        path: '/grid-system',
        name: 'Grid System',
        mini: 'GS',
        component: GridSystem,
        layout: '/admin',
      },
      {
        path: '/panels',
        name: 'Panels',
        mini: 'P',
        component: Panels,
        layout: '/admin',
      },
      {
        path: '/sweet-alert',
        name: 'Sweet Alert',
        mini: 'SA',
        component: SweetAlert,
        layout: '/admin',
      },
      {
        path: '/notifications',
        name: 'Notifications',
        mini: 'N',
        component: Notifications,
        layout: '/admin',
      },
      {
        path: '/icons',
        name: 'Icons',
        mini: 'I',
        component: Icons,
        layout: '/admin',
      },
      {
        path: '/typography',
        name: 'Typography',
        mini: 'T',
        component: Typography,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    path: '/forms',
    name: 'Forms',
    state: 'openForms',
    icon: 'now-ui-icons design_bullet-list-67',
    views: [
      {
        path: '/regular-forms',
        name: 'Regular Forms',
        mini: 'RF',
        component: RegularForms,
        layout: '/admin',
      },
      {
        path: '/extended-forms',
        name: 'Extended Forms',
        mini: 'EF',
        component: ExtendedForms,
        layout: '/admin',
      },
      {
        path: '/validation-forms',
        name: 'Validation Forms',
        mini: 'VF',
        component: ValidationForms,
        layout: '/admin',
      },
      {
        path: '/wizard',
        name: 'Wizard',
        mini: 'W',
        component: Wizard,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    path: '/tables',
    name: 'Tables',
    state: 'openTables',
    icon: 'now-ui-icons files_single-copy-04',
    views: [
      {
        path: '/regular-tables',
        name: 'Regular Tables',
        mini: 'RT',
        component: RegularTables,
        layout: '/admin',
      },
      {
        path: '/extended-tables',
        name: 'Extended Tables',
        mini: 'ET',
        component: ExtendedTables,
        layout: '/admin',
      },
      {
        path: '/react-table',
        name: 'React Table',
        mini: 'RT',
        component: ReactTable,
        layout: '/admin',
      },
    ],
  },
  {
    collapse: true,
    path: '/maps',
    name: 'Maps',
    state: 'openMaps',
    icon: 'now-ui-icons location_pin',
    views: [
      {
        path: '/google-maps',
        name: 'Google Maps',
        mini: 'GM',
        component: GoogleMaps,
        layout: '/admin',
      },
      {
        path: '/full-screen-maps',
        name: 'Full Screen Map',
        mini: 'FSM',
        component: FullScreenMap,
        layout: '/admin',
      },
      {
        path: '/vector-maps',
        name: 'Vector Map',
        mini: 'VM',
        component: VectorMap,
        layout: '/admin',
      },
    ],
  },
  {
    path: '/widgets',
    name: 'Widgets',
    icon: 'now-ui-icons objects_diamond',
    component: Widgets,
    layout: '/admin',
  },
  {
    path: '/charts',
    name: 'Charts',
    icon: 'now-ui-icons business_chart-pie-36',
    component: Charts,
    layout: '/admin',
  },
  {
    path: '/calendar',
    name: 'Calendar',
    icon: 'now-ui-icons media-1_album',
    component: Calendar,
    layout: '/admin',
  },
];

export default drawerRoutes;
