/* eslint-disable react/destructuring-assignment */
import React from 'react';
import ReactTable from 'react-table';
import { Card, CardBody, Row, Col, Button, FormGroup, Label, Input, Alert } from 'reactstrap';
import { observer } from 'mobx-react';
import { getSalespersonList } from 'lib/api/salespersonApi';
import PanelHeader from 'components/PanelHeader/PanelHeader';
import { LabelInput, CardTitle, Label as LabelWrapper, StateDropdown } from 'components';
import { colors } from 'theme';
import { EMAIL_REG_EX, NUMBER_REG_EX } from 'utils/regularExpressions';
import { createCustomer, editCustomer, getCustomerClimateZone } from 'lib/api/customersApi';
import authService from 'lib/services/authService';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stateList as STATE_LIST } from 'variables/general';
import config from '../../config';
import store from '../../store';
import defaultFilter from '../../utils/tableFilter';

const REQUIRED_FIELDS = ['firstName', 'email', 'phone', 'city', 'climateZone', 'address', 'state', 'zip'];

const stripePromise = loadStripe(config.stripeKey);
class AddEditCustomer extends React.Component {
  constructor(props) {
    super(props);
    const customer = props.location?.state?.customer ? JSON.parse(props.location?.state?.customer) : {};
    const stateList = store.settings.stateList?.length > 0 ? store.settings.stateList : STATE_LIST;
    this.state = {
      isLoading: false,
      errorMessage: '',
      data: [],
      selectedSalesmanId: customer.salesman?.id || null,
      showSuccessModal: false,
      showErrorStripeModal: false,
      firstName: customer.firstName || '',
      firstNameState: customer.firstName || '',
      lastName: customer.lastName || '',
      lastNameState: customer.lastName || '',
      phone: customer.phone || '',
      phoneState: customer.phone || '',
      address: customer.address || '',
      addressState: customer.address || '',
      email: customer.email || '',
      emailState: customer.email || '',
      city: customer.city || '',
      cityState: customer.city || '',
      state: customer.state || '',
      zip: customer.zip || '',
      zipState: customer.zip || '',
      climateZone: customer.climateZone?.id || '',
      climateZoneState: customer.climateZone?.id || '',
      stationName: customer.climateZone?.ashraeStationName || '',
      stationNameState: customer.climateZone?.ashraeStationName || '',
      stationNumber: customer.climateZone?.ashraeStationNumber || '',
      stationNumberState: customer.climateZone?.ashraeStationNumber || '',
      notes: customer.notes || '',
      notesState: customer.notes || '',
      customerId: customer.id || '',
      previewInvoiceModal: false,
      isRejectActiveSalesmen: false,
      activateNeedInfoLoading: false,
      showRejectModal: false,
      showRejectModalBilling: false,
      isEditMode: !!customer.id,
      stateList,
    };
  }

  componentDidMount() {
    const { data: company, fetchCompany, fetchBillingInfo, billingInfo } = store.company;
    const { states, fetchStates } = store.settings;
    if (!states?.length) {
      fetchStates(null, {
        done: () => this.setState({ stateList: store.settings.stateList }),
      });
    }
    this.setState({ isLoading: true }, () => {
      this.fetchSalespersonList();
    });
    if (!billingInfo?.paymentMethod) {
      fetchBillingInfo();
    }

    if (!company?.id?.length) {
      fetchCompany();
    }
  }

  fetchSalespersonList = () => {
    const { companyId } = authService.getUser();
    return getSalespersonList(companyId)
      .then(({ salesmanList }) => {
        const data = salesmanList.map(({ avatarUrl, name, phone, userName, id, color, isActive, email }) => {
          return {
            avatarUrl,
            name,
            phone,
            username: userName,
            email,
            id,
            color,
            isActive,
          };
        });
        data.unshift({ name: 'Unassigned', id: null });
        this.setState({
          isLoading: false,
          data,
        });
      })
      .catch(() => this.setState({ isLoading: false }));
  };

  validateFields = () => {
    const { phone, email } = this.state;

    const errors = {};

    REQUIRED_FIELDS.forEach(key => {
      errors[`${key}State`] = this.state[key] ? '' : 'has-danger';
    });

    errors.emailState = EMAIL_REG_EX.test(email) ? '' : 'has-danger';
    errors.phoneState = NUMBER_REG_EX.test(phone) ? '' : 'has-danger';

    this.setState({
      ...errors,
    });

    if (Object.values(errors).some(value => !!value)) {
      return false;
    }

    return true;
  };

  getClimateZone = () => {
    getCustomerClimateZone(this.state.zip).then(response => {
      if (response)
        this.setState({
          climateZone: response.id,
          climateZoneState: response.id,
          stationName: response.ashraeStationName,
          stationNameState: response.ashraeStationName,
          stationNumber: response.ashraeStationNumber,
          stationNumberState: response.ashraeStationNumber,
        });
    });
  };

  onSubmit = event => {
    event.preventDefault();
    const { data: company, billingLoading } = store.company;
    if (!company.id?.length || billingLoading) {
      return;
    }

    if (!this.validateFields()) {
      return;
    }

    const {
      firstName,
      lastName,
      address,
      city,
      zip,
      // climateZone,
      state,
      notes,
      phone,
      email,
      isEditMode,
      customerId,
      selectedSalesmanId,
    } = this.state;

    const data = {
      firstName,
      lastName,
      line1: address,
      email,
      phone,
      city,
      zip,
      // climateZone,
      state,
      notes,
      salesmanId: selectedSalesmanId,
    };

    this.setState({
      isLoading: true,
      errorMessage: '',
    });
    const errorHandler = () => this.setState({ errorMessage: 'Save error', isLoading: false });
    if (isEditMode) {
      data.customerId = customerId;
      editCustomer(data)
        .then(() => {
          this.setState(
            {
              isLoading: false,
            },
            () => this.props.history.push('/admin/customers')
          );
        })
        .catch(errorHandler);
    } else {
      createCustomer(data)
        .then(() => {
          this.setState(
            {
              isLoading: false,
            },
            () => this.props.history.push('/admin/customers')
          );
        })
        .catch(errorHandler);
    }
  };

  // eslint-disable-next-line react/sort-comp
  typeEmail(e) {
    const emailState = EMAIL_REG_EX.test(e.target.value) ? 'has-success' : 'has-danger';

    this.setState({ email: e.target.value, emailState });
  }

  typeNumber(e, field) {
    const numberRex = NUMBER_REG_EX;
    const newValues = {};
    newValues[field] = e.target.value;
    if (numberRex.test(newValues[field])) {
      newValues[`${field}State`] = 'has-success';
    } else {
      newValues[`${field}State`] = 'has-danger';
    }
    this.setState({ ...newValues });
  }

  typeInput(e, field, required) {
    const newValues = {};
    newValues[field] = e.target.value;
    if (newValues[field]) {
      newValues[`${field}State`] = 'has-success';
    } else {
      newValues[`${field}State`] = required ? 'has-danger' : '';
    }
    this.setState({ ...newValues });
  }

  selectState(stateIndex) {
    const { stateList } = this.state;
    this.setState({ state: stateList[stateIndex] });
  }

  getErrorMessage = e =>
    e.errors && typeof e.errors === 'object' && Object.keys(e.errors)?.length
      ? Object.keys(e.errors).map(key => `${key}: ${e.errors[key]}\n`)
      : e.message;

  renderStatus = isActive => (
    // we've added some custom button actions
    // use this button to add a like kind of action
    <Button disabled className="btn-icon btn-round" color={isActive ? 'success' : 'default'} size="sm" />
  );

  render() {
    const {
      firstName,
      lastName,
      firstNameState,
      lastNameState,
      phone,
      phoneState,
      email,
      emailState,
      address,
      addressState,
      state,
      zip,
      zipState,
      climateZone,
      climateZoneState,
      notes,
      notesState,
      city,
      cityState,
      isLoading,
      errorMessage,
      selectedSalesmanId,
      data,
      stateList,
      stationNumber,
      stationNumberState,
      stationName,
      stationNameState,
    } = this.state;

    const { history } = this.props;

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card className="card-stats card-raised" style={{ backgroundColor: colors.lightBlue }}>
                <form onSubmit={this.onSubmit}>
                  <CardBody style={{ padding: '2vw' }}>
                    <Row>
                      <Col lg={6} md={12} xs={12}>
                        <CardTitle>Customer Information</CardTitle>
                        <div className="form-row">
                          <LabelInput
                            className={`col-md-12 col-xs-12 has-label ${firstNameState}`}
                            label="First Name"
                            id="firstName"
                            onChange={e => this.typeInput(e, 'firstName', true)}
                            value={firstName}
                            validationClass={firstNameState}
                            required
                          />
                        </div>
                        <div className="form-row">
                          <LabelInput
                            className={`col-md-12 col-xs-12 has-label ${lastNameState}`}
                            label="Last Name"
                            id="lastName"
                            onChange={e => this.typeInput(e, 'lastName', true)}
                            value={lastName}
                            validationClass={lastNameState}
                          />
                        </div>
                        <div className="form-row">
                          <LabelInput
                            className={`col-md-12 col-xs-12 has-label ${addressState}`}
                            label="Address"
                            id="address"
                            autoComplete="off"
                            onChange={e => this.typeInput(e, 'address', true)}
                            validationClass={addressState}
                            value={address}
                            required
                          />
                        </div>
                        <div className="form-row">
                          <LabelInput
                            className={`col-md-12 col-xs-12 has-label ${cityState}`}
                            label="City"
                            id="city"
                            autoComplete="off"
                            onChange={e => this.typeInput(e, 'city', true)}
                            validationClass={cityState}
                            value={city}
                            required
                          />
                        </div>
                        <div className="form-row">
                          <LabelWrapper
                            className={`col-md-12 col-xs-12 has-label ${state}`}
                            label="State"
                            id="state"
                            required
                          >
                            <StateDropdown
                              state={state || 'Select state'}
                              stateList={stateList}
                              toggleStyle={{ maxWidth: '350px' }}
                              onChange={index => this.selectState(index)}
                            />
                          </LabelWrapper>
                        </div>
                        <div className="form-row">
                          <LabelInput
                            className={`col-md-12 col-xs-12 has-label ${zipState}`}
                            label="Zip code"
                            id="zip"
                            autoComplete="off"
                            onChange={e => this.typeNumber(e, 'zip')}
                            validationClass={zipState}
                            value={zip}
                            onBlur={this.getClimateZone}
                            required
                          />
                        </div>
                        <div className="form-row">
                          <LabelInput
                            className={`col-md-12 col-xs-12 has-label ${phoneState}`}
                            label="Phone"
                            id="phone"
                            onChange={e => this.typeNumber(e, 'phone')}
                            validationClass={phoneState}
                            value={phone}
                            required
                          />
                        </div>
                        <div className="form-row">
                          <LabelInput
                            className={`col-md-12 col-xs-12 has-label ${emailState}`}
                            label="Email Address"
                            id="email"
                            autoComplete="off"
                            onChange={e => this.typeEmail(e)}
                            type="email"
                            validationClass={emailState}
                            value={email}
                            required
                          />
                        </div>
                        <div className="form-row">
                          <LabelInput
                            className={`col-md-12 col-xs-12 has-label ${climateZoneState}`}
                            label="Climate zone"
                            id="climateZone"
                            autoComplete="off"
                            onChange={e => this.typeNumber(e, 'climateZone')}
                            // validationClass={climateZoneState}
                            disabled
                            value={climateZone}
                            required
                          />
                        </div>
                        <div className="form-row">
                          <LabelInput
                            className={`col-md-12 col-xs-12 has-label ${stationNameState}`}
                            label="Station name"
                            id="stationName"
                            autoComplete="off"
                            onChange={e => this.typeInput(e, 'stationName')}
                            // validationClass={climateZoneState}
                            disabled
                            value={stationName}
                            required
                          />
                        </div>
                        <div className="form-row">
                          <LabelInput
                            className={`col-md-12 col-xs-12 has-label ${stationNumberState}`}
                            label="Station Number"
                            id="stationNumber"
                            autoComplete="off"
                            onChange={e => this.typeInput(e, 'stationNumber')}
                            // validationClass={climateZoneState}
                            disabled
                            value={stationNumber}
                            required
                          />
                        </div>
                        <div className="form-row">
                          <LabelInput
                            className={`col-md-12 col-xs-12 has-label ${notesState}`}
                            label="Notes"
                            id="notes"
                            autoComplete="off"
                            onChange={e => this.typeInput(e, 'notes', false)}
                            validationClass={notesState}
                            value={notes}
                          />
                        </div>
                        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                        <div className="col-md-12 col-xs-12">
                          {/* <Row> */}
                          <div>
                            <Button
                              disabled={isLoading}
                              onClick={() => history.push('/admin/customers')}
                              color="danger"
                              className="pull-left"
                            >
                              Cancel
                            </Button>
                          </div>
                          <div>
                            <Button disabled={isLoading} type="submit" color="info" className="pull-right">
                              Save
                            </Button>
                          </div>
                          {/* </Row> */}
                        </div>
                      </Col>
                      <Col lg={6} md={12} xs={12}>
                        <CardTitle>Salesman Selection</CardTitle>
                        <ReactTable
                          data={data}
                          filterable
                          loading={isLoading}
                          defaultFilterMethod={defaultFilter}
                          columns={[
                            {
                              Header: '',
                              accessor: '',
                              Cell: row => {
                                return (
                                  <div style={{ marginLeft: 30, marginBottom: 20 }}>
                                    <Input
                                      type="checkbox"
                                      checked={row?.value.id === selectedSalesmanId}
                                      onClick={e => {
                                        if (data.some(s => s.id === row?.value.id)) {
                                          this.setState({ selectedSalesmanId: row?.value.id });
                                        }
                                      }}
                                    />
                                  </div>
                                );
                              },
                              sortable: false,
                              filterable: false,
                              resizable: false,
                              width: 40,
                            },
                            {
                              Header: 'PROFILE',
                              accessor: 'image',
                              Cell: row => {
                                return (
                                  <div>
                                    <img
                                      alt=""
                                      height={34}
                                      src={
                                        row.original.avatarUrl ? `${config.imagesUrl}${row.original.avatarUrl}` : null
                                      }
                                    />
                                  </div>
                                );
                              },
                              sortable: false,
                              filterable: false,
                            },
                            {
                              Header: 'NAME',
                              accessor: 'name',
                            },
                            {
                              Header: 'USERNAME',
                              accessor: 'username',
                            },
                            {
                              Header: 'STATUS',
                              accessor: 'isActive',
                              Cell: row => this.renderStatus(row.original.isActive),
                              sortable: false,
                              filterable: false,
                            },
                          ]}
                          showPageSizeOptions={false}
                          defaultPageSize={8}
                          showPaginationTop
                          showPaginationBottom={false}
                          className="-striped -highlight"
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </form>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default observer(AddEditCustomer);
