import React, { useEffect, useState } from 'react';
import { Card, Row, Col, CardBody } from 'reactstrap';
import PanelHeader from 'components/PanelHeader/PanelHeader';
import { colors } from 'theme';
import { observer } from 'mobx-react';
import { CardTitle } from '../../components';
import { getAnnouncementList } from '../../lib/api/AnnouncementApi';
import AnnouncementItem from './AnnouncementItem';
import image from '../../assets/img';

const styles = {
  card: { minHeight: 200 },
  marblShapeContainer: {
    position: 'absolute',
    right: '10px',
  },
  marblShape: { alignItems: 'center', flexDirection: 'column', width: '150px', height: 'auto' },
  container: { backgroundColor: colors.lightBlue, paddingLeft: '3%', paddingRight: '3%' },
  clickable: { overflow: 'hidden', cursor: 'pointer' },
  button: { marginLeft: '15px', width: '220px' },
};

const Dashboard = observer(() => {
  const [announcements, setAnnouncements] = useState([]);
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    getAnnouncementList()
      .then(response => {
        const _announcements =
          response.announcements.WebPortal?.sort((a, b) =>
            new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime() ? 1 : -1
          ) || [];
        const _notes =
          response.announcements.Notes?.sort((a, b) =>
            new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime() ? 1 : -1
          ) || [];
        setAnnouncements(_announcements);
        setNotes(_notes);
      })
      .catch(e => {});
  }, []);

  const renderAnnouncements = announcements.map(item => AnnouncementItem(item));
  const renderNotes = notes.map(item => AnnouncementItem(item));

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col xs={12} md={12} style={{ fontSize: '1.1428em' }}>
            <Card className="card-stats card-raised" style={styles.card}>
              <CardBody>
                <div style={styles.marblShapeContainer}>
                  <div style={styles.marblShape}>
                    <img src={image.marblShape} alt="" />
                    <h3 className="text-center" style={{ color: colors.blue }}>
                      marbl
                    </h3>
                  </div>
                </div>
              </CardBody>

              <Row className="pl-5 pb-5 pr-5">
                <Col xs={12} md={7} style={{ fontSize: '1.1428em' }}>
                  {/* {announcements.length > 0 && ( */}
                  <>
                    <CardTitle style={{ color: colors.blue, fontSize: '1.2857em', fontWeight: '500' }}>
                      Announcements:
                    </CardTitle>
                    {renderAnnouncements}
                  </>
                  {/* )} */}
                  {notes.length > 0 && (
                    <>
                      <CardTitle style={{ color: colors.blue, fontSize: '1.2857em', fontWeight: '500' }}>
                        Notes:
                      </CardTitle>
                      {renderNotes}
                    </>
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
});

export default Dashboard;
