import React from 'react';
import { Row, Col, Input } from 'reactstrap';
import { colors } from 'theme';
import NumberFormat from 'react-number-format';
import { getPriceBuilder, editPriceBuilder } from 'lib/api/proposalApi';
import shallowEqual from 'lib/shallowEqual';
import { PRICE_BUILDER_INITIAL_STATE } from './constants';
import { checkZeroFormat } from './helpers';
import { NUM_DEC_REG_EX } from '../../utils/regularExpressions';

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalInstallationLabor: 0,
      totalCrewCostWithTruck: 0,
      form: {},
      initialState: {},
      errors: {},
    };
    this.totalInstallation = {};
    this.fetchPriceBuilderDetails();
  }

  fetchPriceBuilderDetails = () => {
    return getPriceBuilder().then(({ priceBuilder }) => {
      const stringPB = { ...priceBuilder };
      Object.keys(stringPB).forEach(key => {
        stringPB[key] = checkZeroFormat(stringPB[key]);
      });

      this.setState(prevState => ({
        form: {
          ...prevState.form,
          ...stringPB,
        },
        initialState: priceBuilder || {},
        totalInstallationLabor: priceBuilder ? priceBuilder.totalInstallationLabor : 0,
        totalCrewCostWithTruck: priceBuilder ? priceBuilder.totalCrewCostWithTruck : 0,
      }));
    });
  };

  renderInputC = (title, name, inputType, placeholder, min, max, step = 0.01) => {
    const { form, errors } = this.state;
    return (
      <Row style={{ flex: 1 }}>
        <Col style={{ flex: 3, fontSize: '0.8571em' }}>{title}</Col>
        <Col style={{ flex: 1 }}>
          <Input
            id={title}
            name={name}
            step={step}
            className="form-control-sm"
            type={inputType}
            style={errors[name] ? { borderColor: colors.red } : {}}
            placeholder={placeholder}
            min={min}
            max={max}
            onChange={this.onChange}
            value={form[name]}
          />
        </Col>
      </Row>
    );
  };

  submitForm = () => {
    const { form, totalInstallationLabor, totalCrewCostWithTruck } = this.state;
    const numForm = { ...form };
    Object.keys(numForm).forEach(key => {
      numForm[key] = parseFloat(numForm[key]);
    });
    editPriceBuilder({ ...numForm, totalInstallationLabor, totalCrewCostWithTruck });
  };

  isValidated = () => {
    const { form, initialState } = this.state;
    const errors = {};
    let isValid = true;

    Object.keys(PRICE_BUILDER_INITIAL_STATE).forEach(key => {
      const value = form[key];

      if (PRICE_BUILDER_INITIAL_STATE[key].isStrictInequality) {
        errors[key] = value && parseFloat(value) > 0 ? '' : 'has-danger';
      } else {
        errors[key] = value && parseFloat(value) >= 0 ? '' : 'has-danger';
      }

      if (errors[key]) {
        isValid = false;
      }
    });

    this.setState({ errors });

    if (isValid && !shallowEqual(form, initialState)) {
      this.submitForm();
    }

    return isValid;
  };

  onChange = ({ target }) => {
    const { name, value } = target;
    const { form } = this.state;
    form[name] = value.replace(NUM_DEC_REG_EX, '');

    const { leadLaborRate, helperLaborRate, billableEfficiencyPercent, averageMilesRoundTrip, vehicleCostPerMile, hourlyTruckCharge, laborOverheadPercent } = form;
    const totalInstallationLabor =
      parseFloat(billableEfficiencyPercent) > 0 ? (parseFloat(leadLaborRate) + parseFloat(helperLaborRate)) / (parseFloat(billableEfficiencyPercent) / 100) : 0;
    const totalCrewCostWithTruck =
      totalInstallationLabor * (parseFloat(laborOverheadPercent || 0, 10) / 100) +
      totalInstallationLabor +
      (parseFloat(averageMilesRoundTrip || 0, 10) * parseFloat(vehicleCostPerMile || 0)) / 8 +
      parseFloat(hourlyTruckCharge || 0);
    this.setState(prevState => ({
      form,
      errors: {
        ...prevState.errors,
        [name]: '',
      },
      totalInstallationLabor: (totalInstallationLabor && totalInstallationLabor.toFixed(3)) || 0,
      totalCrewCostWithTruck: (totalCrewCostWithTruck && totalCrewCostWithTruck.toFixed(3)) || 0,
    }));
  };

  onBlur = name => {
    const { form } = this.state;
    this.setState(prev => ({
      form: {
        ...prev.form,
        [name]: checkZeroFormat(form[name]),
      },
    }));
  };

  renderInput = (title, inputName, valueType, placeholder) => {
    const { form, errors } = this.state;
    const currentValue = form[inputName];

    const inputProps = {
      id: title,
      name: inputName,
      placeholder,
    };

    if (valueType === 'percent') {
      inputProps.suffix = '%';
    }
    if (valueType === 'dollar') {
      inputProps.prefix = '$';
    }

    return (
      <Row style={{ flex: 1 }}>
        <Col style={{ flex: 3, fontSize: '0.8571em' }}>{title}</Col>
        <Col style={{ flex: 1 }}>
          <NumberFormat
            {...inputProps}
            className="form-control-sm form-control"
            thousandSeparator
            decimalSeparator="."
            decimalScale={2}
            onBlur={() => this.onBlur(inputName, currentValue)}
            style={errors[inputName] ? { borderColor: colors.red } : {}}
            value={currentValue}
            onChange={this.onChange}
          />
        </Col>
      </Row>
    );
  };

  render() {
    const { totalInstallationLabor, totalCrewCostWithTruck } = this.state;
    return (
      <Row>
        <Col>
          <form onChange={this.calcTotalInstLabor}>
            <h6 className="info-text">Installation Labor</h6>
            {this.renderInput('Lead Labor Rate', 'leadLaborRate', 'dollar', '$0.00')}
            {this.renderInput('Helper Labor Rate', 'helperLaborRate', 'dollar', '$0.00')}
            {this.renderInput('Billable Efficiency', 'billableEfficiencyPercent', 'percent', '0.00%')}
            {this.renderInput('Labor Overhead', 'laborOverheadPercent', 'percent', '0.00%')}
            <br />
            <Row style={{ flex: 1 }}>
              <Col style={{ flex: 3 }}>Total Installation Labor</Col>
              <Col style={{ flex: 1, textAlign: 'right' }}>${Number(totalInstallationLabor).toFixed(2)}</Col>
            </Row>
          </form>
          <br />
          Consumer Financing Cost
          {this.renderInput('Finance Fee To Contractor', 'financeFeeToContractor', 'percent', '0.00%')}
          <br />
          Profit Margin
          {this.renderInput('Installation Department Overhead %', 'installationDepartmentOverheadPercent', 'percent', '0.00%')}
          {this.renderInput('Sales Commission % of Sale', 'salesCommissionPercent', 'percent', '0.00%')}
          {this.renderInput('Target Net Profit %', 'targetNetProfitPercent', 'percent', '0.00%')}
          <br />
        </Col>
        <Col>
          <h6 className="info-text">Vehicle/Miscellaneous</h6>
          {this.renderInput('Material Sales Tax %', 'materialSalesTaxPercent', 'percent', '0.00%')}
          {this.renderInput('Average Miles Round Trip', 'averageMilesRoundTrip', 'multiple', '0.00')}
          {this.renderInput('Vehicle Cost Per Mile', 'vehicleCostPerMile', 'dollar', '$0.00')}
          {this.renderInput('Hourly Truck Charge', 'hourlyTruckCharge', 'dollar', '0.00')}
          {this.renderInput('Risk & Proficiency & Warranty %', 'riskAndProficiencyAndWarrantyPercent', 'percent', '0.00%')}
          <br />
          Electrical Subcontractor
          {this.renderInput('Electrical Subcontractor Per Indoor', 'electricalSubcontractorPerIndoor', 'dollar', '$0.00')}
          {this.renderInput('Electrical Subcontractor Per Outdoor', 'electricalSubcontractorPerOutdoor', 'dollar', '$0.00')}
          <br />
          <Row style={{ flex: 1 }}>
            <Col style={{ flex: 3 }}>Total Crew Cost with Truck</Col>
            <Col style={{ flex: 1, textAlign: 'right' }}>${Number(totalCrewCostWithTruck).toFixed(2)}</Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default Step1;
