import React from 'react';
import {Col, Row, Spinner} from 'reactstrap';
import {withRouter} from 'react-router';
import {MarblButton} from '../index';
import {colors} from '../../theme';
import config from '../../config';

// eslint-disable-next-line import/prefer-default-export
export const ProductItem = withRouter(props => {
    const {items, history, isLoading} = props;

    return (
        <>
            {!items.length ? (
                <div className="w-100 text-center">No companies</div>
            ) : (
                <>
                    {items.map(user => (
                        <Col className="custom-xl" key={user.id} xs={12} sm={6} md={4}>
                            <MarblButton
                                url={user.logoUrl && `${config.imagesUrl}${user.logoUrl}`}
                                onClick={() => {
                                    history.push(`/super-admin/company/${user.id}`);
                                }}
                                fixedSize="160px"
                                label={user.displayName}
                            />
                            <Row>
                                <Col style={{fontSize: '0.8571em', maxWidth: '160px'}} className="p-0 m-auto ">
                                    {/* <div className="p-2 m-0"> */}
                                    {/*    <Row className="icon icon-primary" style={{borderTop: '1px solid #dee2e6'}}> */}
                                    {/*        <span className="col-6 pr-1 pl-0 text-right"> */}
                                    {/*            <i className="now-ui-icons ui-2_chat-round"/> */}
                                    {/*            &nbsp; YTD */}
                                    {/*        </span> */}
                                    {/*        <span className="col-6">${user.ytd}</span> */}
                                    {/*    </Row> */}
                                    {/* </div> */}
                                    {/* <div className="p-2 m-0"> */}
                                    {/*    <Row className="icon icon-success" style={{borderTop: '1px solid #dee2e6'}}> */}
                                    {/*        <span className="col-6 pr-1 pl-0  text-right"> */}
                                    {/*            <i className="now-ui-icons business_money-coins"/> */}
                                    {/*            &nbsp; MTD */}
                                    {/*        </span> */}
                                    {/*        <span className="col-6">${user.mtd}</span> */}
                                    {/*    </Row> */}
                                    {/* </div> */}
                                    {/* <div className="p-2 m-0"> */}
                                    {/*    <Row className="icon icon-info" style={{borderTop: '1px solid #dee2e6'}}> */}
                                    {/*        <span className="col-6 pr-1 pl-0 text-right"> */}
                                    {/*            <i className="now-ui-icons users_single-02"/> */}
                                    {/*            &nbsp; AVG */}
                                    {/*        </span> */}
                                    {/*        <span className="col-6">${user.avg}</span> */}
                                    {/*    </Row> */}
                                    {/* </div> */}
                                    <div style={{color: colors.gray}}>
                                        <div className="p-2 m-0">
                                            <Row style={{borderTop: '1px solid #dee2e6'}}>
                                                <span className="col-6 p-1 pl-0 text-right">Id: </span>
                                                <span className="col-6 p-1">{user.shortId}</span>
                                            </Row>
                                            <Row style={{borderTop: '1px solid #dee2e6'}}>
                                                <span className="col-6 p-1 pl-0 text-right">Address: </span>
                                                <span className="col-6 p-1">
                                                    {user.line1}, {user.city}, {user.state}, {user.zip}
                                                </span>
                                            </Row>
                                        </div>
                                        <div className="p-2 m-0">
                                            <Row style={{borderTop: '1px solid #dee2e6'}}>
                                                <span className="col-6 p-1 pl-0 text-right">Phone: </span>
                                                <span className="col-6 p-1">{user.phone1 || 'is not specified'}</span>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    ))
                    }
                    {isLoading ? <Row className='w-100'>
                        <Spinner color="primary" className="m-auto"/>
                    </Row> : null}
                </>
            )}
        </>
    );
});
