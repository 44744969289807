import React from 'react';
import {
    Button,
    Card,
    CardBody, CardFooter,
    CardHeader,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
} from 'reactstrap';
import image from "../../../assets/img";
import {resetPassword} from "../../../lib/api/authApi";

class EnterNewPasswordForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            passwordState: '',
            confirmPassword: '',
            confirmPasswordState: '',
            errorState: '',
            errorsState: {},
            isInProgress: false,
            passwordFocus: false,
            confirmPasswordFocus: false,
        };
    }

    handleOnChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    checkAllFields = () => {
        const { password, confirmPassword } = this.state;

        const errors = {};

        errors.passwordState = !password ? 'has-danger' : '';
        errors.confirmPasswordState = !confirmPassword ? 'has-danger' : '';

        if(password !== confirmPassword){
            errors.passwordState = 'has-danger';
            errors.confirmPasswordState = 'has-danger';
            this.setState({
                errorState: 'Passwords must be equal',
                ...errors,
            });

            return password === confirmPassword;
        }

        const isValid = password && confirmPassword;

        this.setState({
            errorState: !isValid ? 'All field are required' : '',
            ...errors,
        });

        return isValid;
    };

    onSubmit = (event) => {
        event.preventDefault();

        if (!this.checkAllFields()) {
            return;
        }
        this.setState({isInProgress: true}, () =>{
            const { email, username, code} = this.props
            const { password } = this.state
            resetPassword({
                email,
                username,
                code,
                password
            }).then(() => {
                this.setState({errorState: '',errorsState: {}, isInProgress: false})
                this.props.nextStep()
            }).catch(error => {
                console.log('restore error', error);
                if(error.errors){
                    this.setState({errorsState: error?.errors, isInProgress: false})
                }else {
                    this.setState({errorState: error?.message, isInProgress: false})
                }
            });
        })
    }

    renderErrors = () => {
        const {errorsState} = this.state;
        const errors = Object.keys(errorsState)
        return errors.map(error =><Label style={{ color: 'red' }}>{errorsState[error]}</Label>)
    }

    render() {
        const { email, username, goToLoginPage } = this.props;
        const { confirmPasswordFocus, passwordState,  confirmPasswordState, errorState, isInProgress, passwordFocus, errorsState} = this.state;

        return (
            <Form onSubmit={this.onSubmit}>
                <Card className="card-login card-plain">
                    <CardHeader>
                        <div className="logo-container">
                            <img src={image.logo} alt="now-logo" />
                        </div>
                        <div className='login-header'>Please enter new password</div>
                    </CardHeader>
                    <CardBody>
                        <InputGroup
                            className={`no-border form-control-lg`}
                        >
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText style={{'backgroundColor': 'rgba(0, 0, 0, 0.2)'}}>
                                    <i className="now-ui-icons users_circle-08" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                style={{'backgroundColor': 'rgba(0, 0, 0, 0.2)'}}
                                value={username}
                                type="text"
                                placeholder="username"
                                disabled={true}
                                name="username"
                            />
                        </InputGroup>
                        <InputGroup
                            className={`no-border form-control-lg`}
                        >
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText style={{'backgroundColor': 'rgba(0, 0, 0, 0.2)'}}>
                                    <i className="now-ui-icons business_briefcase-24" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                style={{'backgroundColor': 'rgba(0, 0, 0, 0.2)'}}
                                type="text"
                                value={email}
                                placeholder="email address"
                                disabled={true}
                                name="email"
                            />
                        </InputGroup>
                        <InputGroup
                            className={`no-border form-control-lg ${passwordState} ${
                                passwordFocus ? 'input-group-focus' : ''
                            }`}
                        >
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="now-ui-icons ui-1_lock-circle-open" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type="password"
                                placeholder="password"
                                onFocus={e => this.setState({ passwordFocus: true })}
                                onBlur={e => this.setState({ passwordFocus: false })}
                                onChange={this.handleOnChange}
                                name="password"
                            />
                        </InputGroup>
                        <InputGroup
                            className={`no-border form-control-lg ${confirmPasswordState} ${
                                confirmPasswordFocus ? 'input-group-focus' : ''
                            }`}
                        >
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="now-ui-icons ui-1_lock-circle-open" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type="password"
                                placeholder="confirm password"
                                onFocus={e => this.setState({ confirmPasswordFocus: true })}
                                onBlur={e => this.setState({ confirmPasswordFocus: false })}
                                onChange={this.handleOnChange}
                                name="confirmPassword"
                            />
                        </InputGroup>
                        <span className={'description-password'}>
                                Password must contain at least 8 characters, have upper case and lower case letters, number, special
                                character
                            </span>
                        {errorState && <Label style={{ color: 'red' }}>{errorState}</Label>}
                        {Object.keys(errorsState)?.length>0 && this.renderErrors()}
                    </CardBody>
                    <CardFooter>
                        <Button
                            onClick={this.onSubmit}
                            disabled={isInProgress}
                            block
                            color="info"
                            size="lg"
                            className="mb-3 btn-round"
                        >
                            Confirm
                        </Button>
                        <div
                            className='back-button mt-3'
                            onClick={goToLoginPage}
                        >
                            Back to Login page
                        </div>
                    </CardFooter>
                </Card>
            </Form>
        );
    }
}

export default EnterNewPasswordForm
