import {
  getCompanyDetails,
  getCompanyDealers,
  getCompanyUnits,
  getCompanyAncillaryParts,
  getCompanyAdjAncillaryParts,
} from 'lib/api/companyApi';
import { action, makeAutoObservable, observable } from 'mobx';
import authService from 'lib/services/authService';
import { getStripeAccountLink } from '../lib/api/subscriptionApi';
import { getBillingInformation } from '../lib/api/billingApi';
import { SalesmanService, Status, Location } from '../lib/services/salesmanService';
import { getSalespersonList } from '../lib/api/salespersonApi';

export default class CompanyStore {
  constructor(store) {
    makeAutoObservable(this, {
      data: observable,
      loading: observable,
      error: observable,
      billingInfo: observable,
      billingInfoSubType: observable,
      billingLoading: observable,
      billingError: observable,
      averageAncillaryCost: observable,
      adjustableAncillaryParts: observable,
      indoorUnits: observable,
      ancillaryParts: observable,
      fetchCompany: action,
      fetchBillingInfo: action,
      resetBillingError: action,
      updateAverageAncillaryCost: action,
      updateAdjAncillaryPart: action,
    });
    this.store = store;
  }

  activeSalesmans = {
    salesmanList: [],
    totalRecordCount: 0,
  };

  inactiveSalesmans = {
    salesmanList: [],
    totalRecordCount: 0,
  };

  activeSalesmansError = null;

  inActiveSalesmansError = null;

  selectedCompanyError = null;

  activeSalesmansLoading = false;

  inactiveSalesmansLoading = false;

  skipActiveCount = 0;

  skipInactiveCount = 0;

  takeCount = 10;

  data = {};

  isStripeConnected = false;

  loading = false;

  error = null;

  billingInfo = {};

  billingInfoSubType = {};

  billingLoading = false;

  billingError = null;

  indoorUnits = [];

  ancillaryParts = [];

  salesmanList = [];

  adjustableAncillaryParts = [];

  averageAncillaryCost = 0;

  fetchCompany = (params, effects) => {
    this.loading = true;
    effects?.started && effects.started(params);

    const { companyId } = authService.getUser();

    Promise.all([
      getCompanyDetails(),
      getCompanyDealers(),
      getCompanyUnits(),
      getCompanyAncillaryParts(),
      getCompanyAdjAncillaryParts(),
      getSalespersonList(companyId),
    ])
      .then(
        ([
          { company },
          { companyDealers },
          { groupedIndoorUnits },
          ancillaryParts,
          { averageCost, ancillaryPartCompanyCosts },
          { salesmanList },
        ]) => {
          const _company = {
            ...company,
            dealers: companyDealers,
          };
          this.data = _company;
          this.averageAncillaryCost = averageCost;
          this.adjustableAncillaryParts = ancillaryPartCompanyCosts;
          this.indoorUnits = groupedIndoorUnits;
          this.ancillaryParts = ancillaryParts;
          this.salesmanList = salesmanList;
          this.loading = false;
          this.error = null;
          if (company.stripeAccountId) {
            getStripeAccountLink()
              .then(() => {
                this.isStripeConnected = false;
                effects?.done && effects.done(params);
              })
              .catch(() => {
                this.isStripeConnected = true;
                effects?.done && effects.done(params);
              });
          } else {
            effects?.done && effects.done(params);
          }
        }
      )
      .catch(error => {
        this.loading = false;
        this.error = error;
        effects?.failed && effects.failed(params);
      });
  };

  fetchBillingInfo = (params, effects) => {
    this.billingLoading = true;
    effects?.started && effects.started(params);
    getBillingInformation(0)
      .then(billingInfoMonth => {
        this.billingInfo = billingInfoMonth.billingInfo;
        if (billingInfoMonth.billingInfo?.paymentMethod) {
          this.billingInfoSubType = null;
          this.billingLoading = false;
          this.billingError = null;
          effects?.done && effects.done(params);
        } else {
          getBillingInformation(1).then(billingInfoSubType => {
            this.billingInfoSubType = billingInfoSubType.billingInfo;
            this.billingLoading = false;
            this.billingError = null;
            effects?.done && effects.done(params);
          });
        }
      })
      .catch(error => {
        this.billingLoading = false;
        this.billingError = error;
        effects?.failed && effects.failed(params);
      });
  };

  resetBillingError = () => {
    this.billingError = null;
  };

  loadActiveSalesmans = async () => {
    this.activeSalesmansLoading = true;
    const { salesmanList } = this.activeSalesmans;
    const resData = await SalesmanService.getSalesmanssByStatus(
      Status.ACTIVE,
      this.skipActiveCount,
      this.takeCount,
      this.activeSalesmansError
    );
    this.activeSalesmans = resData;
    this.activeSalesmans.salesmanList = [...salesmanList, ...resData.salesmanList];
    this.skipActiveCount = this.skipActiveCount + this.takeCount;
    this.activeSalesmansLoading = false;
  };

  loadInActiveSalesmans = async () => {
    this.inactiveSalesmansLoading = true;
    const salesmanList = this.inactiveSalesmans.salesmanList.slice();
    const resData = await SalesmanService.getSalesmanssByStatus(
      Status.INACTIVE,
      this.skipInactiveCount,
      this.takeCount,
      this.inActiveSalesmansError
    );
    this.inactiveSalesmans = resData;
    this.inactiveSalesmans.salesmanList = [...salesmanList, ...resData.salesmanList];
    this.skipInactiveCount = this.skipInactiveCount + this.takeCount;
    this.inactiveSalesmansLoading = false;
  };

  loadData = () => {
    this.loadActiveSalesmans();
    this.loadInActiveSalesmans();
  };

  resetData = () => {
    this.skipActiveCount = 0;
    this.skipInactiveCount = 0;
    this.activeSalesmans = {
      salesmanList: [],
      totalRecordCount: 0,
    };

    this.inactiveSalesmans = {
      salesmanList: [],
      totalRecordCount: 0,
    };
  };

  uploadFewerActiveSalesmans = async () => {
    const rest = this.activeSalesmans.salesmanList.length - this.takeCount;
    if (rest > 10) {
      this.skipActiveCount = rest;
      this.activeSalesmans.salesmanList.splice(rest);
    } else {
      this.skipActiveCount = 10;
      this.activeSalesmans.salesmanList.splice(10);
    }
  };

  uploadFewerInActiveSalesmans = () => {
    this.skipInactiveCount = this.inactiveSalesmans.salesmanList.length - this.takeCount;
    this.inactiveSalesmans.salesmanList.splice(this.inactiveSalesmans.salesmanList.length - this.takeCount);
  };

  updateAverageAncillaryCost = averageAncillaryCost => {
    this.averageAncillaryCost = averageAncillaryCost;
  };

  updateAdjAncillaryPart = ({ ancillaryPartCompanyCostId, defaultQuantity }) => {
    const partIndex = this.adjustableAncillaryParts.findIndex(p => p.id === ancillaryPartCompanyCostId);
    if (partIndex > -1) {
      let part = this.adjustableAncillaryParts[partIndex];
      part = { ...part, defaultLength: defaultQuantity };
      this.adjustableAncillaryParts.splice(partIndex, 1, part);
    }
  };
}
