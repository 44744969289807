import authService from 'lib/services/authService';

import { postJSON, getJSON, putJSON } from './ajax';

export function createUser(data) {
  return postJSON('/api/companyadmin', data);
}

export function editUser(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return putJSON('/api/companyadmin', data, REQUEST_OPTIONS);
}

export function getUser() {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return getJSON('/api/companyadmin', REQUEST_OPTIONS);
}

export function changePassword(password) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return putJSON('/api/password', { password }, REQUEST_OPTIONS);
}
