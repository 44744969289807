import React from 'react';

// reactstrap components
import { Card, CardBody, Row, Col } from 'reactstrap';

// core components
import PanelHeader from 'components/PanelHeader/PanelHeader';

import { CardTitle } from 'components';
import { colors } from 'theme';
import image from '../../assets/img';

const styles = {
  marblShapeContainer: {
    position: 'absolute',
    right: '10px',
    // display: 'flex',
    // alignItems: 'flex-end',
    // justifyContent: 'flex-end',
    // width: '100%',
  },
  marblShape: { alignItems: 'center', flexDirection: 'column', width: '150px', height: 'auto' },
  iconsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '80px',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
};

const Support = () => {
  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card className="card-stats card-raised" style={{ paddingLeft: '20px', paddingBottom: '20px' }}>
              <CardBody>
                <div style={styles.marblShapeContainer}>
                  <div style={styles.marblShape}>
                    <img src={image.marblShape} alt="" />
                    <h3 className="text-center" style={{ color: colors.blue, fontSize: '2.2857em' }}>
                      marbl
                    </h3>
                  </div>
                </div>
                <CardTitle style={{ color: colors.blue, fontSize: '1.2857em', fontWeight: '600', marginTop: '20px' }}>
                  Contact Us
                </CardTitle>
                <div style={{ color: colors.blue }}>
                  Email:{' '}
                  <a href="mailto:customersupport@marblplatform.com" style={{ color: colors.blue, fontWeight: '600' }}>
                    customersupport@marblplatform.com
                  </a>
                </div>
                <div style={{ color: colors.blue }} className="mt-3">
                  Have a question? Need more information?{' '}
                  <a
                    href="https://marblplatform.com/contact"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: colors.blue, textDecoration: 'underline', fontWeight: '600' }}
                  >
                    Click here
                  </a>
                </div>
                <div style={styles.iconsContainer}>
                  <a href="https://www.facebook.com/marblplatform" target="_blank" rel="noopener noreferrer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill={colors.blue}
                      className="bi bi-facebook"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
                      />
                    </svg>
                  </a>
                  <a href="https://www.linkedin.com/company/marblplatform/" target="_blank" rel="noopener noreferrer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill={colors.blue}
                      className="bi bi-linkedin"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212h-2.4s.03-6.547 0-7.225h2.4v1.023a5.54 5.54 0 0 0-.016.025h.016v-.025c.32-.493.89-1.193 2.165-1.193 1.58 0 2.764 1.033 2.764 3.252v4.143h-2.4V9.529c0-.972-.348-1.634-1.217-1.634-.664 0-1.059.447-1.233.878-.063.154-.079.37-.079.586v4.035z"
                      />
                    </svg>
                  </a>
                </div>

                {/* <CardTitle style={{ color: colors.blue, fontSize: '18px', fontWeight: '600' }} className='mt-5'> */}
                {/*  FAQ */}
                {/* </CardTitle> */}
                {/* <CardTitle onClick={()=>setActive(prevActive => !prevActive)} style={{cursor: 'pointer', color: colors.blue}}>1. How to start using Marbl platform</CardTitle> */}
                {/* <Collapse isOpen={isActive}> */}
                {/*    <div> */}
                {/*      Anim pariatur cliche reprehenderit, */}
                {/*      enim eiusmod high life accusamus terry richardson ad squid. Nihil */}
                {/*      anim keffiyeh helvetica, craft beer labore wes anderson cred */}
                {/*      nesciunt sapiente ea proident. */}
                {/*    </div> */}
                {/* </Collapse> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Support;
