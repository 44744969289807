const colors = {
  orange: '#FFA872',
  blue: '#329DEF',
  lightBlue: 'rgb(228, 240, 254)',
  opacityLigthBlue: 'rgb(228, 240, 254, 0.5)',
  opacityLightGray: 'rgba(0, 0, 0, 0.05)',
  darkBlue: '#008CFF',
  green: '#3DC482',
  coolGreen: '#7CBF87',
  gray: '#8A8A8A',
  white: '#ffffff',
  black: '#000000',
  red: '#d73038',

  semiTransparent: 'rgba(255, 0, 255, 0.5)',
  semiTransparentRed: 'rgba(255, 0, 100, 0.5)',
  transparent: 'rgba(0,0,0,0)',
  opaque: 'rgba(255,255,255,0.6)',
  loading: 'rgba(150,150,150,1)',
  shadow: '#7D8A96',
};

export default colors;
