import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label as DefaultLabel } from 'reactstrap';
import { colors } from '../../theme';

const Label = ({ className, label, id, onChange, required, children, ...props }) => {
  return (
    <FormGroup className={className}>
      <DefaultLabel for={id} style={{ color: colors.blue }}>
        {required && <span style={{ color: colors.red }}>*</span>} {label}
      </DefaultLabel>
      {children}
    </FormGroup>
  );
};

Label.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  validationClass: PropTypes.string,
  required: PropTypes.bool,
};

Label.defaultProps = {
  type: 'text',
  className: '',
  autoComplete: 'on',
  placeholder: '',
  id: '',
  onChange: () => null,
  disabled: false,
  label: '',
  validationClass: '',
  required: false,
};

export default Label;
