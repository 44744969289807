import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form, FormGroup, Input, Col, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import debounce from 'lodash/debounce';
import { Location } from '../../lib/services/companyAdminService';

const Filter = {
  ALL: 'all',
  LOCATION: 'location',
  USERS: 'users',
  SIGNUP_DATE: 'signUpDate',
  ONBOARDING_STATUS: 'onboardingStatus',
};

export const SubFilter = {
  ASC: 'asc',
  DESC: 'desc',
  NUMBER: 'number',
  DATE_RANGE: 'dateRange',
  ACTIVE: true,
  INACTIVE: false,
};

const OPTIONS = [
  {
    id: 'all-option',
    name: 'All',
    value: 'all',
  },
  {
    id: 'location-option',
    name: 'Location',
    value: 'location',
  },
  {
    id: 'users-option',
    name: 'Number of users',
    value: 'users',
  },
  {
    id: 'date-option',
    name: 'Sign Up Date',
    value: 'signUpDate',
  },
  {
    id: 'status-option',
    name: 'Onboarding Status',
    value: 'onboardingStatus',
  },
];

const locationData = {
  [Location.CITY]: '',
  [Location.STATE]: '',
  [Location.ZIP_CODE]: '',
};

let signUpDateData = {};

// eslint-disable-next-line import/prefer-default-export
export const SearchFilter = ({
  resetFilter,
  onSearch,
  changeOnBoardingStatus,
  changeLocation,
  changeSignUpDate,
  changeUsersSortType,
  changeUsersNumber,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [activeFilter, setActiveFilter] = useState(Filter.ALL);
  const [activeSubFilter, setActiveSubFilter] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const firstRender = useRef(true);

  const onChangeFilter = e => {
    setActiveFilter(e.target.value);

    if (e.target.value === Filter.ALL) {
      resetFilter();
    }
  };

  const debouncedOnSearch = useCallback(
    debounce(value => onSearch(value), 500),
    []
  );

  const debouncedOnLocation = useCallback(
    debounce(value => changeLocation(value), 500),
    []
  );

  const debouncedOnUserNumber = useCallback(
    debounce(value => changeUsersNumber(value), 500),
    []
  );

  const onChange = e => {
    const { value } = e.target;
    setSearchQuery(value);
    debouncedOnSearch(value);
  };

  const onChangeOnBoardingSelect = e => {
    changeOnBoardingStatus(e.target.value);
  };

  const onChangeOnLocationSelect = e => {
    const { name, value } = e.target;

    if (value) {
      locationData[name] = value;
    } else {
      locationData[name] = '';
    }

    debouncedOnLocation(locationData);
  };

  const onChangeSignUpDateSelect = e => {
    signUpDateData =
      e.target.value === SubFilter.DATE_RANGE
        ? { sortType: null, startDate, endDate }
        : { sortType: e.target.value, startDate: null, endDate: null };
    setActiveSubFilter(e.target.value);
    changeSignUpDate(signUpDateData);
  };

  const onChangeNumberOfUsersSelect = e => {
    if (e.target.value !== SubFilter.NUMBER) {
      changeUsersSortType(e.target.value);
    }

    setActiveSubFilter(e.target.value);
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      signUpDateData = {
        sortType: null,
        startDate,
        endDate,
      };
      changeSignUpDate(signUpDateData);
    }
  }, [startDate, endDate]);

  return (
    <Form className="flex-grow-1" onSubmit={e => e.preventDefault()}>
      <FormGroup row onChange={onChangeFilter} className="justify-content-end">
        <Col xs={12} sm={6} md={3}>
          <Input value={searchQuery} onChange={onChange} type="text" name="search" id="search" placeholder="Search" />
        </Col>
        <Col xs={12} sm={6} md={3}>
          <Input type="select" name="select" id="filterSelect">
            {OPTIONS.map(option => (
              <option selected={activeFilter === option.value} value={option.value} key={option.id}>
                {option.name}
              </option>
            ))}
          </Input>
        </Col>
      </FormGroup>
      <Row className="justify-content-end">
        {activeFilter === Filter.LOCATION && (
          <Col xs={12} sm={6} md={3} className="d-flex">
            <Input
              type="text"
              onChange={onChangeOnLocationSelect}
              defaultValue=""
              name={Location.CITY}
              placeholder={Location.CITY}
            />
            <Input
              type="text"
              onChange={onChangeOnLocationSelect}
              defaultValue=""
              name={Location.STATE}
              placeholder={Location.STATE}
            />
            <Input
              type="text"
              onChange={onChangeOnLocationSelect}
              defaultValue=""
              name={Location.ZIP_CODE}
              placeholder={Location.ZIP_CODE}
            />
          </Col>
        )}
        {activeFilter === Filter.USERS && (
          <Col xs={12} sm={6} md={3} className="d-flex">
            <Input onChange={onChangeNumberOfUsersSelect} type="select" name="select" id="usersSelect">
              <option>choose value</option>
              <option value={SubFilter.ASC}>Ascending</option>
              <option value={SubFilter.DESC}>Descending</option>
              <option value={SubFilter.NUMBER}>Number</option>
            </Input>
            {activeSubFilter === SubFilter.NUMBER && (
              <Input onChange={e => debouncedOnUserNumber(e.target.value)} type="number" name="userNumber" />
            )}
          </Col>
        )}
        {activeFilter === Filter.SIGNUP_DATE && (
          <Col xs={12} sm={6} md={3} className="d-flex flex-wrap">
            <Input onChange={onChangeSignUpDateSelect} type="select" name="select" id="dateSelect">
              <option>choose value</option>
              <option value={SubFilter.ASC}>Ascending</option>
              <option value={SubFilter.DESC}>Descending</option>
              <option value={SubFilter.DATE_RANGE}>date range</option>
            </Input>
            {activeSubFilter === SubFilter.DATE_RANGE && (
              <div className="d-flex flex-wrap">
                <DatePicker
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                />
                <DatePicker
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                />
              </div>
            )}
          </Col>
        )}
        {activeFilter === Filter.ONBOARDING_STATUS && (
          <Col xs={12} sm={6} md={3} className="d-flex">
            <Input onChange={onChangeOnBoardingSelect} type="select" name="select" id="onBoardingSelect">
              <option>choose value</option>
              <option value={SubFilter.ACTIVE}>active</option>
              <option value={SubFilter.INACTIVE}>inactive</option>
            </Input>
          </Col>
        )}
      </Row>
    </Form>
  );
};
