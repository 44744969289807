import authService from 'lib/services/authService';

import { deleteJSON, getJSON, downloadFile, postJSON, putJSON, submitMultipartForm } from './ajax';
import createFilterData from './filterHelper';

export function getCustomerList(companyId, skip = 0, take = 1000, orders = {}, filterOperands = []) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  const operands = filterOperands.concat({ field: 'isDelete', value: false, type: 1 });
  const DATA = { companyId, filter: createFilterData({ skip, take, operands, orders }) };

  const resp = postJSON(`/api/v2/customer/by/company`, DATA, REQUEST_OPTIONS);
  return resp;
}

export function getCustomerListWithJobs(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  const resp = getJSON('/api/customer-job/list', REQUEST_OPTIONS, data);
  return resp;
}

export function deleteCustomers(customerIds) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return deleteJSON('/api/customers', { customerIds }, REQUEST_OPTIONS);
}
export function getCustomerImportTemplate() {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return downloadFile('/api/customer/importTemplate', REQUEST_OPTIONS);
}

export function uploadCustomerImport(file) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
    method: 'POST',
  };

  return submitMultipartForm('/api/customer/import', { file }, REQUEST_OPTIONS);
}

export function createCustomer(customer) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return postJSON('/api/v2/customer/byadmin', customer, REQUEST_OPTIONS);
}

export function editCustomer(customer) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return putJSON('/api/v2/customer/byadmin', customer, REQUEST_OPTIONS);
}

export function getCustomerClimateZone(zipCode) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return getJSON('/api/v2/customerzone', REQUEST_OPTIONS, { zipCode });
}

export function assignCustomers(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return postJSON('/api/customers/assign', data, REQUEST_OPTIONS);
}

export function getStateList() {
  return getJSON(`/api/common/data/get-us-states-list`, null);
}

export function sendPaymentLink(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return postJSON('/api/proposal/payment-links/send', data, REQUEST_OPTIONS);
}

export function refundPayment(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return postJSON('/api/proposal/payment/refund', data, REQUEST_OPTIONS);
}
