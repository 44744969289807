import React, { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { salesStatus as SALES_STATUS } from '../../variables/general';

const DropdownStatus = ({ salesStatus, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = index => {
    setIsOpen(false);
    onChange(index);
  };

  return (
    <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
      <DropdownToggle caret color="info">
        {SALES_STATUS[salesStatus]}
      </DropdownToggle>
      <DropdownMenu>
        {SALES_STATUS.map((value, index) => (
          <DropdownItem key={value} onClick={() => onClick(index)}>
            {value}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default DropdownStatus;
