import React from 'react';
// react plugin used to create a form with multiple steps
import ReactWizard from 'react-bootstrap-wizard';
// core components
// import PanelHeader from 'components/PanelHeader/PanelHeader.jsx';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

const steps = [
  {
    stepName: 'Price builder',
    stepIcon: 'now-ui-icons ui-2_settings-90',
    component: Step1,
  },
  {
    stepName: 'Price book',
    stepIcon: 'now-ui-icons education_agenda-bookmark',
    component: Step2,
  },
  {
    stepName: 'terms',
    stepIcon: 'now-ui-icons travel_info',
    component: Step3,
  },
];

const Wizard = ({ onFinish }) => (
  <ReactWizard
    steps={steps}
    navSteps
    title="Add Price"
    description=""
    headerTextCenter
    color="blue"
    validate
    finishButtonClasses="btn-wd"
    nextButtonClasses="btn-wd"
    previousButtonClasses="btn-wd"
  />
);

export default Wizard;
