import authService from 'lib/services/authService';

import { deleteJSON, getJSON, postJSON, putJSON, submitMultipartForm } from './ajax';

export function getSalespersonList(companyId) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return getJSON(`/api/salesman?companyId=${companyId}`, REQUEST_OPTIONS);
}

export function createSalesperson(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return postJSON('/api/salesman', data, REQUEST_OPTIONS);
}

export function editSalesperson(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return putJSON('/api/salesman', data, REQUEST_OPTIONS);
}
export function activateSalesperson(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return putJSON('/api/salesman/active', data, REQUEST_OPTIONS);
}

export function deleteSalesperson(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return deleteJSON('/api/salesman', data, REQUEST_OPTIONS);
}

export function editSalespersonAvatar(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return submitMultipartForm('/api/companysalesmanavatar', data, REQUEST_OPTIONS);
}

export function changeSalespersonPassword(data) {
  const REQUEST_OPTIONS = {
    headers: {
      Authorization: `Bearer ${authService.getUser().token}`,
    },
  };
  return putJSON('/api/salesman/changepassword', data, REQUEST_OPTIONS);
}
